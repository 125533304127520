/** Represents the profile data for an invocation. */
export interface Profile {
  // (bytes used, ms since start of profiling)
  memory_profile: [number, number][]
  // (cpu load in number of CPUs (i.e. cpu time / wall time), ms since start of profiling)
  cpu_profile: [number, number][]
}

export type TimeSeries = {
  name: string
  events: [number, number][]
}

export const MEMORY_USED_PANEL = "Memory usage"

export function buildTimeSeries(events: Profile): TimeSeries[] {
  //TODO: fix timescale, should be set by the profile
  return [
    {
      name: MEMORY_USED_PANEL,
      events: events.memory_profile,
    },
    { name: "CPU load", events: events.cpu_profile },
  ]
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { client } from "../../api/axios"

export type TestRule = {
  command_string: string
}

export type TestTarget = {
  test_rule: string
  time_limit: number
  args: { [key: string]: [value: string] }
}

export type JobConfig = {
  test_rules: { [name: string]: TestRule }
  test_targets: { [name: string]: TestTarget }
}

export type JobConfigv1 = {
  test_lists: TestList[]
}

export type TestList = {
  name: string
  path: string
}

export type JobConfigInfo = {
  jobConfig: JobConfigv1
  status: fetchStatus
  failureMessage?: string
}

export type JobConfigInfov1 = {
  jobConfig: JobConfig
  status: fetchStatus
  failureMessage?: string
}

export type fetchStatus = "idle" | "loading" | "succeeded" | "failed"

// map from project ID to Git hash to JobConfig
export interface JobConfigState {
  [projectID: string]: {
    [gitHash: string]: JobConfigInfo
  }
}

const initialState: JobConfigState = {}

export const fetchJobConfig = createAsyncThunk(
  "jobConfig/fetchJobConfig",
  async ({
    organizationID,
    projectID,
    gitIdentifier,
  }: {
    organizationID: string
    projectID: string
    gitIdentifier: string
  }): Promise<{
    config: JobConfigv1
    git_hash: string
  }> => {
    const response = await client(
      `organizations/${organizationID}/projects/${projectID}/refs/${gitIdentifier}/jobConfig`,
    )
    return response.data
  },
)

const slice = createSlice({
  name: "jobConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobConfig.pending, (state, action) => {
        const { projectID, gitIdentifier } = action.meta.arg
        if (state[projectID] === undefined) {
          state[projectID] = {}
        }

        if (state[projectID][gitIdentifier] === undefined) {
          state[projectID][gitIdentifier] = {
            status: "loading",
            jobConfig: {
              test_lists: [],
            },
          }
        }
      })
      .addCase(fetchJobConfig.rejected, (state, action) => {
        const { projectID, gitIdentifier } = action.meta.arg
        state[projectID][gitIdentifier] = {
          status: "failed",
          failureMessage: action.error.stack, // see comment in axios.ts
          jobConfig: {
            test_lists: [],
          },
        }
      })
      .addCase(fetchJobConfig.fulfilled, (state, action) => {
        const { projectID, gitIdentifier } = action.meta.arg
        const { config, git_hash } = action.payload
        state[projectID][git_hash] = {
          status: "succeeded",
          jobConfig: config,
        }
        state[projectID][gitIdentifier] = {
          status: "succeeded",
          jobConfig: config,
        }
      })
  },
})

export default slice.reducer

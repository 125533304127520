import { useGetOrganizationsQuery } from "../api/apiSlice"
import { useNavigate } from "react-router-dom"
import { PageWrapper } from "../../components/PageWrapper"

export function PostLoginPage() {
  const getOrganizationsInfo = useGetOrganizationsQuery()
  const navigate = useNavigate()

  const content = <>Loading...</>
  if (getOrganizationsInfo.isError) {
    // Something went wrong, just move them along
    navigate(`/organizations`)
  } else if (getOrganizationsInfo.isSuccess) {
    const results = getOrganizationsInfo.data.results
    if (results.length === 1) {
      // just go to the only org page they have
      const organizationID = results[0].id
      navigate(`/organizations/${organizationID}`)
    } else {
      navigate(`/organizations`)
    }
  }

  return <PageWrapper title={"Home"} content={content} />
}

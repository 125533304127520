import { externalLinkIcon } from "./icon"
import React, { PropsWithChildren } from "react"

export const ExternalLinkButton = ({
  href,
  children,
}: PropsWithChildren<{
  href: string
}>): React.JSX.Element => {
  return (
    <a href={href}>
      <div
        className={
          "flex-initial text-gray-700 hover:text-gray-900 hover:bg-gray-300 " +
          "text-sm flex flex-row justify-center rounded p-2 space-x-2"
        }
      >
        <span className={"flex-none"}>{children}</span>
        <div className={"flex-none"}>{externalLinkIcon}</div>
      </div>
    </a>
  )
}

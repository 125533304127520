import { PageWrapper } from "../../components/PageWrapper"
import React from "react"
import {
  useGetBasedefsQuery,
  useGetOrganizationQuery,
  useGetProjectQuery,
} from "../api/apiSlice"
import { useParams } from "react-router-dom"
import { Basedef } from "./basedefSlice"

const BasedefLink = ({
  organizationID,
  projectID,
  basedefID,
  basedefName,
  basedefBaseImage,
}: {
  organizationID: string
  projectID: string
  basedefID: string
  basedefName: string
  basedefBaseImage: string
}): React.JSX.Element => {
  const url = `/organizations/${organizationID}/projects/${projectID}/basedefs/${basedefID}`
  return (
    <div className={"text-xl flex flex-col"}>
      <div>
        <a href={url}>{basedefName}</a>
      </div>
      <div className={"text-sm"}>{basedefBaseImage}</div>
    </div>
  )
}

export function BasedefsPage(): React.JSX.Element {
  const { organizationID, projectID } = useParams()
  if (organizationID === undefined || projectID === undefined) {
    throw Error("organization ID required")
  }
  const orgInfo = useGetOrganizationQuery(organizationID)
  const projectInfo = useGetProjectQuery({ organizationID, projectID })
  const basedefsInfo = useGetBasedefsQuery({ organizationID, projectID })

  let content
  if (orgInfo.isLoading || projectInfo.isLoading || basedefsInfo.isLoading) {
    content = <div>Loading...</div>
  } else if (orgInfo.isError) {
    content = (
      <div>Error loading organization: {JSON.stringify(orgInfo.error)}</div>
    )
  } else if (projectInfo.isError) {
    content = (
      <div>Error loading project: {JSON.stringify(projectInfo.error)}</div>
    )
  } else if (basedefsInfo.isError) {
    content = (
      <div>
        Error loading base definitions: {JSON.stringify(basedefsInfo.error)}
      </div>
    )
  } else {
    const basedefs: Basedef[] = basedefsInfo.data.results
    content = (
      <div>
        {basedefs.map(({ id, name, preset }) => (
          <BasedefLink
            organizationID={organizationID}
            projectID={projectID}
            basedefID={id}
            basedefName={name}
            basedefBaseImage={preset.name}
          />
        ))}
      </div>
    )
  }
  return <PageWrapper title={"Base definitions"} content={content} />
}

const timeFormat = new Intl.DateTimeFormat(undefined, {
  hour: "numeric",
  minute: "numeric",
})
const dateTimeFormat = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
})
export const formatDate = (iso8601Date: string): string => {
  const date = new Date(iso8601Date)
  const today = new Date()
  if (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  ) {
    return timeFormat.format(date)
  } else {
    return dateTimeFormat.format(date)
  }
}

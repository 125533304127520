import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { client } from "../../api/axios"

export type Project = {
  id: string
  name: string
  git_url: string
  status: string
  git_default_branch: string | null
}

export type fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export interface ProjectCollectionState {
  status: fetchStatus
  projects: Project[]
}

export interface ProjectsState {
  [organizationID: string]: ProjectCollectionState
}

const initialState: ProjectsState = {}

export const fetchProjectsForOrganization = createAsyncThunk(
  "project/fetchProjects",
  async ({ organizationID }: { organizationID: string }) => {
    const response = await client(`/organizations/${organizationID}/projects`)
    return response.data.results
  },
)

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsForOrganization.pending, (state, action) => {
        state[action.meta.arg.organizationID] = {
          status: "loading",
          projects: [],
        }
      })
      .addCase(fetchProjectsForOrganization.rejected, (state, action) => {
        state[action.meta.arg.organizationID] = {
          status: "failed",
          projects: [],
        }
      })
      .addCase(fetchProjectsForOrganization.fulfilled, (state, action) => {
        state[action.meta.arg.organizationID] = {
          status: "succeeded",
          projects: action.payload,
        }
      })
  },
})

export default projectSlice.reducer

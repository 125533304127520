// Import the RTK Query methods from the React-specific entry point
import { createApi } from "@reduxjs/toolkit/query/react"
import { Organization } from "../organizations/organizationSlice"
import { User } from "../user/userSlice"
import { axiosBaseQuery } from "../../api/axios"
import { Project } from "../projects/projectSlice"
import {
  PipelineRun,
  PipelineRunCreateQuery,
} from "../pipelineRuns/pipelineRunSlice"
import { Pipeline } from "../pipelines/pipelineSlice"
import { BasedefPreset } from "../basedef/basedefSlice"

import { JobSummaryModel } from "../analytics/analyticsSlice"

import { JobArtifact, RascalWave } from "../jobRun/jobRunSlice"

interface hasID {
  id: string
}

type tagType = "organization" | "project" | "pipeline" | "pipelineRun"

const providesTagsListHelper =
  <T extends hasID>(type: tagType) =>
  (result: { results: T[] } | undefined) =>
    result
      ? [
          ...result.results.map(({ id }) => ({
            type,
            id,
          })),
          { type, id: "LIST" },
        ]
      : [{ type, id: "LIST" }]

export const apiSlice = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: "api",
  // All of our requests will have URLs starting with '/api/v1'
  baseQuery: axiosBaseQuery,
  tagTypes: ["organization", "project", "pipeline", "pipelineRun"],
  // The "endpoints" represent operations and requests for this server
  endpoints: (builder) => ({
    // Auth
    getUser: builder.query<User, void>({
      query: () => ({ url: "/users/me", method: "GET" }),
    }),
    login: builder.mutation({
      query: (initialPost) => ({
        url: "/login",
        method: "POST",
        data: initialPost,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/logout",
        method: "POST",
      }),
    }),
    signup: builder.mutation({
      query: (initialPost) => ({
        url: "/signup",
        method: "POST",
        data: initialPost,
      }),
    }),
    // Organizations
    getOrganizations: builder.query<{ results: Organization[] }, void>({
      query: () => ({ url: "/organizations", method: "GET" }),
      providesTags: providesTagsListHelper<Organization>("organization"),
    }),
    getOrganization: builder.query<Organization, string>({
      query: (organizationID) => ({
        url: `/organizations/${organizationID}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result ? [{ type: "organization", id: result.id }] : [],
    }),
    createOrganization: builder.mutation({
      query: (initialPost) => ({
        url: "/organizations",
        method: "POST",
        data: initialPost,
      }),
      invalidatesTags: [{ type: "organization", id: "LIST" }],
    }),
    // Projects
    getProjects: builder.query<{ results: Project[] }, string>({
      query: (organizationID) => ({
        url: `/organizations/${organizationID}/projects`,
        method: "GET",
      }),
      providesTags: providesTagsListHelper<Project>("project"),
    }),
    getProject: builder.query<
      Project,
      { organizationID: string; projectID: string }
    >({
      query: ({ organizationID, projectID }) => ({
        url: `/organizations/${organizationID}/projects/${projectID}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result ? [{ type: "project", id: result.id }] : [],
    }),
    getProjectVerilogFiles: builder.query<
      { files: string[]; git_hash: string },
      { organizationID: string; projectID: string }
    >({
      query: ({ organizationID, projectID }) => ({
        url: `/organizations/${organizationID}/projects/${projectID}/verilog-files`,
        method: "GET",
      }),
    }),
    createProject: builder.mutation({
      query: ({ organizationID, ...data }) => ({
        url: `/organizations/${organizationID}/projects`,
        method: "POST",
        data,
      }),
      invalidatesTags: [{ type: "project", id: "LIST" }],
    }),
    createProjectsFromInstallationID: builder.mutation({
      query: ({ ...data }) => ({
        url: `/projects/create-from-installation-id`,
        method: "POST",
        data,
      }),
    }),
    // Pipelines
    getPipelines: builder.query<
      { results: Pipeline[] },
      { organizationID: string; projectID: string }
    >({
      query: ({ organizationID, projectID }) => ({
        url: `/organizations/${organizationID}/projects/${projectID}/pipelines`,
        method: "GET",
      }),
      providesTags: providesTagsListHelper<Pipeline>("pipeline"),
    }),
    getPipeline: builder.query({
      query: ({ organizationID, projectID, pipelineID }) => ({
        url: `/organizations/${organizationID}/projects/${projectID}/pipelines/${pipelineID}`,
        method: "GET",
      }),
    }),
    // Pipeline runs
    getPipelineRuns: builder.query<
      { results: PipelineRun[] },
      { organizationID: string; projectID: string; pipelineID: string }
    >({
      query: ({ organizationID, projectID, pipelineID }) => ({
        url: `/organizations/${organizationID}/projects/${projectID}/pipelines/${pipelineID}/runs`,
        method: "GET",
      }),
      providesTags: providesTagsListHelper<PipelineRun>("pipelineRun"),
    }),
    getPipelineRun: builder.query<
      PipelineRun,
      {
        organizationID: string
        projectID: string
        pipelineID: string
        pipelineRunID: string
      }
    >({
      query: ({ organizationID, projectID, pipelineID, pipelineRunID }) => ({
        url: `/organizations/${organizationID}/projects/${projectID}/pipelines/${pipelineID}/runs/${pipelineRunID}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result ? [{ type: "pipelineRun", id: result.id }] : [],
    }),
    createPipelineRun: builder.mutation<void, PipelineRunCreateQuery>({
      query: ({ organizationID, projectID, pipelineID, ...data }) => ({
        url: `/organizations/${organizationID}/projects/${projectID}/pipelines/${pipelineID}/runs`,
        method: "POST",
        data,
      }),
      invalidatesTags: [{ type: "pipelineRun", id: "LIST" }],
    }),
    // Job runs
    getJobRuns: builder.query({
      query: ({ organizationID, projectID, pipelineID, pipelineRunID }) => ({
        url:
          `/organizations/${organizationID}/` +
          `projects/${projectID}/` +
          `pipelines/${pipelineID}/` +
          `runs/${pipelineRunID}/jobs`,
        method: "GET",
      }),
    }),
    getJobRun: builder.query({
      query: ({
        organizationID,
        projectID,
        pipelineID,
        pipelineRunID,
        jobRunID,
      }) => ({
        url:
          `/organizations/${organizationID}/` +
          `projects/${projectID}/` +
          `pipelines/${pipelineID}/` +
          `runs/${pipelineRunID}/` +
          `jobs/${jobRunID}`,
        method: "GET",
      }),
    }),
    getJobRunProfile: builder.query({
      query: ({
        organizationID,
        projectID,
        pipelineID,
        pipelineRunID,
        jobRunID,
      }) => ({
        url:
          `/organizations/${organizationID}/` +
          `projects/${projectID}/` +
          `pipelines/${pipelineID}/` +
          `runs/${pipelineRunID}/` +
          `jobs/${jobRunID}/profile`,
        method: "GET",
      }),
    }),
    getJobPassAnalytics: builder.query<
      JobSummaryModel[],
      { organizationID: string; projectID: string; history: number }
    >({
      query: ({ organizationID, projectID, history }) => ({
        url:
          `/organizations/${organizationID}/` +
          `projects/${projectID}/` +
          `${history}/` +
          `job_summary`,
        method: "GET",
      }),
    }),

    getJobRunArtifacts: builder.query<
      JobArtifact[],
      {
        organizationID: string
        projectID: string
        pipelineID: string
        pipelineRunID: string
        jobRunID: string
      }
    >({
      query: ({
        organizationID,
        projectID,
        pipelineID,
        pipelineRunID,
        jobRunID,
      }) => ({
        url:
          `/organizations/${organizationID}/` +
          `projects/${projectID}/` +
          `pipelines/${pipelineID}/` +
          `runs/${pipelineRunID}/` +
          `jobs/${jobRunID}/artifacts`,
        method: "GET",
      }),
    }),

    getArtifact: builder.query<
      BlobPart[],
      {
        organizationID: string
        projectID: string
        pipelineID: string
        pipelineRunID: string
        jobRunID: string
        artifactName: string
      }
    >({
      query: ({
        organizationID,
        projectID,
        pipelineID,
        pipelineRunID,
        jobRunID,
        artifactName,
      }) => ({
        url:
          `/organizations/${organizationID}/` +
          `projects/${projectID}/` +
          `pipelines/${pipelineID}/` +
          `runs/${pipelineRunID}/` +
          `jobs/${jobRunID}/` +
          `download/${artifactName}`,
        method: "GET",
      }),
    }),

    getInitWave: builder.query<
      RascalWave,
      {
        organizationID: string
        projectID: string
        pipelineID: string
        pipelineRunID: string
        jobRunID: string
      }
    >({
      query: ({
        organizationID,
        projectID,
        pipelineID,
        pipelineRunID,
        jobRunID,
      }) => ({
        url:
          `/organizations/${organizationID}/` +
          `projects/${projectID}/` +
          `pipelines/${pipelineID}/` +
          `runs/${pipelineRunID}/` +
          `jobs/${jobRunID}/` +
          `init_wave/`,
        method: "POST",
      }),
    }),
    getJobRunErrorLines: builder.query<
      { warnings: number[]; errors: number[]; fatals: number[] },
      {
        organizationID: string
        projectID: string
        pipelineID: string
        pipelineRunID: string
        jobRunID: string
      }
    >({
      query: ({
        organizationID,
        projectID,
        pipelineID,
        pipelineRunID,
        jobRunID,
      }) => ({
        url:
          `/organizations/${organizationID}/` +
          `projects/${projectID}/` +
          `pipelines/${pipelineID}/` +
          `runs/${pipelineRunID}/` +
          `jobs/${jobRunID}/errors`,
        method: "GET",
      }),
    }),
    // Base definitions
    getBasedefs: builder.query({
      query: ({ organizationID, projectID }) => ({
        url: `/organizations/${organizationID}/projects/${projectID}/basedefs`,
        method: "GET",
      }),
    }),

    // Base definitions
    getBasedef: builder.query({
      query: ({ organizationID, projectID, basedefID }) => ({
        url: `/organizations/${organizationID}/projects/${projectID}/basedefs/${basedefID}`,
        method: "GET",
      }),
    }),
    updateBasedef: builder.mutation({
      query: ({ organizationID, projectID, basedefID, ...data }) => ({
        url: `/organizations/${organizationID}/projects/${projectID}/basedefs/${basedefID}`,
        method: "PUT",
        data: data,
      }),
    }),
    getBasedefPresets: builder.query<
      { [presetLabel: string]: BasedefPreset },
      {
        organizationID: string
        projectID: string
        basedefID: string
      }
    >({
      query: ({ organizationID, projectID, basedefID }) => ({
        url: `/organizations/${organizationID}/projects/${projectID}/basedefs/${basedefID}/presets`,
        method: "GET",
      }),
    }),
  }),
})

export const {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useSignupMutation,
  useGetOrganizationsQuery,
  useGetOrganizationQuery,
  useCreateOrganizationMutation,
  useGetProjectsQuery,
  useGetProjectQuery,
  useGetProjectVerilogFilesQuery,
  useCreateProjectMutation,
  useCreateProjectsFromInstallationIDMutation,
  useGetPipelinesQuery,
  useGetPipelineQuery,
  useGetPipelineRunsQuery,
  useGetPipelineRunQuery,
  useCreatePipelineRunMutation,
  useGetJobRunsQuery,
  useGetJobRunQuery,
  useGetJobRunErrorLinesQuery,
  useGetBasedefsQuery,
  useGetBasedefQuery,
  useUpdateBasedefMutation,
  useGetBasedefPresetsQuery,
  useGetJobRunProfileQuery,
  useGetJobPassAnalyticsQuery,
  useGetJobRunArtifactsQuery,
  useGetArtifactQuery,
  useGetInitWaveQuery,
} = apiSlice

import { useCreateProjectMutation } from "../api/apiSlice"
import { useNavigate, useParams } from "react-router-dom"
import { Input, Label, PrimaryButton } from "../../components/form"
import { PageWrapper } from "../../components/PageWrapper"
import { useState } from "react"

export function CreateProjectPage() {
  const { organizationID } = useParams()
  if (organizationID === undefined) {
    throw Error("organization ID required")
  }

  const [createProject, createProjectInfo] = useCreateProjectMutation()
  const navigate = useNavigate()

  const [name, setName] = useState<string>("")
  const [gitURL, setGitURL] = useState<string>("")

  const gitURLIsValid =
    /^https:\/\/github\.com\/[-a-z\d]{0,38}\/[-_.a-z\d]{0,100}$/i.test(gitURL)
  const inputIsValid = gitURLIsValid && name !== ""
  const shouldShowGitHelpText = gitURL !== "" && !gitURLIsValid

  const content = (
    <div>
      <form>
        <Label htmlFor={"name"}>Name *</Label>
        <Input
          type={"text"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          id={"name"}
        />
        <Label htmlFor={"name"}>GitHub URL *</Label>
        <Input
          type={"text"}
          value={gitURL}
          onChange={(e) => setGitURL(e.target.value)}
          id={"gitURL"}
        />
        {shouldShowGitHelpText && (
          <div className={"text-sm text-red-700"}>
            GitHub URL should be formatted like https://github.com/username/repo
          </div>
        )}
        <PrimaryButton
          onClick={async (e) => {
            e.preventDefault()
            await createProject({
              organizationID,
              name,
              git_url: gitURL,
            }).unwrap()
            navigate(`/organizations/${organizationID}`)
          }}
          disabled={createProjectInfo.isLoading || !inputIsValid}
        >
          {createProjectInfo.isLoading ? "Creating Project" : "Create project"}
        </PrimaryButton>
        {createProjectInfo.isError &&
          "Error: " + JSON.stringify(createProjectInfo.error)}
      </form>
    </div>
  )

  return <PageWrapper title={"Home"} content={content} />
}

import { BaseQueryFn } from "@reduxjs/toolkit/query"
import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { getConfigValue } from "../app/config"
import { onLoggedOut } from "../features/user/userSlice"

export const client = axios.create({
  baseURL: getConfigValue("API_BASE_URL"),
  withCredentials: true,
})
client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      onLoggedOut()
    }
    // We don't need the stack, and the response keeps being overwritten.
    // This is basically criminal but I need the data before launch hn
    error.stack = error.response?.data?.detail
    return Promise.reject(error)
  },
)

export const axiosBaseQuery: BaseQueryFn<
  {
    url: string
    method: AxiosRequestConfig["method"]
    data?: AxiosRequestConfig["data"]
    params?: AxiosRequestConfig["params"]
    headers?: AxiosRequestConfig["headers"]
  },
  unknown,
  unknown
> = async ({ url, method, data, params, headers }) => {
  try {
    const result = await client({
      url,
      method,
      data,
      params,
      headers,
    })
    return { data: result.data }
  } catch (axiosError) {
    const err = axiosError as AxiosError
    const data =
      typeof err.response?.data === "object" &&
      err.response.data !== null &&
      "detail" in err.response?.data
        ? err.response.data.detail
        : err.response?.data || err.message
    return {
      error: {
        status: err.response?.status,
        data,
      },
    }
  }
}

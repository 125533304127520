import { getConfigValue } from "../../app/config"
import { deleteCookie } from "../../utils/cookie"

export type User = {
  email: string
  id: string
}

export const onLoggedOut = (): void => {
  deleteCookie(getConfigValue("SESSION_COOKIE_NAME"))
  window.location.href = "/login"
}

import { useParams } from "react-router-dom"
import {
  useGetOrganizationQuery,
  useGetPipelinesQuery,
  useGetProjectQuery,
} from "../api/apiSlice"
import { Pipeline } from "../pipelines/pipelineSlice"
import { Project } from "./projectSlice"
import React, { useEffect } from "react"
import { PageWrapper } from "../../components/PageWrapper"
import { DisplayPipelinesWindow } from "../../components/DisplayPipelinesWindow"
import { fetchPipelineRunsForPipeline } from "../pipelineRuns/pipelineRunSlice"
import { useDispatch, useSelector } from "react-redux"
import { RootState, store } from "../../app/store"
import { PrimaryButton } from "../../components/form"
import { getConfigValue } from "../../app/config"

export function ProjectPage() {
  const { organizationID, projectID } = useParams()
  if (organizationID === undefined || projectID === undefined) {
    throw Error("organization and project ID required")
  }

  const {
    isLoading: orgIsLoading,
    isSuccess: orgIsSuccess,
    isError: orgIsError,
    error: orgError,
  } = useGetOrganizationQuery(organizationID)

  const {
    data: projectData,
    isLoading: projectIsLoading,
    isSuccess: projectIsSuccess,
    isError: projectIsError,
    error: projectError,
  } = useGetProjectQuery({ organizationID, projectID })

  const {
    data: pipelinesData,
    isLoading: pipelinesIsLoading,
    isSuccess: pipelinesIsSuccess,
    isError: pipelinesIsError,
    error: pipelinesError,
  } = useGetPipelinesQuery({ organizationID, projectID })

  const dispatch = useDispatch<typeof store.dispatch>()
  const pipelineRunData = useSelector((state: RootState) => state.pipelineRun)

  useEffect(() => {
    if (orgIsSuccess && projectIsSuccess && pipelinesIsSuccess) {
      const pipelines = pipelinesData.results
      pipelines.forEach((pipeline) => {
        dispatch(
          fetchPipelineRunsForPipeline({
            organizationID,
            projectID,
            pipelineID: pipeline.id,
          }),
        )
      })
    }
  }, [
    orgIsSuccess,
    projectIsSuccess,
    pipelinesIsSuccess,
    pipelinesData,
    dispatch,
    organizationID,
    projectID,
  ])

  let title = ""
  let content = <></>
  if (orgIsLoading || projectIsLoading || pipelinesIsLoading) {
    title = "Loading..."
    content = <div>Loading...</div>
  } else if (orgIsError) {
    title = "An error occurred"
    content = <div>Error loading organization: {JSON.stringify(orgError)}</div>
  } else if (projectIsError) {
    title = "An error occurred"
    content = <div>Error loading project: {JSON.stringify(projectError)}</div>
  } else if (pipelinesIsError) {
    title = "An error occurred"
    content = (
      <div>Error loading pipelines: {JSON.stringify(pipelinesError)}</div>
    )
  } else {
    if (!(orgIsSuccess && projectIsSuccess && pipelinesIsSuccess)) {
      throw new Error("unhandled case")
    }
    const project: Project = projectData
    title = project.name
    const { results: pipelines }: { results: Pipeline[] } = pipelinesData

    content = (
      <div>
        <div className={"text-sm text-gray-700 font-mono"}>
          {project.git_url}
        </div>
        {["development", "staging"].includes(getConfigValue("environment")) && (
          <div className="flex flex-row  w-full items-start ">
            <div className={"text-base m-4"}>
              <a
                href={`/organizations/${organizationID}/projects/${projectID}/analytics`}
              >
                <PrimaryButton type={"button"}>
                  <b>Project health</b>
                </PrimaryButton>
              </a>
            </div>
            <div className={"text-base m-4"}>
              <a
                href={`/organizations/${organizationID}/projects/${projectID}/plangen`}
              >
                <PrimaryButton type={"button"}>
                  <b>See test plans</b>
                </PrimaryButton>
              </a>
            </div>
          </div>
        )}
        {pipelineRunData !== undefined && (
          <DisplayPipelinesWindow
            organizationID={organizationID}
            projectID={projectID}
            projectGitURL={projectData.git_url}
            projectGitDefaultBranch={projectData.git_default_branch}
            pipelines={pipelines}
            pipelineRunData={pipelineRunData}
          />
        )}
        <hr />

        <div className={"text-base m-4"}>
          <a
            href={`/organizations/${organizationID}/projects/${projectID}/basedefs`}
          >
            <PrimaryButton>See base definitions</PrimaryButton>
          </a>
        </div>
      </div>
    )
  }

  return <PageWrapper title={title} content={content} />
}

import { fetchStatus } from "../projects/projectSlice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { client } from "../../api/axios"

export type Pipeline = {
  id: string
  name: string
  status: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matching_rule: { [k: string]: any }
  average_duration: number | null
}

// A collection of pipelines belonging to a given project
export interface PipelineCollectionState {
  status: fetchStatus
  pipelines: Pipeline[]
}

export interface PipelinesState {
  [projectID: string]: PipelineCollectionState
}

const initialState: PipelinesState = {}

export const fetchPipelinesForProject = createAsyncThunk(
  "pipeline/fetchPipelines",
  async ({
    organizationID,
    projectID,
  }: {
    organizationID: string
    projectID: string
  }) => {
    const response = await client(
      `organizations/${organizationID}/projects/${projectID}/pipelines`,
    )
    return response.data.results
  },
)

const pipelineSlice = createSlice({
  name: "pipeline",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPipelinesForProject.pending, (state, action) => {
        state[action.meta.arg.projectID] = {
          status: "loading",
          pipelines: [],
        }
      })
      .addCase(fetchPipelinesForProject.rejected, (state, action) => {
        state[action.meta.arg.projectID] = {
          status: "failed",
          pipelines: [],
        }
      })
      .addCase(fetchPipelinesForProject.fulfilled, (state, action) => {
        state[action.meta.arg.projectID] = {
          status: "succeeded",
          pipelines: action.payload,
        }
      })
  },
})

export default pipelineSlice.reducer

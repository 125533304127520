import React from "react"
import { useGetUserQuery } from "../api/apiSlice"

export const User = () => {
  const { data, isLoading, isSuccess, isError, error } = useGetUserQuery()

  let content

  if (isLoading) {
    content = <div>Loading...</div>
  } else if (isSuccess) {
    const { email, id } = data
    content = (
      <div>
        User email: {email}, id: {id}
      </div>
    )
  } else if (isError) {
    content = <div>an error occurred: {JSON.stringify(error)}</div>
  }

  return (
    <section>
      <h2>User</h2>
      {content}
    </section>
  )
}

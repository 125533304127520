import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { RootState, store } from "../../app/store"
import { copyToClipboard } from "../../utils/clipboard"
import { Toaster, toast } from "sonner"
import {
  useGetJobRunErrorLinesQuery,
  useGetJobRunQuery,
  useGetOrganizationQuery,
  useGetPipelineQuery,
  useGetPipelineRunQuery,
  useGetProjectQuery,
  useGetJobRunProfileQuery,
  useGetJobRunArtifactsQuery,
} from "../api/apiSlice"
import { PipelineRun } from "../pipelineRuns/pipelineRunSlice"
import { fetchLogs, getArtifactInfo, JobArtifact, JobRun } from "./jobRunSlice"
import { useEffect, useState } from "react"
import { Pipeline } from "../pipelines/pipelineSlice"
import { PageWrapper } from "../../components/PageWrapper"
import { LogsDisplay } from "../../components/LogsDisplay"
import { CodeDisplay } from "../../components/CodeDisplay"
import { formatDate } from "../../utils/date"
import { PrimaryButton } from "../../components/form"
import { Profile } from "../../components/profile_viewer/trace_events"
import TraceViewer from "../../components/profile_viewer/trace_viewer"
import { Modal } from "../../components/Modal"
import { client } from "../../api/axios"

async function downloadArtifact(
  organizationID: string,
  projectID: string,
  pipelineID: string,
  pipelineRunID: string,
  jobRunID: string,
  artifactName: string,
) {
  try {
    const response = await client.get(
      `/organizations/${organizationID}/projects/${projectID}/pipelines/${pipelineID}/runs/${pipelineRunID}/jobs/${jobRunID}/download/${artifactName}`,
      { responseType: "arraybuffer" },
    )

    // The response will be a stream, you can handle it according to your needs
    // For example, you can create a Blob and create a link for download
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", artifactName)
    document.body.appendChild(link)
    link.click()
  } catch (error) {
    console.error("Error downloading artifact:", error)
  }
}

const MetaDataButton = ({
  icon,
  payload,
}: {
  icon: string
  payload: string
}) => {
  return (
    <div className=" flex items-center">
      <i className={`${icon}`} />
      <p className="text-sm ml-1.5">{payload}</p>
    </div>
  )
}

const ReproCommand = ({ command_text }: { command_text: string }) => {
  return (
    <div className="flex-col mt-8 pt-8 border-t border-solid">
      <div className="font-bold">
        command line reproduction{" "}
        <i
          className="bi bi-copy cursor-pointer"
          onClick={() => {
            copyToClipboard(command_text)
            toast.success("Copied command to clipboard!")
          }}
        />
      </div>
      <div className="p-4 bg-white shadow-lg rounded-lg flex items-center space-x-4 mt-2">
        <div className="flex">
          <code className="whitespace-pre-wrap">{command_text}</code>
        </div>
      </div>
    </div>
  )
}

const TestMetadataCard = ({
  jobrun,
  pipelinerun,
}: {
  jobrun: JobRun
  pipelinerun: PipelineRun
}) => {
  const date_str = formatDate(pipelinerun.date_created)
  const status_icon =
    jobrun.status === "SUCCEEDED"
      ? "bi bi-check-circle-fill text-green-500"
      : jobrun.status === "IN_PROGRESS"
        ? "bi bi-person-walking text-red-500"
        : "bi bi-x-circle-fill text-red-500"

  return (
    <div className="p-4 bg-white shadow rounded-lg flex items-center space-x-4">
      <div className="flex flex-col items-start space-y-2">
        <MetaDataButton icon={status_icon} payload={jobrun.status} />
        <MetaDataButton
          icon={"bi bi-clock-fill text-gray-500"}
          payload={jobrun.duration?.toFixed(2) ?? "unknown"}
        />
        <MetaDataButton icon={"bi bi-calendar-date"} payload={date_str} />
      </div>

      <div className="flex flex-col items-start space-y-2">
        <MetaDataButton
          icon={"bi bi-github text-gray-500"}
          payload={pipelinerun.git_hash.substring(0, 7)}
        />
      </div>
    </div>
  )
}

const ProfileCard = ({
  profile,
  isLoading,
  isError,
}: {
  profile: Profile
  isLoading: boolean
  isError: boolean
}) => {
  const inner = isLoading ? (
    <div>Profile is loading...</div>
  ) : isError ? (
    <div> Failed to get profile </div>
  ) : (
    <TraceViewer profile={profile} />
  )

  return (
    <div className="card">
      <div className="content">
        <div className="title"> Memory and CPU Load Profile</div>
        <div className="details">{inner}</div>
      </div>
    </div>
  )
}

export function JobRunPage() {
  const { organizationID, projectID, pipelineID, pipelineRunID, jobRunID } =
    useParams()
  if (
    organizationID === undefined ||
    projectID === undefined ||
    pipelineID === undefined ||
    pipelineRunID === undefined ||
    jobRunID === undefined
  ) {
    throw Error("some stuff is missing")
  }

  const dispatch = useDispatch<typeof store.dispatch>()
  const logsData = useSelector((state: RootState) => state.jobRun.logs)

  const codeData = useSelector(
    (state: RootState) => state.pipelineRunCode[pipelineRunID] ?? {},
  )

  const organizationInfo = useGetOrganizationQuery(organizationID)
  const projectInfo = useGetProjectQuery({ organizationID, projectID })
  const pipelineInfo = useGetPipelineQuery({
    organizationID,
    projectID,
    pipelineID,
  })
  const pipelineRunInfo = useGetPipelineRunQuery({
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
  })
  const jobRunInfo = useGetJobRunQuery({
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
    jobRunID,
  })

  const profileQuery = useGetJobRunProfileQuery({
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
    jobRunID,
  })

  const artifactList = useGetJobRunArtifactsQuery({
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
    jobRunID,
  })

  const errorLinesInfo = useGetJobRunErrorLinesQuery({
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
    jobRunID,
  })
  const [logRefreshIntervalID, setLogRefreshIntervalID] = useState(0)

  const [downloadedArtifacts, setDownloadedArtifacts] = useState<
    Record<string, boolean>
  >({})

  useEffect(() => {
    if (jobRunInfo.isSuccess && logRefreshIntervalID === 0) {
      const jobRun: JobRun = jobRunInfo.data
      const logsComplete = ["FAILED", "SUCCEEDED", "TIMED_OUT"].includes(
        jobRun.status,
      )
      const shouldAutoRefreshLogs = !logsComplete
      dispatch(
        fetchLogs({
          organizationID,
          projectID,
          pipelineID,
          pipelineRunID,
          jobRunID,
        }),
      )
      if (shouldAutoRefreshLogs) {
        const intervalID = window.setInterval(() => {
          dispatch(
            fetchLogs({
              organizationID,
              projectID,
              pipelineID,
              pipelineRunID,
              jobRunID,
            }),
          )
        }, 5000)
        setLogRefreshIntervalID(intervalID)
      }
    }
    return () => {
      window.clearInterval(logRefreshIntervalID)
    }
  }, [
    logRefreshIntervalID,
    dispatch,
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
    jobRunInfo.isSuccess,
    jobRunInfo.data,
    jobRunID,
  ])

  const [scrolledOnLoad, setScrolledOnLoad] = useState(false)
  useEffect(() => {
    if (errorLinesInfo.isSuccess && !scrolledOnLoad) {
      const firstError = Math.min(
        errorLinesInfo.data.warnings[0] ?? Number.MAX_SAFE_INTEGER,
        errorLinesInfo.data.errors[0] ?? Number.MAX_SAFE_INTEGER,
        errorLinesInfo.data.fatals[0] ?? Number.MAX_SAFE_INTEGER,
      )
      if (firstError < Number.MAX_SAFE_INTEGER) {
        setTimeout(() => {
          const element = document.getElementById(`L${firstError + 1}`)
          element?.scrollIntoView()
        }, 750)
        // wait for the dom element to render, then scroll to it
      }
      setScrolledOnLoad(true)
    }
  })

  const [showCode, setShowCode] = useState(false)
  const [errorText] = useState("")
  const [logLineno] = useState(0)
  const [basename] = useState("")
  const [codeErrorLineno] = useState(0)
  const [artifactModal, setArtifactModal] = useState(false)

  const errorLines = new Set<number>([])
  if (errorLinesInfo.isSuccess) {
    errorLinesInfo.data.warnings.forEach((line) => errorLines.add(line))
    errorLinesInfo.data.errors.forEach((line) => errorLines.add(line))
    errorLinesInfo.data.fatals.forEach((line) => errorLines.add(line))
  }

  let title: string = ""
  let content = <></>
  if (
    organizationInfo.isError ||
    projectInfo.isError ||
    pipelineInfo.isError ||
    pipelineRunInfo.isError ||
    jobRunInfo.isError
  ) {
    title = "An error occurred"
    content = <div>Error loading data</div>
    // TODO make this good
  } else if (
    organizationInfo.isLoading ||
    projectInfo.isLoading ||
    pipelineInfo.isLoading ||
    pipelineRunInfo.isLoading ||
    jobRunInfo.isLoading
  ) {
    title = "Loading..."
    content = <div>Loading...</div>
  } else {
    if (
      !(
        organizationInfo.isSuccess &&
        projectInfo.isSuccess &&
        pipelineInfo.isSuccess &&
        pipelineRunInfo.isSuccess &&
        jobRunInfo.isSuccess
      )
    ) {
      throw new Error("unhandled case")
    }

    const loading =
      organizationInfo.isLoading ||
      projectInfo.isLoading ||
      pipelineInfo.isLoading ||
      pipelineRunInfo.isLoading ||
      jobRunInfo.isLoading

    const pipeline: Pipeline = pipelineInfo.data
    const pipelineRun: PipelineRun = pipelineRunInfo.data
    const jobRun: JobRun = jobRunInfo.data

    const command_line_repro = `smelt execute ${jobRun.smelt_file_path} --target-name ${jobRun.job_name}`
    const profile: Profile = profileQuery.data
    const arts: JobArtifact[] = artifactList.isSuccess ? artifactList.data : []

    const card_status =
      jobRun.status === "SUCCEEDED"
        ? "success"
        : jobRun.status === "FAILED"
          ? "failure"
          : "neutral"

    title = `#${pipelineRun.display_number} ${pipeline.name} > ${jobRun.job_name}`
    const log_title = <div className="title">{`${jobRun.job_name} logs`}</div>

    const vcdArtifact = arts.find((art) => art.key.endsWith(".vcd")) || null

    let waveUrl: string | null = null

    if (vcdArtifact) {
      waveUrl =
        `/organizations/${organizationID}/` +
        `projects/${projectID}/` +
        `pipelines/${pipelineID}/` +
        `runs/${pipelineRunID}/` +
        `jobs/${jobRun.id}/` +
        `wave/${vcdArtifact.key}`
    }

    content = (
      <div>
        <span className={"text-base"}></span>
        <TestMetadataCard jobrun={jobRun} pipelinerun={pipelineRun} />
        <div className={"flex flex-row space-x-4"}>
          <div className={"w-full"}>
            <LogsDisplay
              logLines={logsData}
              loading={loading}
              title={log_title}
              card_status={card_status}
            />
          </div>
          {showCode && (
            <div className={"w-1/2"}>
              <CodeDisplay
                errorText={errorText}
                logLineno={logLineno}
                basename={basename}
                onClose={() => {
                  setShowCode(false)
                }}
                lines={codeData[basename]?.lines ?? []}
                status={codeData[basename]?.status ?? "loading"}
                codeLineno={codeErrorLineno}
              />
            </div>
          )}
        </div>
        <ReproCommand command_text={command_line_repro} />
        <ProfileCard
          profile={profile}
          isError={profileQuery.isError}
          isLoading={profileQuery.isLoading}
        />
        <div className={"space-x-4 my-4"}>
          <PrimaryButton
            onClick={() => {
              setArtifactModal(true)
            }}
          >
            Job Artifacts
          </PrimaryButton>
          {waveUrl && (
            <PrimaryButton
              onClick={() => {
                window.open(waveUrl, "_blank")
              }}
            >
              Open waveforms
            </PrimaryButton>
          )}
        </div>
        {artifactModal && (
          <Modal
            title={"Job Artifacts"}
            confirmMessage={""}
            contents={
              <div className={"overflow-y-auto max-h-128 my-2"}>
                {arts.map((art) => {
                  const { name, size } = getArtifactInfo(art)

                  return (
                    <div
                      className={`flex flex-row w-full p-1 shadow-sm hover:shadow  ${downloadedArtifacts[name] ? "bg-gray-200" : ""}`}
                    >
                      <div className="flex flex-row w-full space-x-4 p-1 justify-between">
                        <div>{name}</div>
                        <div className="flex flex-row space-x-2">
                          <div>{size}</div>
                          {downloadedArtifacts[name] ? (
                            <i className="bi bi-check-circle-fill" />
                          ) : (
                            <i
                              className="bi bi-download cursor-pointer"
                              onClick={() => {
                                if (!downloadedArtifacts[name]) {
                                  downloadArtifact(
                                    organizationID,
                                    projectID,
                                    pipelineID,
                                    pipelineRunID,
                                    jobRunID,
                                    name,
                                  )
                                  setDownloadedArtifacts((prevState) => ({
                                    ...prevState,
                                    [name]: true,
                                  }))
                                }
                              }}
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            }
            confirmDisabled={false}
            onCancel={(e) => {
              e.preventDefault()
              setArtifactModal(false)
            }}
          />
        )}

        <Toaster richColors duration={4000} />
      </div>
    )
  }

  return <PageWrapper title={title} content={content} />
}

import React, { FormEventHandler, MouseEventHandler } from "react"
import { PrimaryButton, SecondaryButton } from "./form"

// Adapted from https://www.creative-tim.com/learning-lab/tailwind-starter-kit/documentation/react/modals/regular
// For forms only
export const Modal = ({
  contents,
  title,
  subtitle = "",
  confirmMessage,
  confirmDisabled,
  onCancel,
  onConfirm,
}: {
  contents: React.JSX.Element
  title: string
  subtitle?: string
  confirmMessage: string
  confirmDisabled: boolean
  onCancel: MouseEventHandler<HTMLButtonElement>
  onConfirm?: FormEventHandler<HTMLFormElement>
}): React.JSX.Element => {
  const modalRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onCancel(event as any)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [onCancel])

  return (
    <>
      <div className="p-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-1/2 my-6 mx-auto max-w-3xl" ref={modalRef}>
          {/*content*/}
          <div className="p-6 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 rounded-t">
              <div>
                <h3 className="text-4xl">{title}</h3>
                {subtitle !== "" && (
                  <div className={"my-2 text-gray-700 text-base"}>
                    {subtitle}
                  </div>
                )}
              </div>
              <button
                className="ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={onCancel}
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>

            <form onSubmit={onConfirm}>
              {/*body*/}
              <div className={"p-5"}>{contents}</div>
              {/*footer*/}
              <div className="flex items-center p-6 rounded-b">
                <span className={"mr-2"}>
                  {onConfirm && (
                    <PrimaryButton disabled={confirmDisabled} type="submit">
                      {confirmMessage}
                    </PrimaryButton>
                  )}
                </span>
                {onConfirm && (
                  <SecondaryButton onClick={onCancel!} type={"button"}>
                    Close
                  </SecondaryButton>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

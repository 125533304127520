import React, { PropsWithChildren, useState } from "react"

const arrow = (size: number, open: boolean) => (
  <svg
    width={size}
    height={size}
    // style="width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    transform={open ? "rotate(90 0 0)" : undefined}
  >
    <path d="M384.30607 256.204389l0 512.41186 320.259338-256.206443L384.30607 256.204389z" />
  </svg>
)

export const Collapsible = ({
  header,
  children,
  size,
  arrowDivClass,
  initOpen = true,
}: PropsWithChildren<{
  header: React.JSX.Element
  size: number
  arrowDivClass: string
  initOpen?: boolean
}>): React.JSX.Element => {
  const [open, setOpen] = useState(initOpen)
  return (
    <div className={"flex flex-row"}>
      <div className={arrowDivClass} onClick={() => setOpen(!open)}>
        {arrow(size, open)}
      </div>
      <div className={"grow"}>
        <div
          onClick={(e) => {
            let target: EventTarget | null = e.target
            // Don't toggle the collapse if the click was on a button.
            while (target !== e.currentTarget) {
              if (target instanceof HTMLElement) {
                if (target.nodeName.toLowerCase() === "button") {
                  return
                }
                target = target.parentNode
              }
            }
            setOpen(!open)
          }}
        >
          {header}
        </div>
        {open && children}
      </div>
    </div>
  )
}

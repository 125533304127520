import React from "react"
import { PipelineRunCodeFetchStatus } from "../features/pipelineRuns/pipelineRunCodeSlice"

const Header = ({
  errorText,
  logLineno,
  basename,
  codeLineno,
  onClose,
}: {
  errorText: string
  logLineno: number
  basename: string
  codeLineno: number
  onClose: () => void
}) => {
  return (
    <div className={"flex flex-row bg-gray-400 p-4"}>
      <div>
        <div className={"font-mono text-lg my-2"}>{errorText}</div>
        <div className={"text-sm"}>
          logs:{logLineno} → {basename}:{codeLineno}
        </div>
      </div>
      <div className={"flex-1"} />
      <div className={"text-2xl"} onClick={onClose}>
        ×
      </div>
    </div>
  )
}

const CodeWindow = ({
  lines,
  errorLineNumbers,
  status,
}: {
  lines: string[]
  errorLineNumbers: Set<number>
  status: PipelineRunCodeFetchStatus
}): React.JSX.Element => {
  let content = <></>

  switch (status) {
    case "loading":
      content = <div>Loading...</div>
      break

    case "failed":
      content = <div>Loading code failed.</div>
      break

    case "succeeded":
      content = (
        <div className={"grid grid-cols-12"}>
          {lines.map((line, i) => {
            const oneIndexedLine = i + 1
            const isError = errorLineNumbers.has(oneIndexedLine)
            const lineNumberBackground = isError ? "bg-gray-500" : "bg-gray-600"
            const background = isError ? "bg-gray-200" : "bg-gray-300"
            const textColor = isError ? "text-red-700" : "text-black"

            return (
              <>
                <div
                  className={`${lineNumberBackground} col-span-1 text-white text-right px-3 select-none`}
                  id={`C${oneIndexedLine}`}
                >
                  <code>
                    <a href={`#C${oneIndexedLine}`}>{oneIndexedLine}</a>
                  </code>
                </div>
                <div
                  className={`${background} ${textColor} col-span-11 px-3 whitespace-pre-wrap min-w-full`}
                >
                  <code>{line}</code>
                </div>
              </>
            )
          })}
        </div>
      )
      break
  }

  return <div className={"overflow-auto max-h-128 gap-4"}>{content}</div>
}

export const CodeDisplay = ({
  errorText,
  logLineno,
  basename,
  onClose,
  lines,
  status,
  codeLineno,
}: {
  errorText: string
  logLineno: number
  basename: string
  onClose: () => void
  lines: string[]
  status: PipelineRunCodeFetchStatus
  codeLineno: number
}): React.JSX.Element => {
  const codeContent = (
    <CodeWindow
      lines={lines}
      errorLineNumbers={new Set([codeLineno])}
      status={status}
    />
  )
  return (
    <div>
      <Header
        errorText={errorText}
        logLineno={logLineno}
        basename={basename}
        codeLineno={codeLineno}
        onClose={onClose}
      />
      {codeContent}
    </div>
  )
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { client } from "../../api/axios"

export type PipelineRunLogFetchStatus =
  | "idle"
  | "loading"
  | "succeeded"
  | "failed"

export interface PipelineRunState {
  logStatus: PipelineRunLogFetchStatus
  logs: string[]
}

const initialState: PipelineRunState = {
  logStatus: "idle",
  logs: [],
}
export const fetchLogs = createAsyncThunk(
  "pipelineRunLog/fetchLogs",
  async ({
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
  }: {
    organizationID: string
    projectID: string
    pipelineID: string
    pipelineRunID: string
  }) => {
    const response = await client(
      `organizations/${organizationID}/projects/${projectID}/pipelines/${pipelineID}/runs/${pipelineRunID}/logs`,
    )
    return response.data.result
  },
)
export const pipelineRunSlice = createSlice({
  name: "pipelineRunLog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogs.pending, (state) => {
        state.logStatus = "loading"
      })
      .addCase(fetchLogs.rejected, (state) => {
        state.logStatus = "failed"
      })
      .addCase(fetchLogs.fulfilled, (state, action) => {
        state.logStatus = "succeeded"
        state.logs = action.payload.split("\n")
      })
  },
})

export default pipelineRunSlice.reducer

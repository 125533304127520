import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { useGetOrganizationsQuery } from "../api/apiSlice"
import { fetchProjectsForOrganization } from "../projects/projectSlice"
import { PageWrapper } from "../../components/PageWrapper"
import { TestsSummaryGraph } from "./AnalyticsPage"
import { Organization } from "../organizations/organizationSlice"
import { RootState, store } from "../../app/store"

export function MultiAnalyticsPage(): React.JSX.Element {
  const organizationsData = useGetOrganizationsQuery()
  const orgsIsSuccess = organizationsData.isSuccess
  const orgsData = organizationsData?.data?.results
  const dispatch = useDispatch<typeof store.dispatch>()

  let content: React.JSX.Element = <></>

  const allProjects = useSelector((state: RootState) => state.project)
  useEffect(() => {
    if (orgsIsSuccess && Object.keys(allProjects).length === 0) {
      const organizations: Organization[] = orgsData ?? []
      organizations.forEach((organization) => {
        dispatch(
          fetchProjectsForOrganization({ organizationID: organization.id }),
        )
      })
    }
  }, [orgsIsSuccess, orgsData, allProjects, dispatch])

  if (!orgsIsSuccess) {
    return <PageWrapper title={"Analytics"} content={<div>Loading ...</div>} />
  }
  content = (
    <div>
      {orgsData?.flatMap(({ id }) => {
        return allProjects[id]?.projects.map((project) => {
          return (
            <div>
              <div className="font-bold text-xl py-4">{`${project.name}`}</div>
              <TestsSummaryGraph
                key={`${id}-${project.id}`}
                organizationID={id}
                projectID={project.id}
              />
            </div>
          )
        })
      })}
    </div>
  )

  return <PageWrapper title={"Analytics"} content={content} />
}

import { useLoginMutation } from "../api/apiSlice"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { Input, Label, PrimaryButton } from "../../components/form"
import { CombinedLogo } from "../../components/CombinedLogo"

export function Login() {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [
    login,
    {
      isError,
      isSuccess: {},
      error,
    },
  ] = useLoginMutation()

  return (
    <div
      className={
        "px-6 py-2 h-screen w-screen bg-backgroundDark text-textLight flex "
      }
    >
      <div className={"flex flex-col flex-1 items-center justify-center"}>
        <CombinedLogo />
        <h1 className={"hidden"}>Log in to Silogy</h1>
        <form className={"min-w-72"}>
          <div className={"mb-4"}>
            <Label
              className={"block text-gray-300 text-sm font-bold mb-2"}
              htmlFor={"email"}
            >
              Email
            </Label>
            <Input
              type={"email"}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
            <br />
            <Label
              className={"block text-gray-300 text-sm font-bold mb-2"}
              htmlFor={"email"}
            >
              Password
            </Label>
            <Input
              type={"password"}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
          </div>

          <PrimaryButton
            type={"submit"}
            onClick={async (e) => {
              e.preventDefault()
              await login({ email, password }).unwrap()
              navigate("/post-login")
            }}
          >
            Log in
          </PrimaryButton>

          <div className={"my-4"}>
            Or{" "}
            <a className={"obvious"} href={"/signup"}>
              create an account
            </a>
          </div>
          {isError ? <div>Error: {JSON.stringify(error)}</div> : ""}
        </form>
      </div>
    </div>
  )
}

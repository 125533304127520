import React from "react"
import installScreenshot from "./install-screenshot.png"
import { PageWrapper } from "../../components/PageWrapper"
import { PrimaryButton } from "../../components/form"

export function Welcome() {
  const content = (
    <div className={"space-y-4 text-lg"}>
      <div className={"my-3"}>To get started:</div>
      <ol className={"list-decimal space-y-2"}>
        <li>
          <p>
            <a
              href={"https://github.com/silogy-io/silogy-hello-world/fork"}
              className={"obvious"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Fork
            </a>{" "}
            our Hello World app in your personal GitHub account.
            <p className={"text-base"}>
              Make sure to <b>untick</b> the box labeled "Copy the main branch
              only" because you'll want all branches. (If you forget to do this,
              delete the fork and try again.)
            </p>
          </p>
          <p className={"text-base"}>Any name is ok.</p>
        </li>
        <li>
          <p>
            Install the{" "}
            <a
              href={"https://github.com/apps/silogy-design-verification-runner"}
              className={"obvious"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Silogy GitHub app
            </a>{" "}
            on only the new repository.
          </p>
          <p className={"text-base"}>It should look like:</p>
          <img
            className={"border-2 rounded m-4"}
            src={installScreenshot}
            alt={
              "Only one repository should be " +
              "selected. Only the Read access to code and metadata permission " +
              "should be enabled."
            }
          />
        </li>
        <li>
          If you're curious about how tests are defined, read the{" "}
          <a
            className={"obvious"}
            href={
              "https://github.com/silogy-io/silogy-hello-world/blob/main/README.md"
            }
          >
            README
          </a>{" "}
          in the hello world app.
        </li>
      </ol>
      <div>Then you'll be ready to run your first test on Silogy!</div>
      <div className={"my-4"}>
        <PrimaryButton
          onClick={() => {
            window.localStorage.removeItem("onboarding")
            document.location.assign("/post-login")
          }}
        >
          I'm done!
        </PrimaryButton>
      </div>
    </div>
  )
  return <PageWrapper title="Welcome to Silogy!" content={content} />
}

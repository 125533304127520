import React, { useEffect, useState } from "react"

export const ProgressBar = ({
  startTime,
  endTime,
  expectedDuration,
  textColor,
  backgroundColor,
  timedOut,
}: {
  startTime: Date | null
  endTime: Date | null
  expectedDuration: number
  textColor: string
  backgroundColor: string
  timedOut: boolean
}): React.JSX.Element => {
  const [now, setNow] = useState(new Date())
  useEffect(() => {
    if (endTime === null) {
      const intervalID = window.setInterval(() => {
        setNow(new Date())
      }, 1000)
      return () => {
        window.clearInterval(intervalID)
      }
    }
  }, [])

  if (startTime === null) {
    return (
      <div className={`w-full ${textColor} rounded h-6 dark:bg-gray-700`}>
        Not yet started
      </div>
    )
  } else if (timedOut) {
    return (
      <div className={`w-full ${textColor} rounded h-6 dark:bg-gray-700`}>
        Timed out
      </div>
    )
  } else if (endTime !== null) {
    const elapsedSeconds = (endTime.getTime() - startTime.getTime()) / 1000
    const minutes = Math.floor(elapsedSeconds / 60)
    const seconds = Math.floor(elapsedSeconds - minutes * 60)
    const progressText = `Completed in ${minutes}m${seconds}s`
    return (
      <div className={`w-full ${textColor} rounded h-6 dark:bg-gray-700`}>
        {progressText}
      </div>
    )
  } else {
    const elapsedSeconds = (now.getTime() - startTime.getTime()) / 1000
    const progress = elapsedSeconds / expectedDuration
    const progressWidth = `${Math.min(progress * 100, 100)}%`
    let progressText = ""
    const overtime = elapsedSeconds > expectedDuration
    if (overtime) {
      const minutes = Math.floor(elapsedSeconds / 60)
      const seconds = Math.floor(elapsedSeconds - minutes * 60)
      progressText = `${minutes}m${seconds}s elapsed`
    } else {
      const remainingTime = expectedDuration - elapsedSeconds
      const minutes = Math.floor(remainingTime / 60)
      const seconds = Math.floor(remainingTime - minutes * 60)
      progressText = `${minutes}m${seconds}s left`
    }
    return (
      <div className="w-full bg-gray-200 rounded h-6 dark:bg-gray-700 text-base flex flex-row">
        <div
          className={`${backgroundColor} h-6 px-2 rounded text-white`}
          style={{ width: progressWidth }}
        >
          {progress > 0.5 && progressText}
        </div>
        <div className={"h-6 px-2 rounded text-black"}>
          {progress < 0.5 && progressText}
        </div>
      </div>
    )
  }
}

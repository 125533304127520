import React from "react"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { client } from "../../api/axios"

export type PlanGenTreeItem = {
  canGeneratePlan: boolean
  generatePlan?: (e: React.MouseEvent<HTMLButtonElement>) => void
  fetchPlan?: (e: React.MouseEvent<HTMLButtonElement>) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any
}

export type fetchStatus = "loading" | "succeeded" | "failed"

export interface PlanGenFileState {
  status: fetchStatus
  contents: string
}

export interface PlanGenState {
  // projectID is globally unique so this is ok
  [projectID: string]: {
    [filepath: string]: PlanGenFileState
  }
}

const initialState: PlanGenState = {}

export const fetchFile = createAsyncThunk(
  "planGen/fetchFile",
  async ({
    organizationID,
    projectID,
    filepath,
    gitHash,
  }: {
    organizationID: string
    projectID: string
    filepath: string
    gitHash: string
  }) => {
    const encodedPath = encodeURIComponent(filepath)
    const response = await client(
      `/organizations/${organizationID}/projects/${projectID}/verilog-files/${encodedPath}?git_hash=${gitHash}`,
    )
    return response.data.results
  },
)
const planGenSlice = createSlice({
  name: "planGen",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFile.pending, (state, action) => {
        const { projectID, filepath } = action.meta.arg
        if (!state[projectID]) {
          state[projectID] = {}
        }
        state[projectID][filepath] = {
          status: "loading",
          contents: "",
        }
      })
      .addCase(fetchFile.rejected, (state, action) => {
        const { projectID, filepath } = action.meta.arg
        if (!state[projectID]) {
          state[projectID] = {}
        }
        state[projectID][filepath] = {
          status: "failed",
          contents: "",
        }
      })
      .addCase(fetchFile.fulfilled, (state, action) => {
        const { projectID, filepath } = action.meta.arg
        if (!state[projectID]) {
          state[projectID] = {}
        }
        state[projectID][filepath] = {
          status: "succeeded",
          contents: action.payload,
        }
      })
  },
})

export default planGenSlice.reducer

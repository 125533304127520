// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getConfigValue = (k: string): any => {
  const env: string = import.meta.env.VITE_ENV

  const dataForEnv = {
    development: {
      environment: "development",
      API_BASE_URL: "/v1",
      SESSION_COOKIE_NAME: "session-dev",
      COOKIE_DOMAIN: null,
    },
    production: {
      environment: "production",
      API_BASE_URL: "https://api.silogy.io/v1",
      SESSION_COOKIE_NAME: "session",
      COOKIE_DOMAIN: ".silogy.io",
    },
    staging: {
      environment: "staging",
      API_BASE_URL: "https://api-staging.silogy.io/v1",
      SESSION_COOKIE_NAME: "session-staging",
      COOKIE_DOMAIN: ".silogy.io",
    },
  }[env]

  if (dataForEnv === undefined) {
    throw new Error(`unknown env value: ${env}`)
  }

  if (!(k in dataForEnv)) {
    throw new Error(`key ${k} not found in data for env ${env}`)
  }

  // @ts-expect-error We live in a world of pain
  return dataForEnv[k]
}

import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import thunkMiddleware from "redux-thunk"
import pipelineReducer from "../features/pipelines/pipelineSlice"
import pipelineRunReducer from "../features/pipelineRuns/pipelineRunSlice"
import pipelineRunCodeReducer from "../features/pipelineRuns/pipelineRunCodeSlice"
import pipelineRunLogReducer from "../features/pipelineRuns/pipelineRunLogSlice"
import planGenReducer from "../features/planGen/planGenSlice"
import projectReducer from "../features/projects/projectSlice"
import jobRunReducer from "../features/jobRun/jobRunSlice"
import jobConfigReducer from "../features/jobConfig/jobConfigSlice"
import waveStateReducer from "../features/wave/wavewindow/waveWindowSlice"

import { apiSlice } from "../features/api/apiSlice"

export const store = configureStore({
  reducer: {
    jobConfig: jobConfigReducer,
    jobRun: jobRunReducer,
    pipeline: pipelineReducer,
    pipelineRun: pipelineRunReducer,
    pipelineRunCode: pipelineRunCodeReducer,
    pipelineRunLog: pipelineRunLogReducer,
    planGen: planGenReducer,
    project: projectReducer,
    waveState: waveStateReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware).concat(thunkMiddleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

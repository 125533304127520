/**
 * How many units in the model X dimension there are per second. Model X units
 * are just microseconds, but the term "model x" coordinates is used to make
 * some of the transformation calculations more readable.
 */
export const MODEL_X_PER_SECOND = 1_000_000

export const EVENTS_PANEL_HEIGHT = 540
export const LINE_PLOTS_PANEL_HEIGHT = 360

export const TIMESTAMP_HEADER_SIZE = 16
export const TIMESTAMP_HEADER_COLOR = "rgba(236, 239, 241, 0.9)" // "#ECEFF1" with alpha
export const TIMESTAMP_FONT_COLOR = "#424242"
export const TIMESTAMP_FONT_SIZE = "11px"

export const GRIDLINE_IDEAL_GAP = 80
export const GRIDLINE_COLOR = "#CFD8DC"
export const MOUSE_GRIDLINE_COLOR = "#B0BEC5"

export const SECTION_LABEL_HEIGHT = 20
export const SECTION_LABEL_FONT_SIZE = "13px"
export const SECTION_LABEL_FONT_COLOR = "#212121"
export const SECTION_LABEL_BG_COLOR = "#eee"
export const SECTION_LABEL_BORDER_COLOR = "#E0E0E0"
export const SECTION_LABEL_PADDING_BOTTOM = 1
export const SECTION_PADDING_BOTTOM = 1

export const TRACK_HEIGHT = 16
export const TRACK_VERTICAL_GAP = 1

export const EVENT_HORIZONTAL_GAP = 0.5
export const EVENT_LABEL_WIDTH_THRESHOLD = 20
export const EVENT_LABEL_FONT_SIZE = "11px"
export const EVENT_LABEL_FONT_COLOR = SECTION_LABEL_FONT_COLOR
export const EVENT_FILTERED_OUT_COLOR = "#ddd"

export const TIME_SERIES_HEIGHT = 125
export const TIME_SERIES_POINT_RADIUS = 5

export const BOTTOM_CONTROLS_HEIGHT = 48

export const SCROLLBAR_SIZE = 13

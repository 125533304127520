import { useParams } from "react-router-dom"
import {
  useGetJobRunsQuery,
  useGetOrganizationQuery,
  useGetPipelineQuery,
  useGetPipelineRunQuery,
  useGetProjectQuery,
} from "../api/apiSlice"
import { Pipeline } from "../pipelines/pipelineSlice"
import { PageWrapper } from "../../components/PageWrapper"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { logsComplete, PipelineRun } from "./pipelineRunSlice"
import { RootState, store } from "../../app/store"
import { JobRun, jobRunErrorCount } from "../jobRun/jobRunSlice"
import { LogsDisplay } from "../../components/LogsDisplay"
import { fetchLogs } from "./pipelineRunLogSlice"
import { formatDate } from "../../utils/date"
import {
  failureIcon,
  gitHubIcon,
  inProgressIcon,
  successIcon,
} from "../../components/icon"
import { ProgressBar } from "../../components/ProgressBar"
import { Collapsible } from "../../components/Collapsible"
import { PipelineRunStatusWidget } from "./PipelineRunStatusWidget"
import { ErrorDisplay } from "../../components/ErrorDisplay"
import { ExternalLinkButton } from "../../components/ExternalLinkButton"
import {
  getGitHubCommitURL,
  getGitHubRefURL,
  getGitRefSuffix,
} from "../../utils/git"

const JobRunLink = ({
  organizationID,
  projectID,
  pipelineID,
  pipelineRunID,
  jobRun,
}: {
  organizationID: string
  projectID: string
  pipelineID: string
  pipelineRunID: string
  jobRun: JobRun
}): React.JSX.Element => {
  const url =
    `/organizations/${organizationID}/` +
    `projects/${projectID}/` +
    `pipelines/${pipelineID}/` +
    `runs/${pipelineRunID}/` +
    `jobs/${jobRun.id}`
  const icon = ["IN_PROGRESS"].includes(jobRun.status)
    ? inProgressIcon
    : ["SUCCEEDED"].includes(jobRun.status)
      ? successIcon
      : failureIcon
  const failed = ["FAILED", "TIMED_OUT"].includes(jobRun.status)
  const timedOut = jobRun.status === "TIMED_OUT"
  const errorTextColor = failed ? "text-red-600" : "text-green-600"
  const errorTextClass = failed ? "text-red-600 font-bold" : "text-green-600"
  const progressBackgroundColor = failed ? "bg-red-600" : "bg-green-600"
  const startTime =
    jobRun.date_started !== null ? new Date(jobRun.date_started) : null
  const endTime =
    jobRun.date_finished !== null ? new Date(jobRun.date_finished) : null
  const errorCount = jobRunErrorCount(jobRun)

  const errorMessage =
    errorCount > 0
      ? `${errorCount} errors`
      : jobRun.status === "SUCCEEDED"
        ? "All tests passed"
        : jobRun.status === "IN_PROGRESS"
          ? "In progress"
          : jobRun.status === "TIMED_OUT"
            ? "Timed out"
            : jobRun.status === "FAILED"
              ? "Failed"
              : ""

  const progressBar = (
    <ProgressBar
      startTime={startTime}
      endTime={endTime}
      expectedDuration={21}
      textColor={errorTextColor}
      backgroundColor={progressBackgroundColor}
      timedOut={timedOut}
    />
  )

  return (
    <div
      className={
        "flex flex-row w-full text-base p-2 border-b bg-backgroundLight"
      }
    >
      <div className={"flex-initial w-8"}>
        <a href={url}>{icon}</a>
      </div>
      {/* test names can get long, break-all to force a line break */}
      <div className={"flex-initial w-72 font-mono font-bold break-all"}>
        <a href={url}>{jobRun.job_name}</a>
      </div>
      <div className={`flex-initial w-48 ${errorTextClass}`}>
        <a href={url}>{errorMessage}</a>
      </div>
      <div className={"flex-initial w-48"}>{progressBar}</div>
    </div>
  )
}

export function PipelineRunPage() {
  const { organizationID, projectID, pipelineID, pipelineRunID } = useParams()
  if (
    organizationID === undefined ||
    projectID === undefined ||
    pipelineID === undefined ||
    pipelineRunID === undefined
  ) {
    throw Error("some stuff is missing")
  }

  const dispatch = useDispatch<typeof store.dispatch>()
  const logsData = useSelector((state: RootState) => state.pipelineRunLog.logs)

  const {
    isLoading: orgIsLoading,
    isSuccess: orgIsSuccess,
    isError: orgIsError,
    error: orgError,
  } = useGetOrganizationQuery(organizationID)
  const {
    data: projectData,
    isLoading: projectIsLoading,
    isSuccess: projectIsSuccess,
    isError: projectIsError,
    error: projectError,
  } = useGetProjectQuery({ organizationID, projectID })
  const {
    data: pipelineData,
    isLoading: pipelineIsLoading,
    isSuccess: pipelineIsSuccess,
    isError: pipelineIsError,
    error: pipelineError,
  } = useGetPipelineQuery({ organizationID, projectID, pipelineID })
  const {
    data: pipelineRunData,
    isLoading: pipelineRunIsLoading,
    isSuccess: pipelineRunIsSuccess,
    isError: pipelineRunIsError,
    error: pipelineRunError,
  } = useGetPipelineRunQuery({
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
  })
  const jobRunsInfo = useGetJobRunsQuery({
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
  })
  const [logRefreshIntervalID, setLogRefreshIntervalID] = useState(0)

  useEffect(() => {
    if (pipelineRunIsSuccess && logRefreshIntervalID === 0) {
      const pipelineRun: PipelineRun = pipelineRunData
      const logsReady = !["INIT", "PENDING"].includes(pipelineRun.status)
      const shouldAutoRefreshLogs =
        logsReady && !logsComplete(pipelineRun.status)
      if (logsReady) {
        dispatch(
          fetchLogs({
            organizationID,
            projectID,
            pipelineID,
            pipelineRunID,
          }),
        )
      }
      if (shouldAutoRefreshLogs) {
        const intervalID = window.setInterval(() => {
          dispatch(
            fetchLogs({
              organizationID,
              projectID,
              pipelineID,
              pipelineRunID,
            }),
          )
        }, 5000)
        setLogRefreshIntervalID(intervalID)
      }
    }
    return () => {
      window.clearInterval(logRefreshIntervalID)
    }
  }, [
    pipelineRunIsSuccess,
    logRefreshIntervalID,
    pipelineRunData,
    dispatch,
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
  ])

  let title = ""
  let content = <></>
  if (
    orgIsLoading ||
    projectIsLoading ||
    pipelineIsLoading ||
    pipelineRunIsLoading ||
    jobRunsInfo.isLoading
  ) {
    title = "Loading..."
    content = <div>Loading...</div>
  } else if (
    orgIsError ||
    projectIsError ||
    pipelineIsError ||
    pipelineRunIsError ||
    jobRunsInfo.isError
  ) {
    title = "An error occurred"
    let errorType, error
    switch (true) {
      case orgIsError:
        errorType = "organization"
        error = orgError
        break
      case projectIsError:
        errorType = "project"
        error = projectError
        break
      case pipelineIsError:
        errorType = "pipeline"
        error = pipelineError
        break
      case pipelineRunIsError:
        errorType = "run"
        error = pipelineRunError
        break
      case jobRunsInfo.isError:
        errorType = "job"
        error = jobRunsInfo.error
        break
      default:
        throw new Error("unhandled error case")
    }
    content = (
      <div>
        Error loading {errorType}: {JSON.stringify(error)}
      </div>
    )
  } else {
    if (
      !(
        orgIsSuccess &&
        projectIsSuccess &&
        pipelineIsSuccess &&
        pipelineRunIsSuccess &&
        jobRunsInfo.isSuccess
      )
    ) {
      throw new Error("unhandled case")
    }
    const pipeline: Pipeline = pipelineData
    const pipelineRun: PipelineRun = pipelineRunData
    title = `#${pipelineRun.display_number} ${pipeline.name}`

    const logsReady = !["INIT", "PENDING"].includes(pipelineRun.status)
    if (logsReady) {
      const jobRuns: JobRun[] = jobRunsInfo.data.results
      const jobRunLinks = jobRuns.map((jobRun) => (
        <JobRunLink
          organizationID={organizationID}
          projectID={projectID}
          pipelineID={pipelineID}
          pipelineRunID={pipelineRunID}
          jobRun={jobRun}
        />
      ))
      const log_title = <div className="title">{`Build logs`}</div>
      const card_status =
        pipelineRun.status === "FAILED"
          ? "failure"
          : pipelineRun.status == "SUCCEEDED"
            ? "success"
            : "neutral"

      const gitHubCommitURL = getGitHubCommitURL(
        projectData.git_url,
        pipelineRun.git_hash,
      )
      const gitHubRefURL = getGitHubRefURL(
        projectData.git_url,
        pipelineRun.git_ref,
      )
      const gitRefSuffix = getGitRefSuffix(pipelineRun.git_ref)
      content = (
        <div>
          <div className={"flex flex-row space-x-2"}>
            <span className={"text-base mt-2"}>
              {formatDate(pipelineRun.date_created)}
            </span>
            <ExternalLinkButton href={gitHubCommitURL}>
              <div className={"flex flex-row space-x-2"}>
                <div>{gitHubIcon}</div>
                <div>{pipelineRun.git_hash.substring(0, 12)}</div>
              </div>
            </ExternalLinkButton>
            {gitHubRefURL && gitRefSuffix && (
              <ExternalLinkButton href={gitHubRefURL}>
                <div className={"flex flex-row space-x-2"}>
                  <div>{gitHubIcon}</div>
                  <div>{gitRefSuffix}</div>
                </div>
              </ExternalLinkButton>
            )}
          </div>
          <PipelineRunStatusWidget status={pipelineRun.status} />
          {pipelineRun.failure_reason && (
            <ErrorDisplay
              shortMessage={"Pipeline run failed"}
              longerMessage={pipelineRun.failure_reason}
            />
          )}
          <Collapsible
            header={<h2>Build logs</h2>}
            size={32}
            arrowDivClass={"mx-1 p-2"}
            initOpen={!logsComplete(pipelineRun.status)}
          >
            <LogsDisplay
              logLines={logsData}
              loading={!logsReady}
              title={log_title}
              card_status={card_status}
            />
          </Collapsible>
          <Collapsible
            header={<h2>Job runs</h2>}
            size={32}
            arrowDivClass={"mx-1 p-2"}
          >
            <div className={"border rounded"}>
              {jobRunLinks.length ? jobRunLinks : "No jobs were run."}
            </div>
          </Collapsible>
        </div>
      )
    } else {
      content = <div>Waiting to start build...</div>
    }
  }

  return <PageWrapper title={title} content={content} />
}

let wasm

const heap = new Array(128).fill(undefined)

heap.push(undefined, null, true, false)

function getObject(idx) {
  return heap[idx]
}

let heap_next = heap.length

function dropObject(idx) {
  if (idx < 132) return
  heap[idx] = heap_next
  heap_next = idx
}

function takeObject(idx) {
  const ret = getObject(idx)
  dropObject(idx)
  return ret
}

function addHeapObject(obj) {
  if (heap_next === heap.length) heap.push(heap.length + 1)
  const idx = heap_next
  heap_next = heap[idx]

  heap[idx] = obj
  return idx
}

const cachedTextDecoder =
  typeof TextDecoder !== "undefined"
    ? new TextDecoder("utf-8", { ignoreBOM: true, fatal: true })
    : {
        decode: () => {
          throw Error("TextDecoder not available")
        },
      }

if (typeof TextDecoder !== "undefined") {
  cachedTextDecoder.decode()
}

let cachedUint8Memory0 = null

function getUint8Memory0() {
  if (cachedUint8Memory0 === null || cachedUint8Memory0.byteLength === 0) {
    cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer)
  }
  return cachedUint8Memory0
}

function getStringFromWasm0(ptr, len) {
  ptr = ptr >>> 0
  return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len))
}

let WASM_VECTOR_LEN = 0

const cachedTextEncoder =
  typeof TextEncoder !== "undefined"
    ? new TextEncoder("utf-8")
    : {
        encode: () => {
          throw Error("TextEncoder not available")
        },
      }

const encodeString =
  typeof cachedTextEncoder.encodeInto === "function"
    ? function (arg, view) {
        return cachedTextEncoder.encodeInto(arg, view)
      }
    : function (arg, view) {
        const buf = cachedTextEncoder.encode(arg)
        view.set(buf)
        return {
          read: arg.length,
          written: buf.length,
        }
      }

function passStringToWasm0(arg, malloc, realloc) {
  if (realloc === undefined) {
    const buf = cachedTextEncoder.encode(arg)
    const ptr = malloc(buf.length, 1) >>> 0
    getUint8Memory0()
      .subarray(ptr, ptr + buf.length)
      .set(buf)
    WASM_VECTOR_LEN = buf.length
    return ptr
  }

  let len = arg.length
  let ptr = malloc(len, 1) >>> 0

  const mem = getUint8Memory0()

  let offset = 0

  for (; offset < len; offset++) {
    const code = arg.charCodeAt(offset)
    if (code > 0x7f) break
    mem[ptr + offset] = code
  }

  if (offset !== len) {
    if (offset !== 0) {
      arg = arg.slice(offset)
    }
    ptr = realloc(ptr, len, (len = offset + arg.length * 3), 1) >>> 0
    const view = getUint8Memory0().subarray(ptr + offset, ptr + len)
    const ret = encodeString(arg, view)

    offset += ret.written
    ptr = realloc(ptr, len, offset, 1) >>> 0
  }

  WASM_VECTOR_LEN = offset
  return ptr
}

function isLikeNone(x) {
  return x === undefined || x === null
}

let cachedInt32Memory0 = null

function getInt32Memory0() {
  if (cachedInt32Memory0 === null || cachedInt32Memory0.byteLength === 0) {
    cachedInt32Memory0 = new Int32Array(wasm.memory.buffer)
  }
  return cachedInt32Memory0
}

let cachedFloat64Memory0 = null

function getFloat64Memory0() {
  if (cachedFloat64Memory0 === null || cachedFloat64Memory0.byteLength === 0) {
    cachedFloat64Memory0 = new Float64Array(wasm.memory.buffer)
  }
  return cachedFloat64Memory0
}

function debugString(val) {
  // primitive types
  const type = typeof val
  if (type == "number" || type == "boolean" || val == null) {
    return `${val}`
  }
  if (type == "string") {
    return `"${val}"`
  }
  if (type == "symbol") {
    const description = val.description
    if (description == null) {
      return "Symbol"
    } else {
      return `Symbol(${description})`
    }
  }
  if (type == "function") {
    const name = val.name
    if (typeof name == "string" && name.length > 0) {
      return `Function(${name})`
    } else {
      return "Function"
    }
  }
  // objects
  if (Array.isArray(val)) {
    const length = val.length
    let debug = "["
    if (length > 0) {
      debug += debugString(val[0])
    }
    for (let i = 1; i < length; i++) {
      debug += ", " + debugString(val[i])
    }
    debug += "]"
    return debug
  }
  // Test for built-in
  const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val))
  let className
  if (builtInMatches.length > 1) {
    className = builtInMatches[1]
  } else {
    // Failed to match the standard '[object ClassName]'
    return toString.call(val)
  }
  if (className == "Object") {
    // we're a user defined class or Object
    // JSON.stringify avoids problems with cycles, and is generally much
    // easier than looping through ownProperties of `val`.
    try {
      return "Object(" + JSON.stringify(val) + ")"
    } catch (_) {
      return "Object"
    }
  }
  // errors
  if (val instanceof Error) {
    return `${val.name}: ${val.message}\n${val.stack}`
  }
  // TODO we could test for more things here, like `Set`s and `Map`s.
  return className
}

const CLOSURE_DTORS =
  typeof FinalizationRegistry === "undefined"
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry((state) => {
        wasm.__wbindgen_export_2.get(state.dtor)(state.a, state.b)
      })

function makeMutClosure(arg0, arg1, dtor, f) {
  const state = { a: arg0, b: arg1, cnt: 1, dtor }
  const real = (...args) => {
    // First up with a closure we increment the internal reference
    // count. This ensures that the Rust closure environment won't
    // be deallocated while we're invoking it.
    state.cnt++
    const a = state.a
    state.a = 0
    try {
      return f(a, state.b, ...args)
    } finally {
      if (--state.cnt === 0) {
        wasm.__wbindgen_export_2.get(state.dtor)(a, state.b)
        CLOSURE_DTORS.unregister(state)
      } else {
        state.a = a
      }
    }
  }
  real.original = state
  CLOSURE_DTORS.register(real, state, state)
  return real
}
function __wbg_adapter_30(arg0, arg1, arg2) {
  wasm.wasm_bindgen__convert__closures__invoke1_mut__ha0294f29bba006a4(
    arg0,
    arg1,
    addHeapObject(arg2),
  )
}

function __wbg_adapter_33(arg0, arg1) {
  try {
    const retptr = wasm.__wbindgen_add_to_stack_pointer(-16)
    wasm.wasm_bindgen__convert__closures__invoke0_mut__h7d9a159dd4397f85(
      retptr,
      arg0,
      arg1,
    )
    var r0 = getInt32Memory0()[retptr / 4 + 0]
    var r1 = getInt32Memory0()[retptr / 4 + 1]
    if (r1) {
      throw takeObject(r0)
    }
  } finally {
    wasm.__wbindgen_add_to_stack_pointer(16)
  }
}

function __wbg_adapter_38(arg0, arg1) {
  wasm.wasm_bindgen__convert__closures__invoke0_mut__h8de3bf340d701ef0(
    arg0,
    arg1,
  )
}

function handleError(f, args) {
  try {
    return f.apply(this, args)
  } catch (e) {
    wasm.__wbindgen_exn_store(addHeapObject(e))
  }
}
function __wbg_adapter_93(arg0, arg1, arg2, arg3) {
  wasm.wasm_bindgen__convert__closures__invoke2_mut__h350bc46a701fca5a(
    arg0,
    arg1,
    addHeapObject(arg2),
    addHeapObject(arg3),
  )
}

/**
 * @param {string} message
 */
export function inject_message(message) {
  const ptr0 = passStringToWasm0(
    message,
    wasm.__wbindgen_malloc,
    wasm.__wbindgen_realloc,
  )
  const len0 = WASM_VECTOR_LEN
  wasm.inject_message(ptr0, len0)
}

const IntoUnderlyingByteSourceFinalization =
  typeof FinalizationRegistry === "undefined"
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry((ptr) =>
        wasm.__wbg_intounderlyingbytesource_free(ptr >>> 0),
      )
/**
 */
export class IntoUnderlyingByteSource {
  __destroy_into_raw() {
    const ptr = this.__wbg_ptr
    this.__wbg_ptr = 0
    IntoUnderlyingByteSourceFinalization.unregister(this)
    return ptr
  }

  free() {
    const ptr = this.__destroy_into_raw()
    wasm.__wbg_intounderlyingbytesource_free(ptr)
  }
  /**
   * @returns {string}
   */
  get type() {
    let deferred1_0
    let deferred1_1
    try {
      const retptr = wasm.__wbindgen_add_to_stack_pointer(-16)
      wasm.intounderlyingbytesource_type(retptr, this.__wbg_ptr)
      var r0 = getInt32Memory0()[retptr / 4 + 0]
      var r1 = getInt32Memory0()[retptr / 4 + 1]
      deferred1_0 = r0
      deferred1_1 = r1
      return getStringFromWasm0(r0, r1)
    } finally {
      wasm.__wbindgen_add_to_stack_pointer(16)
      wasm.__wbindgen_free(deferred1_0, deferred1_1, 1)
    }
  }
  /**
   * @returns {number}
   */
  get autoAllocateChunkSize() {
    const ret = wasm.intounderlyingbytesource_autoAllocateChunkSize(
      this.__wbg_ptr,
    )
    return ret >>> 0
  }
  /**
   * @param {ReadableByteStreamController} controller
   */
  start(controller) {
    wasm.intounderlyingbytesource_start(
      this.__wbg_ptr,
      addHeapObject(controller),
    )
  }
  /**
   * @param {ReadableByteStreamController} controller
   * @returns {Promise<any>}
   */
  pull(controller) {
    const ret = wasm.intounderlyingbytesource_pull(
      this.__wbg_ptr,
      addHeapObject(controller),
    )
    return takeObject(ret)
  }
  /**
   */
  cancel() {
    const ptr = this.__destroy_into_raw()
    wasm.intounderlyingbytesource_cancel(ptr)
  }
}

const IntoUnderlyingSinkFinalization =
  typeof FinalizationRegistry === "undefined"
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry((ptr) =>
        wasm.__wbg_intounderlyingsink_free(ptr >>> 0),
      )
/**
 */
export class IntoUnderlyingSink {
  __destroy_into_raw() {
    const ptr = this.__wbg_ptr
    this.__wbg_ptr = 0
    IntoUnderlyingSinkFinalization.unregister(this)
    return ptr
  }

  free() {
    const ptr = this.__destroy_into_raw()
    wasm.__wbg_intounderlyingsink_free(ptr)
  }
  /**
   * @param {any} chunk
   * @returns {Promise<any>}
   */
  write(chunk) {
    const ret = wasm.intounderlyingsink_write(
      this.__wbg_ptr,
      addHeapObject(chunk),
    )
    return takeObject(ret)
  }
  /**
   * @returns {Promise<any>}
   */
  close() {
    const ptr = this.__destroy_into_raw()
    const ret = wasm.intounderlyingsink_close(ptr)
    return takeObject(ret)
  }
  /**
   * @param {any} reason
   * @returns {Promise<any>}
   */
  abort(reason) {
    const ptr = this.__destroy_into_raw()
    const ret = wasm.intounderlyingsink_abort(ptr, addHeapObject(reason))
    return takeObject(ret)
  }
}

const IntoUnderlyingSourceFinalization =
  typeof FinalizationRegistry === "undefined"
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry((ptr) =>
        wasm.__wbg_intounderlyingsource_free(ptr >>> 0),
      )
/**
 */
export class IntoUnderlyingSource {
  __destroy_into_raw() {
    const ptr = this.__wbg_ptr
    this.__wbg_ptr = 0
    IntoUnderlyingSourceFinalization.unregister(this)
    return ptr
  }

  free() {
    const ptr = this.__destroy_into_raw()
    wasm.__wbg_intounderlyingsource_free(ptr)
  }
  /**
   * @param {ReadableStreamDefaultController} controller
   * @returns {Promise<any>}
   */
  pull(controller) {
    const ret = wasm.intounderlyingsource_pull(
      this.__wbg_ptr,
      addHeapObject(controller),
    )
    return takeObject(ret)
  }
  /**
   */
  cancel() {
    const ptr = this.__destroy_into_raw()
    wasm.__wbg_intounderlyingsource_free(ptr)
  }
}

async function __wbg_load(module, imports) {
  if (typeof Response === "function" && module instanceof Response) {
    if (typeof WebAssembly.instantiateStreaming === "function") {
      try {
        return await WebAssembly.instantiateStreaming(module, imports)
      } catch (e) {
        if (module.headers.get("Content-Type") != "application/wasm") {
          console.warn(
            "`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n",
            e,
          )
        } else {
          throw e
        }
      }
    }

    const bytes = await module.arrayBuffer()
    return await WebAssembly.instantiate(bytes, imports)
  } else {
    const instance = await WebAssembly.instantiate(module, imports)

    if (instance instanceof WebAssembly.Instance) {
      return { instance, module }
    } else {
      return instance
    }
  }
}

function __wbg_get_imports() {
  const imports = {}
  imports.wbg = {}
  imports.wbg.__wbg_new_abda76e883ba8a5f = function () {
    const ret = new Error()
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_stack_658279fe44541cf6 = function (arg0, arg1) {
    const ret = getObject(arg1).stack
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc,
    )
    const len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_error_f851667af71bcfc6 = function (arg0, arg1) {
    let deferred0_0
    let deferred0_1
    try {
      deferred0_0 = arg0
      deferred0_1 = arg1
      console.error(getStringFromWasm0(arg0, arg1))
    } finally {
      wasm.__wbindgen_free(deferred0_0, deferred0_1, 1)
    }
  }
  imports.wbg.__wbindgen_object_drop_ref = function (arg0) {
    takeObject(arg0)
  }
  imports.wbg.__wbindgen_cb_drop = function (arg0) {
    const obj = takeObject(arg0).original
    if (obj.cnt-- == 1) {
      obj.a = 0
      return true
    }
    const ret = false
    return ret
  }
  imports.wbg.__wbg_removeEventListener_92cb9b3943463338 = function () {
    return handleError(function (arg0, arg1, arg2, arg3) {
      getObject(arg0).removeEventListener(
        getStringFromWasm0(arg1, arg2),
        getObject(arg3),
      )
    }, arguments)
  }
  imports.wbg.__wbg_instanceof_Window_f401953a2cf86220 = function (arg0) {
    let result
    try {
      result = getObject(arg0) instanceof Window
    } catch (_) {
      result = false
    }
    const ret = result
    return ret
  }
  imports.wbg.__wbg_clearInterval_4368213fd2b325b0 = function (arg0, arg1) {
    getObject(arg0).clearInterval(arg1)
  }
  imports.wbg.__wbg_disconnect_6675f32e2ae8deb7 = function (arg0) {
    getObject(arg0).disconnect()
  }
  imports.wbg.__wbg_addEventListener_53b787075bd5e003 = function () {
    return handleError(function (arg0, arg1, arg2, arg3) {
      getObject(arg0).addEventListener(
        getStringFromWasm0(arg1, arg2),
        getObject(arg3),
      )
    }, arguments)
  }
  imports.wbg.__wbindgen_object_clone_ref = function (arg0) {
    const ret = getObject(arg0)
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_data_1d8005e6d66d881b = function (arg0, arg1) {
    const ret = getObject(arg1).data
    var ptr1 = isLikeNone(ret)
      ? 0
      : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc)
    var len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_altKey_2e6c34c37088d8b1 = function (arg0) {
    const ret = getObject(arg0).altKey
    return ret
  }
  imports.wbg.__wbg_ctrlKey_bb5b6fef87339703 = function (arg0) {
    const ret = getObject(arg0).ctrlKey
    return ret
  }
  imports.wbg.__wbg_shiftKey_5911baf439ab232b = function (arg0) {
    const ret = getObject(arg0).shiftKey
    return ret
  }
  imports.wbg.__wbg_metaKey_6bf4ae4e83a11278 = function (arg0) {
    const ret = getObject(arg0).metaKey
    return ret
  }
  imports.wbg.__wbg_instanceof_Element_6945fc210db80ea9 = function (arg0) {
    let result
    try {
      result = getObject(arg0) instanceof Element
    } catch (_) {
      result = false
    }
    const ret = result
    return ret
  }
  imports.wbg.__wbg_is_010fdc0f4ab96916 = function (arg0, arg1) {
    const ret = Object.is(getObject(arg0), getObject(arg1))
    return ret
  }
  imports.wbg.__wbg_preventDefault_b1a4aafc79409429 = function (arg0) {
    getObject(arg0).preventDefault()
  }
  imports.wbg.__wbg_stopPropagation_fa5b666049c9fd02 = function (arg0) {
    getObject(arg0).stopPropagation()
  }
  imports.wbg.__wbg_location_2951b5ee34f19221 = function (arg0) {
    const ret = getObject(arg0).location
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_hash_553098e838e06c1d = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg1).hash
      const ptr1 = passStringToWasm0(
        ret,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
      )
      const len1 = WASM_VECTOR_LEN
      getInt32Memory0()[arg0 / 4 + 1] = len1
      getInt32Memory0()[arg0 / 4 + 0] = ptr1
    }, arguments)
  }
  imports.wbg.__wbg_button_367cdc7303e3cf9b = function (arg0) {
    const ret = getObject(arg0).button
    return ret
  }
  imports.wbg.__wbg_id_e0c4392b9418f9b0 = function (arg0, arg1) {
    const ret = getObject(arg1).id
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc,
    )
    const len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_clientX_fef6bf7a6bcf41b8 = function (arg0) {
    const ret = getObject(arg0).clientX
    return ret
  }
  imports.wbg.__wbg_clientY_df42f8fceab3cef2 = function (arg0) {
    const ret = getObject(arg0).clientY
    return ret
  }
  imports.wbg.__wbg_clipboardData_0427b2003659865a = function (arg0) {
    const ret = getObject(arg0).clipboardData
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_getData_35c5974f5cd7e02c = function () {
    return handleError(function (arg0, arg1, arg2, arg3) {
      const ret = getObject(arg1).getData(getStringFromWasm0(arg2, arg3))
      const ptr1 = passStringToWasm0(
        ret,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
      )
      const len1 = WASM_VECTOR_LEN
      getInt32Memory0()[arg0 / 4 + 1] = len1
      getInt32Memory0()[arg0 / 4 + 0] = ptr1
    }, arguments)
  }
  imports.wbg.__wbg_clientX_32cdd4a59d3eff3f = function (arg0) {
    const ret = getObject(arg0).clientX
    return ret
  }
  imports.wbg.__wbg_clientY_155c09997817066a = function (arg0) {
    const ret = getObject(arg0).clientY
    return ret
  }
  imports.wbg.__wbg_setvalue_78cb4f1fef58ae98 = function (arg0, arg1, arg2) {
    getObject(arg0).value = getStringFromWasm0(arg1, arg2)
  }
  imports.wbg.__wbg_dataTransfer_cef7816623bd8478 = function (arg0) {
    const ret = getObject(arg0).dataTransfer
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_items_5070ce38a6d53ed2 = function (arg0) {
    const ret = getObject(arg0).items
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_length_a23c520109d9ba0a = function (arg0) {
    const ret = getObject(arg0).length
    return ret
  }
  imports.wbg.__wbg_get_0fa6ec8bd6a5c256 = function (arg0, arg1) {
    const ret = getObject(arg0)[arg1 >>> 0]
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_type_e55aae30eb601b13 = function (arg0, arg1) {
    const ret = getObject(arg1).type
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc,
    )
    const len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_matches_dd4fdea75008ad05 = function (arg0) {
    const ret = getObject(arg0).matches
    return ret
  }
  imports.wbg.__wbg_value_47fe6384562f52ab = function (arg0, arg1) {
    const ret = getObject(arg1).value
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc,
    )
    const len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_isComposing_71872de364b1e1b7 = function (arg0) {
    const ret = getObject(arg0).isComposing
    return ret
  }
  imports.wbg.__wbg_files_a2848a7a7424820f = function (arg0) {
    const ret = getObject(arg0).files
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_length_4db38705d5c8ba2f = function (arg0) {
    const ret = getObject(arg0).length
    return ret
  }
  imports.wbg.__wbg_get_58f6d5f6aee3f846 = function (arg0, arg1) {
    const ret = getObject(arg0)[arg1 >>> 0]
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_name_f35eb93a73d94973 = function (arg0, arg1) {
    const ret = getObject(arg1).name
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc,
    )
    const len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_type_020d4abf13839639 = function (arg0, arg1) {
    const ret = getObject(arg1).type
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc,
    )
    const len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_lastModified_e774a1d2d0384c3b = function (arg0) {
    const ret = getObject(arg0).lastModified
    return ret
  }
  imports.wbg.__wbg_size_9c7e57fbd4f0f4b5 = function (arg0) {
    const ret = getObject(arg0).size
    return ret
  }
  imports.wbg.__wbg_arrayBuffer_307ddd1bd1d04e23 = function (arg0) {
    const ret = getObject(arg0).arrayBuffer()
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_new_63b92bc8671ed464 = function (arg0) {
    const ret = new Uint8Array(getObject(arg0))
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_length_c20a40f15020d68a = function (arg0) {
    const ret = getObject(arg0).length
    return ret
  }
  imports.wbg.__wbindgen_memory = function () {
    const ret = wasm.memory
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_buffer_12d079cc21e14bdb = function (arg0) {
    const ret = getObject(arg0).buffer
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_set_a47bac70306a19a7 = function (arg0, arg1, arg2) {
    getObject(arg0).set(getObject(arg1), arg2 >>> 0)
  }
  imports.wbg.__wbg_deltaMode_294b2eaf54047265 = function (arg0) {
    const ret = getObject(arg0).deltaMode
    return ret
  }
  imports.wbg.__wbg_deltaX_206576827ededbe5 = function (arg0) {
    const ret = getObject(arg0).deltaX
    return ret
  }
  imports.wbg.__wbg_deltaY_032e327e216f2b2b = function (arg0) {
    const ret = getObject(arg0).deltaY
    return ret
  }
  imports.wbg.__wbg_altKey_07da841b54bd3ed6 = function (arg0) {
    const ret = getObject(arg0).altKey
    return ret
  }
  imports.wbg.__wbg_ctrlKey_008695ce60a588f5 = function (arg0) {
    const ret = getObject(arg0).ctrlKey
    return ret
  }
  imports.wbg.__wbg_shiftKey_1e76dbfcdd36a4b4 = function (arg0) {
    const ret = getObject(arg0).shiftKey
    return ret
  }
  imports.wbg.__wbg_metaKey_86bfd3b0d3a8083f = function (arg0) {
    const ret = getObject(arg0).metaKey
    return ret
  }
  imports.wbg.__wbg_requestAnimationFrame_549258cfa66011f0 = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg0).requestAnimationFrame(getObject(arg1))
      return ret
    }, arguments)
  }
  imports.wbg.__wbg_isComposing_a0b97b7ba6491ed6 = function (arg0) {
    const ret = getObject(arg0).isComposing
    return ret
  }
  imports.wbg.__wbg_keyCode_2af7775f99bf8e33 = function (arg0) {
    const ret = getObject(arg0).keyCode
    return ret
  }
  imports.wbg.__wbg_key_dccf9e8aa1315a8e = function (arg0, arg1) {
    const ret = getObject(arg1).key
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc,
    )
    const len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_document_5100775d18896c16 = function (arg0) {
    const ret = getObject(arg0).document
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_elementFromPoint_2816f49709db4437 = function (
    arg0,
    arg1,
    arg2,
  ) {
    const ret = getObject(arg0).elementFromPoint(arg1, arg2)
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_devicePixelRatio_efc553b59506f64c = function (arg0) {
    const ret = getObject(arg0).devicePixelRatio
    return ret
  }
  imports.wbg.__wbg_at_c729a14f9fc27c62 = function (arg0, arg1) {
    const ret = getObject(arg0).at(arg1)
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_instanceof_ResizeObserverEntry_494c663b8cd0cdcf = function (
    arg0,
  ) {
    let result
    try {
      result = getObject(arg0) instanceof ResizeObserverEntry
    } catch (_) {
      result = false
    }
    const ret = result
    return ret
  }
  imports.wbg.__wbindgen_string_new = function (arg0, arg1) {
    const ret = getStringFromWasm0(arg0, arg1)
    return addHeapObject(ret)
  }
  imports.wbg.__wbindgen_in = function (arg0, arg1) {
    const ret = getObject(arg0) in getObject(arg1)
    return ret
  }
  imports.wbg.__wbg_devicePixelContentBoxSize_d5bcdcd5e96671f3 = function (
    arg0,
  ) {
    const ret = getObject(arg0).devicePixelContentBoxSize
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_instanceof_ResizeObserverSize_54b26135ae24a941 = function (
    arg0,
  ) {
    let result
    try {
      result = getObject(arg0) instanceof ResizeObserverSize
    } catch (_) {
      result = false
    }
    const ret = result
    return ret
  }
  imports.wbg.__wbg_inlineSize_ff0e40258cefeba2 = function (arg0) {
    const ret = getObject(arg0).inlineSize
    return ret
  }
  imports.wbg.__wbg_blockSize_73f4e5608c08713d = function (arg0) {
    const ret = getObject(arg0).blockSize
    return ret
  }
  imports.wbg.__wbg_contentBoxSize_c6294de1f1ca7e41 = function (arg0) {
    const ret = getObject(arg0).contentBoxSize
    return addHeapObject(ret)
  }
  imports.wbg.__wbindgen_is_undefined = function (arg0) {
    const ret = getObject(arg0) === undefined
    return ret
  }
  imports.wbg.__wbg_contentRect_bce644376332c7a5 = function (arg0) {
    const ret = getObject(arg0).contentRect
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_width_1e8430024cb82aba = function (arg0) {
    const ret = getObject(arg0).width
    return ret
  }
  imports.wbg.__wbg_height_0c1394f089d7bb71 = function (arg0) {
    const ret = getObject(arg0).height
    return ret
  }
  imports.wbg.__wbg_setwidth_080107476e633963 = function (arg0, arg1) {
    getObject(arg0).width = arg1 >>> 0
  }
  imports.wbg.__wbg_setheight_dc240617639f1f51 = function (arg0, arg1) {
    getObject(arg0).height = arg1 >>> 0
  }
  imports.wbg.__wbg_touches_c0f077e3c2429577 = function (arg0) {
    const ret = getObject(arg0).touches
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_length_679e0f1f9f0744bd = function (arg0) {
    const ret = getObject(arg0).length
    return ret
  }
  imports.wbg.__wbg_changedTouches_d044c818dbcb83b1 = function (arg0) {
    const ret = getObject(arg0).changedTouches
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_get_cbca0027ab731230 = function (arg0, arg1) {
    const ret = getObject(arg0)[arg1 >>> 0]
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_identifier_02d52b63cc6ddc4d = function (arg0) {
    const ret = getObject(arg0).identifier
    return ret
  }
  imports.wbg.__wbg_item_2b1028b3d39463e9 = function (arg0, arg1) {
    const ret = getObject(arg0).item(arg1 >>> 0)
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_force_139077aa422a42a5 = function (arg0) {
    const ret = getObject(arg0).force
    return ret
  }
  imports.wbg.__wbg_new_a337b459b99ce6cf = function () {
    const ret = new Error()
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_stack_3cb0faed69ec1c1c = function (arg0, arg1) {
    const ret = getObject(arg1).stack
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc,
    )
    const len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_error_d2d279fddc1936c2 = function (arg0, arg1) {
    let deferred0_0
    let deferred0_1
    try {
      deferred0_0 = arg0
      deferred0_1 = arg1
      console.error(getStringFromWasm0(arg0, arg1))
    } finally {
      wasm.__wbindgen_free(deferred0_0, deferred0_1, 1)
    }
  }
  imports.wbg.__wbg_warn_0c35b5ddd8466777 = function (arg0, arg1) {
    console.warn(getStringFromWasm0(arg0, arg1))
  }
  imports.wbg.__wbg_info_b7189e05f6c14531 = function (arg0, arg1) {
    console.info(getStringFromWasm0(arg0, arg1))
  }
  imports.wbg.__wbg_debug_161db4be06d72d91 = function (arg0, arg1) {
    console.debug(getStringFromWasm0(arg0, arg1))
  }
  imports.wbg.__wbg_remove_49b0a5925a04b955 = function (arg0) {
    getObject(arg0).remove()
  }
  imports.wbg.__wbg_getContext_df50fa48a8876636 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).getContext(getStringFromWasm0(arg1, arg2))
      return isLikeNone(ret) ? 0 : addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_instanceof_WebGlRenderingContext_d48361eb1e636d9a =
    function (arg0) {
      let result
      try {
        result = getObject(arg0) instanceof WebGLRenderingContext
      } catch (_) {
        result = false
      }
      const ret = result
      return ret
    }
  imports.wbg.__wbg_navigator_6c8fa55c5cc8796e = function (arg0) {
    const ret = getObject(arg0).navigator
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_userAgent_e94c7cbcdac01fea = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg1).userAgent
      const ptr1 = passStringToWasm0(
        ret,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
      )
      const len1 = WASM_VECTOR_LEN
      getInt32Memory0()[arg0 / 4 + 1] = len1
      getInt32Memory0()[arg0 / 4 + 0] = ptr1
    }, arguments)
  }
  imports.wbg.__wbg_getExtension_cb7fb87e4bca59c7 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).getExtension(getStringFromWasm0(arg1, arg2))
      return isLikeNone(ret) ? 0 : addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_getParameter_a77768abe8a51f24 = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg0).getParameter(arg1 >>> 0)
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbindgen_string_get = function (arg0, arg1) {
    const obj = getObject(arg1)
    const ret = typeof obj === "string" ? obj : undefined
    var ptr1 = isLikeNone(ret)
      ? 0
      : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc)
    var len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_getSupportedExtensions_d0eef4c0b5a783b8 = function (arg0) {
    const ret = getObject(arg0).getSupportedExtensions()
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_length_cd7af8117672b8b8 = function (arg0) {
    const ret = getObject(arg0).length
    return ret
  }
  imports.wbg.__wbg_get_bd8e338fbd5f5cc8 = function (arg0, arg1) {
    const ret = getObject(arg0)[arg1 >>> 0]
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_instanceof_WebGl2RenderingContext_6b8f92d566ced9e1 =
    function (arg0) {
      let result
      try {
        result = getObject(arg0) instanceof WebGL2RenderingContext
      } catch (_) {
        result = false
      }
      const ret = result
      return ret
    }
  imports.wbg.__wbg_getExtension_bef4112494c87f34 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).getExtension(getStringFromWasm0(arg1, arg2))
      return isLikeNone(ret) ? 0 : addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_getSupportedExtensions_7a174085f9e1983a = function (arg0) {
    const ret = getObject(arg0).getSupportedExtensions()
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_getParameter_aa9af66884d2b210 = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg0).getParameter(arg1 >>> 0)
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_width_aee8b8809b033b05 = function (arg0) {
    const ret = getObject(arg0).width
    return ret
  }
  imports.wbg.__wbg_height_80053d3c71b338e0 = function (arg0) {
    const ret = getObject(arg0).height
    return ret
  }
  imports.wbg.__wbg_speechSynthesis_e789dc7ae65b1456 = function () {
    return handleError(function (arg0) {
      const ret = getObject(arg0).speechSynthesis
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_cancel_c53e12d70a6a1f24 = function (arg0) {
    getObject(arg0).cancel()
  }
  imports.wbg.__wbg_newwithtext_d0ce0ecd073e2cbd = function () {
    return handleError(function (arg0, arg1) {
      const ret = new SpeechSynthesisUtterance(getStringFromWasm0(arg0, arg1))
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_setrate_f21df78656ede74f = function (arg0, arg1) {
    getObject(arg0).rate = arg1
  }
  imports.wbg.__wbg_setpitch_b479d1137831d7d1 = function (arg0, arg1) {
    getObject(arg0).pitch = arg1
  }
  imports.wbg.__wbg_setvolume_49dc22f414becb85 = function (arg0, arg1) {
    getObject(arg0).volume = arg1
  }
  imports.wbg.__wbg_speak_7754ac865685ef61 = function (arg0, arg1) {
    getObject(arg0).speak(getObject(arg1))
  }
  imports.wbg.__wbg_body_edb1908d3ceff3a1 = function (arg0) {
    const ret = getObject(arg0).body
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_style_c3fc3dd146182a2d = function (arg0) {
    const ret = getObject(arg0).style
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_setProperty_ea7d15a2b591aa97 = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4) {
      getObject(arg0).setProperty(
        getStringFromWasm0(arg1, arg2),
        getStringFromWasm0(arg3, arg4),
      )
    }, arguments)
  }
  imports.wbg.__wbg_open_cc82b8aaf0c296c1 = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4) {
      const ret = getObject(arg0).open(
        getStringFromWasm0(arg1, arg2),
        getStringFromWasm0(arg3, arg4),
      )
      return isLikeNone(ret) ? 0 : addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_clipboard_45ef2514e9ece120 = function (arg0) {
    const ret = getObject(arg0).clipboard
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_writeText_4f1bf9bc5850bc26 = function (arg0, arg1, arg2) {
    const ret = getObject(arg0).writeText(getStringFromWasm0(arg1, arg2))
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_isSecureContext_3dd59a5324a1c6d5 = function (arg0) {
    const ret = getObject(arg0).isSecureContext
    return ret
  }
  imports.wbg.__wbg_blur_51f7b635f18a0eec = function () {
    return handleError(function (arg0) {
      getObject(arg0).blur()
    }, arguments)
  }
  imports.wbg.__wbg_focus_39d4b8ba8ff9df14 = function () {
    return handleError(function (arg0) {
      getObject(arg0).focus()
    }, arguments)
  }
  imports.wbg.__wbg_createTexture_c13c31b2b132c17f = function (arg0) {
    const ret = getObject(arg0).createTexture()
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_createTexture_18b4a88c14cb086e = function (arg0) {
    const ret = getObject(arg0).createTexture()
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_bindTexture_772f5eb022019d87 = function (arg0, arg1, arg2) {
    getObject(arg0).bindTexture(arg1 >>> 0, getObject(arg2))
  }
  imports.wbg.__wbg_bindTexture_75a698c47a923814 = function (arg0, arg1, arg2) {
    getObject(arg0).bindTexture(arg1 >>> 0, getObject(arg2))
  }
  imports.wbg.__wbg_disable_5dd8c3842de93e92 = function (arg0, arg1) {
    getObject(arg0).disable(arg1 >>> 0)
  }
  imports.wbg.__wbg_disable_f0ef6e9a7ac6ddd7 = function (arg0, arg1) {
    getObject(arg0).disable(arg1 >>> 0)
  }
  imports.wbg.__wbg_viewport_221ade2aef6032c8 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
  ) {
    getObject(arg0).viewport(arg1, arg2, arg3, arg4)
  }
  imports.wbg.__wbg_viewport_7414e7e2a83afc72 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
  ) {
    getObject(arg0).viewport(arg1, arg2, arg3, arg4)
  }
  imports.wbg.__wbg_clearColor_42707553c40e0e0f = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
  ) {
    getObject(arg0).clearColor(arg1, arg2, arg3, arg4)
  }
  imports.wbg.__wbg_clearColor_480962bfac4e1cbd = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
  ) {
    getObject(arg0).clearColor(arg1, arg2, arg3, arg4)
  }
  imports.wbg.__wbg_clear_f9731a47df2e70d8 = function (arg0, arg1) {
    getObject(arg0).clear(arg1 >>> 0)
  }
  imports.wbg.__wbg_clear_8e2508724944df18 = function (arg0, arg1) {
    getObject(arg0).clear(arg1 >>> 0)
  }
  imports.wbg.__wbg_scissor_75ba2245d4db0eaf = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
  ) {
    getObject(arg0).scissor(arg1, arg2, arg3, arg4)
  }
  imports.wbg.__wbg_scissor_726eea865bbd6809 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
  ) {
    getObject(arg0).scissor(arg1, arg2, arg3, arg4)
  }
  imports.wbg.__wbg_bindVertexArrayOES_abe2fd389c6a2f56 = function (
    arg0,
    arg1,
  ) {
    getObject(arg0).bindVertexArrayOES(getObject(arg1))
  }
  imports.wbg.__wbg_bindBuffer_1e5043751efddd4f = function (arg0, arg1, arg2) {
    getObject(arg0).bindBuffer(arg1 >>> 0, getObject(arg2))
  }
  imports.wbg.__wbg_bindBuffer_90d4fb91538001d5 = function (arg0, arg1, arg2) {
    getObject(arg0).bindBuffer(arg1 >>> 0, getObject(arg2))
  }
  imports.wbg.__wbg_disableVertexAttribArray_12bc9adefa738796 = function (
    arg0,
    arg1,
  ) {
    getObject(arg0).disableVertexAttribArray(arg1 >>> 0)
  }
  imports.wbg.__wbg_disableVertexAttribArray_e4f458e34e54fe78 = function (
    arg0,
    arg1,
  ) {
    getObject(arg0).disableVertexAttribArray(arg1 >>> 0)
  }
  imports.wbg.__wbg_bindVertexArray_239574d42dbbd203 = function (arg0, arg1) {
    getObject(arg0).bindVertexArray(getObject(arg1))
  }
  imports.wbg.__wbg_localStorage_e381d34d0c40c761 = function () {
    return handleError(function (arg0) {
      const ret = getObject(arg0).localStorage
      return isLikeNone(ret) ? 0 : addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_getItem_164e8e5265095b87 = function () {
    return handleError(function (arg0, arg1, arg2, arg3) {
      const ret = getObject(arg1).getItem(getStringFromWasm0(arg2, arg3))
      var ptr1 = isLikeNone(ret)
        ? 0
        : passStringToWasm0(
            ret,
            wasm.__wbindgen_malloc,
            wasm.__wbindgen_realloc,
          )
      var len1 = WASM_VECTOR_LEN
      getInt32Memory0()[arg0 / 4 + 1] = len1
      getInt32Memory0()[arg0 / 4 + 0] = ptr1
    }, arguments)
  }
  imports.wbg.__wbg_setItem_ba2bb41d73dac079 = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4) {
      getObject(arg0).setItem(
        getStringFromWasm0(arg1, arg2),
        getStringFromWasm0(arg3, arg4),
      )
    }, arguments)
  }
  imports.wbg.__wbg_activeElement_fa7feca08f5028c0 = function (arg0) {
    const ret = getObject(arg0).activeElement
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_performance_3298a9628a5c8aa4 = function (arg0) {
    const ret = getObject(arg0).performance
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_now_4e659b3d15f470d9 = function (arg0) {
    const ret = getObject(arg0).now()
    return ret
  }
  imports.wbg.__wbg_getBoundingClientRect_91e6d57c4e65f745 = function (arg0) {
    const ret = getObject(arg0).getBoundingClientRect()
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_left_fe0a839abdd508f4 = function (arg0) {
    const ret = getObject(arg0).left
    return ret
  }
  imports.wbg.__wbg_top_c4e2234a035a3d25 = function (arg0) {
    const ret = getObject(arg0).top
    return ret
  }
  imports.wbg.__wbg_right_4659608ec17bdea7 = function (arg0) {
    const ret = getObject(arg0).right
    return ret
  }
  imports.wbg.__wbg_bottom_91d8cb531cf1afd2 = function (arg0) {
    const ret = getObject(arg0).bottom
    return ret
  }
  imports.wbg.__wbg_getComputedStyle_078292ffe423aded = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg0).getComputedStyle(getObject(arg1))
      return isLikeNone(ret) ? 0 : addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_getPropertyValue_fa32ee1811f224cb = function () {
    return handleError(function (arg0, arg1, arg2, arg3) {
      const ret = getObject(arg1).getPropertyValue(
        getStringFromWasm0(arg2, arg3),
      )
      const ptr1 = passStringToWasm0(
        ret,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
      )
      const len1 = WASM_VECTOR_LEN
      getInt32Memory0()[arg0 / 4 + 1] = len1
      getInt32Memory0()[arg0 / 4 + 0] = ptr1
    }, arguments)
  }
  imports.wbg.__wbindgen_number_get = function (arg0, arg1) {
    const obj = getObject(arg1)
    const ret = typeof obj === "number" ? obj : undefined
    getFloat64Memory0()[arg0 / 8 + 1] = isLikeNone(ret) ? 0 : ret
    getInt32Memory0()[arg0 / 4 + 0] = !isLikeNone(ret)
  }
  imports.wbg.__wbg_createProgram_9affbfa62b7b2608 = function (arg0) {
    const ret = getObject(arg0).createProgram()
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_createProgram_7759fb2effb5d9b3 = function (arg0) {
    const ret = getObject(arg0).createProgram()
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_attachShader_6397dc4fd87343d3 = function (
    arg0,
    arg1,
    arg2,
  ) {
    getObject(arg0).attachShader(getObject(arg1), getObject(arg2))
  }
  imports.wbg.__wbg_attachShader_2112634b3ffa9e9f = function (
    arg0,
    arg1,
    arg2,
  ) {
    getObject(arg0).attachShader(getObject(arg1), getObject(arg2))
  }
  imports.wbg.__wbg_linkProgram_af5fed9dc3f1cdf9 = function (arg0, arg1) {
    getObject(arg0).linkProgram(getObject(arg1))
  }
  imports.wbg.__wbg_linkProgram_eabc664217816e72 = function (arg0, arg1) {
    getObject(arg0).linkProgram(getObject(arg1))
  }
  imports.wbg.__wbg_getProgramParameter_10c8a43809fb8c2e = function (
    arg0,
    arg1,
    arg2,
  ) {
    const ret = getObject(arg0).getProgramParameter(getObject(arg1), arg2 >>> 0)
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_getProgramParameter_7b04ca71a79d9047 = function (
    arg0,
    arg1,
    arg2,
  ) {
    const ret = getObject(arg0).getProgramParameter(getObject(arg1), arg2 >>> 0)
    return addHeapObject(ret)
  }
  imports.wbg.__wbindgen_boolean_get = function (arg0) {
    const v = getObject(arg0)
    const ret = typeof v === "boolean" ? (v ? 1 : 0) : 2
    return ret
  }
  imports.wbg.__wbg_getProgramInfoLog_bf1fba8fa90667c7 = function (
    arg0,
    arg1,
    arg2,
  ) {
    const ret = getObject(arg1).getProgramInfoLog(getObject(arg2))
    var ptr1 = isLikeNone(ret)
      ? 0
      : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc)
    var len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_getProgramInfoLog_4d189135f8d5a2de = function (
    arg0,
    arg1,
    arg2,
  ) {
    const ret = getObject(arg1).getProgramInfoLog(getObject(arg2))
    var ptr1 = isLikeNone(ret)
      ? 0
      : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc)
    var len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_getUniformLocation_6eedfb513ccce732 = function (
    arg0,
    arg1,
    arg2,
    arg3,
  ) {
    const ret = getObject(arg0).getUniformLocation(
      getObject(arg1),
      getStringFromWasm0(arg2, arg3),
    )
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_getUniformLocation_51ec30e3755e574d = function (
    arg0,
    arg1,
    arg2,
    arg3,
  ) {
    const ret = getObject(arg0).getUniformLocation(
      getObject(arg1),
      getStringFromWasm0(arg2, arg3),
    )
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_getAttribLocation_0a3d71a11394d043 = function (
    arg0,
    arg1,
    arg2,
    arg3,
  ) {
    const ret = getObject(arg0).getAttribLocation(
      getObject(arg1),
      getStringFromWasm0(arg2, arg3),
    )
    return ret
  }
  imports.wbg.__wbg_getAttribLocation_4e2b9fe88dcc9802 = function (
    arg0,
    arg1,
    arg2,
    arg3,
  ) {
    const ret = getObject(arg0).getAttribLocation(
      getObject(arg1),
      getStringFromWasm0(arg2, arg3),
    )
    return ret
  }
  imports.wbg.__wbg_createVertexArrayOES_886be8a08db32ce6 = function (arg0) {
    const ret = getObject(arg0).createVertexArrayOES()
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_createVertexArray_4f450ed4d4a69acf = function (arg0) {
    const ret = getObject(arg0).createVertexArray()
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_vertexAttribPointer_c25e4c5ed17f8a1d = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6,
  ) {
    getObject(arg0).vertexAttribPointer(
      arg1 >>> 0,
      arg2,
      arg3 >>> 0,
      arg4 !== 0,
      arg5,
      arg6,
    )
  }
  imports.wbg.__wbg_vertexAttribPointer_4416f0325c02aa13 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
    arg5,
    arg6,
  ) {
    getObject(arg0).vertexAttribPointer(
      arg1 >>> 0,
      arg2,
      arg3 >>> 0,
      arg4 !== 0,
      arg5,
      arg6,
    )
  }
  imports.wbg.__wbg_enableVertexAttribArray_6d44444aa994f42a = function (
    arg0,
    arg1,
  ) {
    getObject(arg0).enableVertexAttribArray(arg1 >>> 0)
  }
  imports.wbg.__wbg_enableVertexAttribArray_9d7b7e199f86e09b = function (
    arg0,
    arg1,
  ) {
    getObject(arg0).enableVertexAttribArray(arg1 >>> 0)
  }
  imports.wbg.__wbg_texParameteri_d1035ed45d6c5655 = function (
    arg0,
    arg1,
    arg2,
    arg3,
  ) {
    getObject(arg0).texParameteri(arg1 >>> 0, arg2 >>> 0, arg3)
  }
  imports.wbg.__wbg_texParameteri_8f70dffce11d7da1 = function (
    arg0,
    arg1,
    arg2,
    arg3,
  ) {
    getObject(arg0).texParameteri(arg1 >>> 0, arg2 >>> 0, arg3)
  }
  imports.wbg.__wbg_pixelStorei_054e50b5fdc17824 = function (arg0, arg1, arg2) {
    getObject(arg0).pixelStorei(arg1 >>> 0, arg2)
  }
  imports.wbg.__wbg_pixelStorei_162a23ba7872b886 = function (arg0, arg1, arg2) {
    getObject(arg0).pixelStorei(arg1 >>> 0, arg2)
  }
  imports.wbg.__wbg_newwithbyteoffsetandlength_aa4a17c33a06e5cb = function (
    arg0,
    arg1,
    arg2,
  ) {
    const ret = new Uint8Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0)
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_texSubImage2D_55a407e48f3a5cb4 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9,
    ) {
      getObject(arg0).texSubImage2D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7 >>> 0,
        arg8 >>> 0,
        getObject(arg9),
      )
    }, arguments)
  }
  imports.wbg.__wbg_texSubImage2D_b4ac5eac47418cc5 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9,
    ) {
      getObject(arg0).texSubImage2D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7 >>> 0,
        arg8 >>> 0,
        getObject(arg9),
      )
    }, arguments)
  }
  imports.wbg.__wbg_texImage2D_a14a3c7863e25c89 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9,
    ) {
      getObject(arg0).texImage2D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7 >>> 0,
        arg8 >>> 0,
        getObject(arg9),
      )
    }, arguments)
  }
  imports.wbg.__wbg_texImage2D_2558a70047650d54 = function () {
    return handleError(function (
      arg0,
      arg1,
      arg2,
      arg3,
      arg4,
      arg5,
      arg6,
      arg7,
      arg8,
      arg9,
    ) {
      getObject(arg0).texImage2D(
        arg1 >>> 0,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7 >>> 0,
        arg8 >>> 0,
        getObject(arg9),
      )
    }, arguments)
  }
  imports.wbg.__wbg_enable_7abe812a71c76206 = function (arg0, arg1) {
    getObject(arg0).enable(arg1 >>> 0)
  }
  imports.wbg.__wbg_enable_8b3019da8846ce76 = function (arg0, arg1) {
    getObject(arg0).enable(arg1 >>> 0)
  }
  imports.wbg.__wbg_colorMask_03aa359acc86fd70 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
  ) {
    getObject(arg0).colorMask(arg1 !== 0, arg2 !== 0, arg3 !== 0, arg4 !== 0)
  }
  imports.wbg.__wbg_colorMask_21a93d0180bcbffa = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
  ) {
    getObject(arg0).colorMask(arg1 !== 0, arg2 !== 0, arg3 !== 0, arg4 !== 0)
  }
  imports.wbg.__wbg_blendEquationSeparate_721f30ba584a5233 = function (
    arg0,
    arg1,
    arg2,
  ) {
    getObject(arg0).blendEquationSeparate(arg1 >>> 0, arg2 >>> 0)
  }
  imports.wbg.__wbg_blendEquationSeparate_34aa4cecd02882ab = function (
    arg0,
    arg1,
    arg2,
  ) {
    getObject(arg0).blendEquationSeparate(arg1 >>> 0, arg2 >>> 0)
  }
  imports.wbg.__wbg_blendFuncSeparate_abe2ad4272c8365e = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
  ) {
    getObject(arg0).blendFuncSeparate(
      arg1 >>> 0,
      arg2 >>> 0,
      arg3 >>> 0,
      arg4 >>> 0,
    )
  }
  imports.wbg.__wbg_blendFuncSeparate_3c342f57887c2900 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
  ) {
    getObject(arg0).blendFuncSeparate(
      arg1 >>> 0,
      arg2 >>> 0,
      arg3 >>> 0,
      arg4 >>> 0,
    )
  }
  imports.wbg.__wbg_useProgram_c637e43f9cd4c07a = function (arg0, arg1) {
    getObject(arg0).useProgram(getObject(arg1))
  }
  imports.wbg.__wbg_useProgram_757fab437af29c20 = function (arg0, arg1) {
    getObject(arg0).useProgram(getObject(arg1))
  }
  imports.wbg.__wbg_uniform2f_dbf02e46dd8c211d = function (
    arg0,
    arg1,
    arg2,
    arg3,
  ) {
    getObject(arg0).uniform2f(getObject(arg1), arg2, arg3)
  }
  imports.wbg.__wbg_uniform2f_e8287b8c104117ac = function (
    arg0,
    arg1,
    arg2,
    arg3,
  ) {
    getObject(arg0).uniform2f(getObject(arg1), arg2, arg3)
  }
  imports.wbg.__wbg_uniform1i_badd5ff70c0d30bf = function (arg0, arg1, arg2) {
    getObject(arg0).uniform1i(getObject(arg1), arg2)
  }
  imports.wbg.__wbg_uniform1i_bdcd75be097285e6 = function (arg0, arg1, arg2) {
    getObject(arg0).uniform1i(getObject(arg1), arg2)
  }
  imports.wbg.__wbg_activeTexture_5f084e1b3f14853e = function (arg0, arg1) {
    getObject(arg0).activeTexture(arg1 >>> 0)
  }
  imports.wbg.__wbg_activeTexture_d42cec3a26e47a5b = function (arg0, arg1) {
    getObject(arg0).activeTexture(arg1 >>> 0)
  }
  imports.wbg.__wbg_drawElements_0861624300587fcd = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
  ) {
    getObject(arg0).drawElements(arg1 >>> 0, arg2, arg3 >>> 0, arg4)
  }
  imports.wbg.__wbg_drawElements_565a93d1efa4da07 = function (
    arg0,
    arg1,
    arg2,
    arg3,
    arg4,
  ) {
    getObject(arg0).drawElements(arg1 >>> 0, arg2, arg3 >>> 0, arg4)
  }
  imports.wbg.__wbg_createShader_55ca04b44164bd41 = function (arg0, arg1) {
    const ret = getObject(arg0).createShader(arg1 >>> 0)
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_createShader_b474ef421ec0f80b = function (arg0, arg1) {
    const ret = getObject(arg0).createShader(arg1 >>> 0)
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_shaderSource_7891a1fcb69a0023 = function (
    arg0,
    arg1,
    arg2,
    arg3,
  ) {
    getObject(arg0).shaderSource(
      getObject(arg1),
      getStringFromWasm0(arg2, arg3),
    )
  }
  imports.wbg.__wbg_shaderSource_7943d06f24862a3b = function (
    arg0,
    arg1,
    arg2,
    arg3,
  ) {
    getObject(arg0).shaderSource(
      getObject(arg1),
      getStringFromWasm0(arg2, arg3),
    )
  }
  imports.wbg.__wbg_compileShader_3af4719dfdb508e3 = function (arg0, arg1) {
    getObject(arg0).compileShader(getObject(arg1))
  }
  imports.wbg.__wbg_compileShader_f40e0c51a7a836fd = function (arg0, arg1) {
    getObject(arg0).compileShader(getObject(arg1))
  }
  imports.wbg.__wbg_getShaderParameter_60b69083e8d662ce = function (
    arg0,
    arg1,
    arg2,
  ) {
    const ret = getObject(arg0).getShaderParameter(getObject(arg1), arg2 >>> 0)
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_getShaderParameter_4ddb51279bb1500b = function (
    arg0,
    arg1,
    arg2,
  ) {
    const ret = getObject(arg0).getShaderParameter(getObject(arg1), arg2 >>> 0)
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_getShaderInfoLog_0262cb299092ce92 = function (
    arg0,
    arg1,
    arg2,
  ) {
    const ret = getObject(arg1).getShaderInfoLog(getObject(arg2))
    var ptr1 = isLikeNone(ret)
      ? 0
      : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc)
    var len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_getShaderInfoLog_d5de3e4eab06fc46 = function (
    arg0,
    arg1,
    arg2,
  ) {
    const ret = getObject(arg1).getShaderInfoLog(getObject(arg2))
    var ptr1 = isLikeNone(ret)
      ? 0
      : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc)
    var len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_getError_fd1f7b2b2ba5a860 = function (arg0) {
    const ret = getObject(arg0).getError()
    return ret
  }
  imports.wbg.__wbg_getError_d02c89917f45dd5e = function (arg0) {
    const ret = getObject(arg0).getError()
    return ret
  }
  imports.wbg.__wbg_deleteShader_e5c778f25b722e68 = function (arg0, arg1) {
    getObject(arg0).deleteShader(getObject(arg1))
  }
  imports.wbg.__wbg_deleteShader_138a810cc0ca9986 = function (arg0, arg1) {
    getObject(arg0).deleteShader(getObject(arg1))
  }
  imports.wbg.__wbg_detachShader_2be0011a543a788a = function (
    arg0,
    arg1,
    arg2,
  ) {
    getObject(arg0).detachShader(getObject(arg1), getObject(arg2))
  }
  imports.wbg.__wbg_detachShader_6cdc9c293ddee02e = function (
    arg0,
    arg1,
    arg2,
  ) {
    getObject(arg0).detachShader(getObject(arg1), getObject(arg2))
  }
  imports.wbg.__wbg_createBuffer_34e01f5c10929b41 = function (arg0) {
    const ret = getObject(arg0).createBuffer()
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_createBuffer_7f57647465d111f0 = function (arg0) {
    const ret = getObject(arg0).createBuffer()
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_bufferData_5d1e6b8eaa7d23c8 = function (
    arg0,
    arg1,
    arg2,
    arg3,
  ) {
    getObject(arg0).bufferData(arg1 >>> 0, getObject(arg2), arg3 >>> 0)
  }
  imports.wbg.__wbg_bufferData_c787516945ba48c2 = function (
    arg0,
    arg1,
    arg2,
    arg3,
  ) {
    getObject(arg0).bufferData(arg1 >>> 0, getObject(arg2), arg3 >>> 0)
  }
  imports.wbg.__wbg_deleteBuffer_96df38349e3487d2 = function (arg0, arg1) {
    getObject(arg0).deleteBuffer(getObject(arg1))
  }
  imports.wbg.__wbg_deleteBuffer_fca5d765302c9a4e = function (arg0, arg1) {
    getObject(arg0).deleteBuffer(getObject(arg1))
  }
  imports.wbg.__wbg_deleteTexture_f89d8e417b156960 = function (arg0, arg1) {
    getObject(arg0).deleteTexture(getObject(arg1))
  }
  imports.wbg.__wbg_deleteTexture_eae7abcfa3015f09 = function (arg0, arg1) {
    getObject(arg0).deleteTexture(getObject(arg1))
  }
  imports.wbg.__wbg_iterator_2cee6dadfd956dfa = function () {
    const ret = Symbol.iterator
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_get_e3c254076557e348 = function () {
    return handleError(function (arg0, arg1) {
      const ret = Reflect.get(getObject(arg0), getObject(arg1))
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbindgen_is_function = function (arg0) {
    const ret = typeof getObject(arg0) === "function"
    return ret
  }
  imports.wbg.__wbg_call_27c0f87801dedf93 = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg0).call(getObject(arg1))
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbindgen_is_object = function (arg0) {
    const val = getObject(arg0)
    const ret = typeof val === "object" && val !== null
    return ret
  }
  imports.wbg.__wbg_next_40fc327bfc8770e6 = function (arg0) {
    const ret = getObject(arg0).next
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_self_ce0dbfc45cf2f5be = function () {
    return handleError(function () {
      const ret = self.self
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_window_c6fb939a7f436783 = function () {
    return handleError(function () {
      const ret = window.window
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_globalThis_d1e6af4856ba331b = function () {
    return handleError(function () {
      const ret = globalThis.globalThis
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_global_207b558942527489 = function () {
    return handleError(function () {
      const ret = global.global
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_newnoargs_e258087cd0daa0ea = function (arg0, arg1) {
    const ret = new Function(getStringFromWasm0(arg0, arg1))
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_new_0d76b0581eca6298 = function () {
    return handleError(function () {
      const ret = new AbortController()
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_has_0af94d20077affa2 = function () {
    return handleError(function (arg0, arg1) {
      const ret = Reflect.has(getObject(arg0), getObject(arg1))
      return ret
    }, arguments)
  }
  imports.wbg.__wbg_fetch_921fad6ef9e883dd = function (arg0, arg1) {
    const ret = getObject(arg0).fetch(getObject(arg1))
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_fetch_eadcbc7351113537 = function (arg0) {
    const ret = fetch(getObject(arg0))
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_setmultiple_df7c9f1022e04575 = function (arg0, arg1) {
    getObject(arg0).multiple = arg1 !== 0
  }
  imports.wbg.__wbg_new_81740750da40724f = function (arg0, arg1) {
    try {
      var state0 = { a: arg0, b: arg1 }
      var cb0 = (arg0, arg1) => {
        const a = state0.a
        state0.a = 0
        try {
          return __wbg_adapter_93(a, state0.b, arg0, arg1)
        } finally {
          state0.a = a
        }
      }
      const ret = new Promise(cb0)
      return addHeapObject(ret)
    } finally {
      state0.a = state0.b = 0
    }
  }
  imports.wbg.__wbg_files_8b6e6eff43af0f6d = function (arg0) {
    const ret = getObject(arg0).files
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_setonclick_4fd9bd8531d33a17 = function (arg0, arg1) {
    getObject(arg0).onclick = getObject(arg1)
  }
  imports.wbg.__wbg_new_16b304a2cfa7ff4a = function () {
    const ret = new Array()
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_buffer_dd7f74bc60f1faab = function (arg0) {
    const ret = getObject(arg0).buffer
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_push_a5b05aedc7234f9f = function (arg0, arg1) {
    const ret = getObject(arg0).push(getObject(arg1))
    return ret
  }
  imports.wbg.__wbg_new_72fb9a18b5ae2624 = function () {
    const ret = new Object()
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_newwithu8arraysequenceandoptions_366f462e1b363808 =
    function () {
      return handleError(function (arg0, arg1) {
        const ret = new Blob(getObject(arg0), getObject(arg1))
        return addHeapObject(ret)
      }, arguments)
    }
  imports.wbg.__wbg_createObjectURL_ad8244759309f204 = function () {
    return handleError(function (arg0, arg1) {
      const ret = URL.createObjectURL(getObject(arg1))
      const ptr1 = passStringToWasm0(
        ret,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
      )
      const len1 = WASM_VECTOR_LEN
      getInt32Memory0()[arg0 / 4 + 1] = len1
      getInt32Memory0()[arg0 / 4 + 0] = ptr1
    }, arguments)
  }
  imports.wbg.__wbg_sethref_b0712139dd35e2fd = function (arg0, arg1, arg2) {
    getObject(arg0).href = getStringFromWasm0(arg1, arg2)
  }
  imports.wbg.__wbg_setdownload_65ac7e7c800d764e = function (arg0, arg1, arg2) {
    getObject(arg0).download = getStringFromWasm0(arg1, arg2)
  }
  imports.wbg.__wbg_appendChild_580ccb11a660db68 = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg0).appendChild(getObject(arg1))
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_call_b3ca7c6051f9bec1 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).call(getObject(arg1), getObject(arg2))
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_createElement_8bae7856a4bb7411 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).createElement(getStringFromWasm0(arg1, arg2))
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_setid_37bacc3f09f555aa = function (arg0, arg1, arg2) {
    getObject(arg0).id = getStringFromWasm0(arg1, arg2)
  }
  imports.wbg.__wbg_instanceof_HtmlElement_3bcc4ff70cfdcba5 = function (arg0) {
    let result
    try {
      result = getObject(arg0) instanceof HTMLElement
    } catch (_) {
      result = false
    }
    const ret = result
    return ret
  }
  imports.wbg.__wbg_setinnerHTML_26d69b59e1af99c7 = function (
    arg0,
    arg1,
    arg2,
  ) {
    getObject(arg0).innerHTML = getStringFromWasm0(arg1, arg2)
  }
  imports.wbg.__wbg_instanceof_HtmlInputElement_307512fe1252c849 = function (
    arg0,
  ) {
    let result
    try {
      result = getObject(arg0) instanceof HTMLInputElement
    } catch (_) {
      result = false
    }
    const ret = result
    return ret
  }
  imports.wbg.__wbg_settype_12715bd23e119883 = function (arg0, arg1, arg2) {
    getObject(arg0).type = getStringFromWasm0(arg1, arg2)
  }
  imports.wbg.__wbg_instanceof_HtmlAnchorElement_5fc0eb2fbc8672d8 = function (
    arg0,
  ) {
    let result
    try {
      result = getObject(arg0) instanceof HTMLAnchorElement
    } catch (_) {
      result = false
    }
    const ret = result
    return ret
  }
  imports.wbg.__wbg_setinnerText_087b7e3f90d97466 = function (
    arg0,
    arg1,
    arg2,
  ) {
    getObject(arg0).innerText = getStringFromWasm0(arg1, arg2)
  }
  imports.wbg.__wbg_setaccept_e9aecafb8dbc1efa = function (arg0, arg1, arg2) {
    getObject(arg0).accept = getStringFromWasm0(arg1, arg2)
  }
  imports.wbg.__wbg_instanceof_HtmlButtonElement_534f7aa847dae46f = function (
    arg0,
  ) {
    let result
    try {
      result = getObject(arg0) instanceof HTMLButtonElement
    } catch (_) {
      result = false
    }
    const ret = result
    return ret
  }
  imports.wbg.__wbindgen_debug_string = function (arg0, arg1) {
    const ret = debugString(getObject(arg1))
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc,
    )
    const len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_new_c1e4a76f0b5c28b8 = function () {
    return handleError(function () {
      const ret = new FileReader()
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_setonload_0af77109dbfaa065 = function (arg0, arg1) {
    getObject(arg0).onload = getObject(arg1)
  }
  imports.wbg.__wbg_readAsArrayBuffer_4f4ed73c7dc0ce42 = function () {
    return handleError(function (arg0, arg1) {
      getObject(arg0).readAsArrayBuffer(getObject(arg1))
    }, arguments)
  }
  imports.wbg.__wbg_result_77ceeec1e3a16df7 = function () {
    return handleError(function (arg0) {
      const ret = getObject(arg0).result
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_search_489f12953342ec1f = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg1).search
      const ptr1 = passStringToWasm0(
        ret,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
      )
      const len1 = WASM_VECTOR_LEN
      getInt32Memory0()[arg0 / 4 + 1] = len1
      getInt32Memory0()[arg0 / 4 + 0] = ptr1
    }, arguments)
  }
  imports.wbg.__wbg_newwithstr_2902c1dd2ea907e6 = function () {
    return handleError(function (arg0, arg1) {
      const ret = new URLSearchParams(getStringFromWasm0(arg0, arg1))
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_get_6c30336bb91c60e5 = function (arg0, arg1, arg2, arg3) {
    const ret = getObject(arg1).get(getStringFromWasm0(arg2, arg3))
    var ptr1 = isLikeNone(ret)
      ? 0
      : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc)
    var len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_cancelAnimationFrame_111532f326e480af = function () {
    return handleError(function (arg0, arg1) {
      getObject(arg0).cancelAnimationFrame(arg1)
    }, arguments)
  }
  imports.wbg.__wbg_deleteProgram_641402f7551587d8 = function (arg0, arg1) {
    getObject(arg0).deleteProgram(getObject(arg1))
  }
  imports.wbg.__wbg_deleteProgram_a06d69620332cc70 = function (arg0, arg1) {
    getObject(arg0).deleteProgram(getObject(arg1))
  }
  imports.wbg.__wbg_getElementById_c369ff43f0db99cf = function (
    arg0,
    arg1,
    arg2,
  ) {
    const ret = getObject(arg0).getElementById(getStringFromWasm0(arg1, arg2))
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_instanceof_HtmlCanvasElement_46bdbf323b0b18d1 = function (
    arg0,
  ) {
    let result
    try {
      result = getObject(arg0) instanceof HTMLCanvasElement
    } catch (_) {
      result = false
    }
    const ret = result
    return ret
  }
  imports.wbg.__wbg_matchMedia_66bb21e3ef19270c = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).matchMedia(getStringFromWasm0(arg1, arg2))
      return isLikeNone(ret) ? 0 : addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_matches_e14ed9ff8291cf24 = function (arg0) {
    const ret = getObject(arg0).matches
    return ret
  }
  imports.wbg.__wbg_href_706b235ecfe6848c = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg1).href
      const ptr1 = passStringToWasm0(
        ret,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
      )
      const len1 = WASM_VECTOR_LEN
      getInt32Memory0()[arg0 / 4 + 1] = len1
      getInt32Memory0()[arg0 / 4 + 0] = ptr1
    }, arguments)
  }
  imports.wbg.__wbg_protocol_b7292c581cfe1e5c = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg1).protocol
      const ptr1 = passStringToWasm0(
        ret,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
      )
      const len1 = WASM_VECTOR_LEN
      getInt32Memory0()[arg0 / 4 + 1] = len1
      getInt32Memory0()[arg0 / 4 + 0] = ptr1
    }, arguments)
  }
  imports.wbg.__wbg_host_8f1b8ead257c8135 = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg1).host
      const ptr1 = passStringToWasm0(
        ret,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
      )
      const len1 = WASM_VECTOR_LEN
      getInt32Memory0()[arg0 / 4 + 1] = len1
      getInt32Memory0()[arg0 / 4 + 0] = ptr1
    }, arguments)
  }
  imports.wbg.__wbg_hostname_3d9f22c60dc5bec6 = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg1).hostname
      const ptr1 = passStringToWasm0(
        ret,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
      )
      const len1 = WASM_VECTOR_LEN
      getInt32Memory0()[arg0 / 4 + 1] = len1
      getInt32Memory0()[arg0 / 4 + 0] = ptr1
    }, arguments)
  }
  imports.wbg.__wbg_port_b8d9a9c4e2b26efa = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg1).port
      const ptr1 = passStringToWasm0(
        ret,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
      )
      const len1 = WASM_VECTOR_LEN
      getInt32Memory0()[arg0 / 4 + 1] = len1
      getInt32Memory0()[arg0 / 4 + 0] = ptr1
    }, arguments)
  }
  imports.wbg.__wbg_origin_ee93e29ace71f568 = function () {
    return handleError(function (arg0, arg1) {
      const ret = getObject(arg1).origin
      const ptr1 = passStringToWasm0(
        ret,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
      )
      const len1 = WASM_VECTOR_LEN
      getInt32Memory0()[arg0 / 4 + 1] = len1
      getInt32Memory0()[arg0 / 4 + 0] = ptr1
    }, arguments)
  }
  imports.wbg.__wbg_settabIndex_27de1972b86c0f4c = function (arg0, arg1) {
    getObject(arg0).tabIndex = arg1
  }
  imports.wbg.__wbg_new_61d4f20a1c08a45c = function () {
    return handleError(function (arg0) {
      const ret = new ResizeObserver(getObject(arg0))
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_set_1f9b04f170055d33 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = Reflect.set(getObject(arg0), getObject(arg1), getObject(arg2))
      return ret
    }, arguments)
  }
  imports.wbg.__wbg_observe_dc0ebcd59ee7cd17 = function (arg0, arg1, arg2) {
    getObject(arg0).observe(getObject(arg1), getObject(arg2))
  }
  imports.wbg.__wbg_new_ab6fd82b10560829 = function () {
    return handleError(function () {
      const ret = new Headers()
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_signal_a61f78a3478fd9bc = function (arg0) {
    const ret = getObject(arg0).signal
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_append_7bfcb4937d1d5e29 = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4) {
      getObject(arg0).append(
        getStringFromWasm0(arg1, arg2),
        getStringFromWasm0(arg3, arg4),
      )
    }, arguments)
  }
  imports.wbg.__wbg_instanceof_Response_849eb93e75734b6e = function (arg0) {
    let result
    try {
      result = getObject(arg0) instanceof Response
    } catch (_) {
      result = false
    }
    const ret = result
    return ret
  }
  imports.wbg.__wbg_status_61a01141acd3cf74 = function (arg0) {
    const ret = getObject(arg0).status
    return ret
  }
  imports.wbg.__wbg_url_5f6dc4009ac5f99d = function (arg0, arg1) {
    const ret = getObject(arg1).url
    const ptr1 = passStringToWasm0(
      ret,
      wasm.__wbindgen_malloc,
      wasm.__wbindgen_realloc,
    )
    const len1 = WASM_VECTOR_LEN
    getInt32Memory0()[arg0 / 4 + 1] = len1
    getInt32Memory0()[arg0 / 4 + 0] = ptr1
  }
  imports.wbg.__wbg_headers_9620bfada380764a = function (arg0) {
    const ret = getObject(arg0).headers
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_next_196c84450b364254 = function () {
    return handleError(function (arg0) {
      const ret = getObject(arg0).next()
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_done_298b57d23c0fc80c = function (arg0) {
    const ret = getObject(arg0).done
    return ret
  }
  imports.wbg.__wbg_value_d93c65011f51a456 = function (arg0) {
    const ret = getObject(arg0).value
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_abort_2aa7521d5690750e = function (arg0) {
    getObject(arg0).abort()
  }
  imports.wbg.__wbg_stringify_8887fe74e1c50d81 = function () {
    return handleError(function (arg0) {
      const ret = JSON.stringify(getObject(arg0))
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_arrayBuffer_29931d52c7206b02 = function () {
    return handleError(function (arg0) {
      const ret = getObject(arg0).arrayBuffer()
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_newwithstrandinit_3fd6fba4083ff2d0 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = new Request(getStringFromWasm0(arg0, arg1), getObject(arg2))
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_text_450a059667fd91fd = function () {
    return handleError(function (arg0) {
      const ret = getObject(arg0).text()
      return addHeapObject(ret)
    }, arguments)
  }
  imports.wbg.__wbg_setTimeout_c172d5704ef82276 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = getObject(arg0).setTimeout(getObject(arg1), arg2)
      return ret
    }, arguments)
  }
  imports.wbg.__wbindgen_throw = function (arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1))
  }
  imports.wbg.__wbg_then_0c86a60e8fcfe9f6 = function (arg0, arg1) {
    const ret = getObject(arg0).then(getObject(arg1))
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_queueMicrotask_481971b0d87f3dd4 = function (arg0) {
    queueMicrotask(getObject(arg0))
  }
  imports.wbg.__wbg_queueMicrotask_3cbae2ec6b6cd3d6 = function (arg0) {
    const ret = getObject(arg0).queueMicrotask
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_resolve_b0083a7967828ec8 = function (arg0) {
    const ret = Promise.resolve(getObject(arg0))
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_then_a73caa9a87991566 = function (arg0, arg1, arg2) {
    const ret = getObject(arg0).then(getObject(arg1), getObject(arg2))
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_close_a994f9425dab445c = function () {
    return handleError(function (arg0) {
      getObject(arg0).close()
    }, arguments)
  }
  imports.wbg.__wbg_enqueue_ea194723156c0cc2 = function () {
    return handleError(function (arg0, arg1) {
      getObject(arg0).enqueue(getObject(arg1))
    }, arguments)
  }
  imports.wbg.__wbg_byobRequest_72fca99f9c32c193 = function (arg0) {
    const ret = getObject(arg0).byobRequest
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_view_7f0ce470793a340f = function (arg0) {
    const ret = getObject(arg0).view
    return isLikeNone(ret) ? 0 : addHeapObject(ret)
  }
  imports.wbg.__wbg_byteLength_58f7b4fab1919d44 = function (arg0) {
    const ret = getObject(arg0).byteLength
    return ret
  }
  imports.wbg.__wbg_close_184931724d961ccc = function () {
    return handleError(function (arg0) {
      getObject(arg0).close()
    }, arguments)
  }
  imports.wbg.__wbg_new_28c511d9baebfa89 = function (arg0, arg1) {
    const ret = new Error(getStringFromWasm0(arg0, arg1))
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_byteOffset_81d60f7392524f62 = function (arg0) {
    const ret = getObject(arg0).byteOffset
    return ret
  }
  imports.wbg.__wbg_respond_b1a43b2e3a06d525 = function () {
    return handleError(function (arg0, arg1) {
      getObject(arg0).respond(arg1 >>> 0)
    }, arguments)
  }
  imports.wbg.__wbg_performance_eeefc685c9bc38b4 = function (arg0) {
    const ret = getObject(arg0).performance
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_now_e0d8ec93dd25766a = function (arg0) {
    const ret = getObject(arg0).now()
    return ret
  }
  imports.wbg.__wbg_performance_a1b8bde2ee512264 = function (arg0) {
    const ret = getObject(arg0).performance
    return addHeapObject(ret)
  }
  imports.wbg.__wbg_now_abd80e969af37148 = function (arg0) {
    const ret = getObject(arg0).now()
    return ret
  }
  imports.wbg.__wbindgen_closure_wrapper1050 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 99, __wbg_adapter_30)
    return addHeapObject(ret)
  }
  imports.wbg.__wbindgen_closure_wrapper1164 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 99, __wbg_adapter_33)
    return addHeapObject(ret)
  }
  imports.wbg.__wbindgen_closure_wrapper1224 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 99, __wbg_adapter_30)
    return addHeapObject(ret)
  }
  imports.wbg.__wbindgen_closure_wrapper3437 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 99, __wbg_adapter_38)
    return addHeapObject(ret)
  }
  imports.wbg.__wbindgen_closure_wrapper3695 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 99, __wbg_adapter_38)
    return addHeapObject(ret)
  }
  imports.wbg.__wbindgen_closure_wrapper5618 = function (arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 99, __wbg_adapter_30)
    return addHeapObject(ret)
  }

  return imports
}

function __wbg_init_memory(imports, maybe_memory) {}

function __wbg_finalize_init(instance, module) {
  wasm = instance.exports
  __wbg_init.__wbindgen_wasm_module = module
  cachedFloat64Memory0 = null
  cachedInt32Memory0 = null
  cachedUint8Memory0 = null

  wasm.__wbindgen_start()
  return wasm
}

function initSync(module) {
  if (wasm !== undefined) return wasm

  const imports = __wbg_get_imports()

  __wbg_init_memory(imports)

  if (!(module instanceof WebAssembly.Module)) {
    module = new WebAssembly.Module(module)
  }

  const instance = new WebAssembly.Instance(module, imports)

  return __wbg_finalize_init(instance, module)
}

async function __wbg_init(input) {
  if (wasm !== undefined) return wasm

  if (typeof input === "undefined") {
    input = new URL("surfer_bg.wasm", import.meta.url)
  }
  const imports = __wbg_get_imports()

  if (
    typeof input === "string" ||
    (typeof Request === "function" && input instanceof Request) ||
    (typeof URL === "function" && input instanceof URL)
  ) {
    input = fetch(input)
  }

  __wbg_init_memory(imports)

  const { instance, module } = await __wbg_load(await input, imports)

  return __wbg_finalize_init(instance, module)
}

export { initSync }
export default __wbg_init

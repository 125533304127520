import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { client } from "../../api/axios"

export type PipelineRunCodeFetchStatus =
  | "idle"
  | "loading"
  | "succeeded"
  | "failed"

type FileState = {
  lines: string[]
  status: PipelineRunCodeFetchStatus
}

export interface PipelineRunCodeState {
  [pipelineRunID: string]: {
    [filename: string]: FileState
  }
}

const initialState: PipelineRunCodeState = {}
export const fetchCode = createAsyncThunk(
  "pipelineRunCode/fetchCode",
  async ({
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
    filename,
  }: {
    organizationID: string
    projectID: string
    pipelineID: string
    pipelineRunID: string
    filename: string
  }) => {
    const response = await client(
      `organizations/${organizationID}/projects/${projectID}/pipelines/${pipelineID}/runs/${pipelineRunID}/files/${filename}`,
    )
    return response.data
  },
)
export const pipelineRunCodeSlice = createSlice({
  name: "pipelineRunCode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCode.pending, (state, action) => {
        const pipelineRunID = action.meta.arg.pipelineRunID
        const filename = action.meta.arg.filename
        if (!(pipelineRunID in state)) {
          state[pipelineRunID] = {}
        }
        state[pipelineRunID][filename] = {
          status: "loading",
          lines: [],
        }
      })
      .addCase(fetchCode.rejected, (state, action) => {
        const pipelineRunID = action.meta.arg.pipelineRunID
        const filename = action.meta.arg.filename
        if (!(pipelineRunID in state)) {
          state[pipelineRunID] = {}
        }
        state[pipelineRunID][filename] = {
          status: "failed",
          lines: [],
        }
      })
      .addCase(fetchCode.fulfilled, (state, action) => {
        const pipelineRunID = action.meta.arg.pipelineRunID
        const filename = action.meta.arg.filename
        if (!(pipelineRunID in state)) {
          state[pipelineRunID] = {}
        }
        state[pipelineRunID][filename] = {
          status: "succeeded",
          lines: action.payload.split("\n"),
        }
      })
  },
})

export default pipelineRunCodeSlice.reducer

import { useParams } from "react-router-dom"
import {
  useGetOrganizationQuery,
  useGetProjectQuery,
  useGetProjectVerilogFilesQuery,
} from "../api/apiSlice"
import { Project } from "../projects/projectSlice"
import { fetchFile, PlanGenFileState, PlanGenTreeItem } from "./planGenSlice"
import React, { useState } from "react"
import { PageWrapper } from "../../components/PageWrapper"
import { ibexTestPlans } from "./ibexTestPlans"
import {
  StaticTreeDataProvider,
  Tree,
  TreeItem,
  TreeItemIndex,
  UncontrolledTreeEnvironment,
} from "react-complex-tree"
import "react-complex-tree/lib/style.css"
import "./plan-markdown.css"
import { renderers } from "./treeRenderers"
import { LogsDisplay } from "../../components/LogsDisplay"
import { Collapsible } from "../../components/Collapsible"
import { PrimaryButton, TextArea } from "../../components/form"
import Markdown from "react-markdown"
import { useDispatch, useSelector } from "react-redux"
import { RootState, store } from "../../app/store"

const SourceCodeDisplay = ({
  filepath,
  fileState,
}: {
  filepath: string
  fileState: PlanGenFileState
}): React.JSX.Element => {
  let title
  switch (fileState.status) {
    case "failed":
      return <div>Could not fetch source code.</div>
    case "loading":
      title = <div className="title">{filepath}</div>
      return (
        <LogsDisplay
          logLines={["Loading contents..."]}
          loading={true}
          title={title}
          card_status={"neutral"}
        />
      )
    case "succeeded":
      title = <div className="title">{filepath}</div>
      return (
        <LogsDisplay
          logLines={fileState.contents.split("\n")}
          loading={false}
          title={title}
          card_status={"neutral"}
        />
      )
  }
}

export function PlanGenPage() {
  const { organizationID, projectID } = useParams()
  if (organizationID === undefined || projectID === undefined) {
    throw Error("organization and project ID required")
  }

  const {
    isLoading: orgIsLoading,
    isSuccess: orgIsSuccess,
    isError: orgIsError,
    error: orgError,
  } = useGetOrganizationQuery(organizationID)

  const {
    data: projectData,
    isLoading: projectIsLoading,
    isSuccess: projectIsSuccess,
    isError: projectIsError,
    error: projectError,
  } = useGetProjectQuery({ organizationID, projectID })

  const {
    data: verilogFilesData,
    isLoading: verilogFilesIsLoading,
    isSuccess: verilogFilesIsSuccess,
    isError: verilogFilesIsError,
    error: verilogFilesError,
  } = useGetProjectVerilogFilesQuery({ organizationID, projectID })

  const dispatch = useDispatch<typeof store.dispatch>()
  const sourceCodeState = useSelector((state: RootState) => state.planGen)

  const [filesWithTestPlan, setFilesWithTestPlan] = useState([
    "rtl/ibex_branch_predict.sv",
    "rtl/ibex_core.sv",
    "rtl/ibex_cs_registers.sv",
    "rtl/ibex_multdiv_fast.sv",
  ])

  const [selectedFile, setSelectedFile] = useState<string | null>(null)
  let basename = ""
  if (selectedFile !== null) {
    const idx = selectedFile.lastIndexOf("/")
    basename = selectedFile.substring(idx + 1)
  }
  const testPlan = ibexTestPlans[basename] ?? ""
  const [rightHandDisplay, setRightHandDisplay] = useState<
    "sourceCode" | "plan" | "editPlan" | null
  >(null)
  const [planLoading, setPlanLoading] = useState(false)

  let title = ""
  let content = <></>
  if (orgIsLoading || projectIsLoading || verilogFilesIsLoading) {
    title = "Loading..."
    content = <div>Loading...</div>
  } else if (orgIsError || projectIsError || verilogFilesIsError) {
    title = "An error occurred"
    const error = orgError ?? projectError ?? verilogFilesError
    content = <div>Error loading organization: {JSON.stringify(error)}</div>
  } else {
    if (!(orgIsSuccess && projectIsSuccess && verilogFilesIsSuccess)) {
      throw new Error("unhandled case")
    }
    const project: Project = projectData
    title = `${project.name}`

    const filesWithPlan: Record<TreeItemIndex, TreeItem<PlanGenTreeItem>> = {}
    const filesWithoutPlan: Record<
      TreeItemIndex,
      TreeItem<PlanGenTreeItem>
    > = {}
    const { git_hash: gitHash, files } = verilogFilesData
    files.forEach((filename) => {
      if (!filename.startsWith("rtl")) {
        return
      }
      const fileItem: TreeItem<PlanGenTreeItem> = {
        data: {
          canGeneratePlan: true,
          fetchPlan: (e) => {
            e.preventDefault()
            setTimeout(() => {
              setRightHandDisplay("plan")
            }, 1)
          },
          generatePlan: async (e) => {
            e.preventDefault()
          },
        },
        index: filename,
      }
      if (filesWithTestPlan.includes(filename)) {
        filesWithPlan[filename] = fileItem
      } else {
        filesWithoutPlan[filename] = fileItem
      }
    })
    filesWithPlan["/"] = {
      data: {
        canGeneratePlan: false,
      },
      index: "/",
      isFolder: true,
      children: files,
    }
    filesWithoutPlan["/"] = {
      data: {
        canGeneratePlan: false,
      },
      index: "/",
      isFolder: true,
      children: files,
    }

    const tree = (
      <div
        className={
          "m-2 rounded border border-solid border-gray-300 divide-y divide-solid divide-gray-300"
        }
      >
        {filesWithTestPlan.map((file) => (
          <div
            key={file}
            className={"p-2" + (file === selectedFile ? " font-bold" : "")}
          >
            <button
              onClick={(e) => {
                e.preventDefault()
                setSelectedFile(file)
                setRightHandDisplay("plan")
              }}
            >
              {file}
            </button>
          </div>
        ))}
      </div>
    )

    let rightHandContent = null
    if (rightHandDisplay === "sourceCode") {
      if (selectedFile === null) {
        rightHandContent = <div>Please select a test plan or new file</div>
      } else {
        rightHandContent = (
          <div>
            <PrimaryButton
              onClick={async () => {
                setPlanLoading(true)
                await new Promise((resolve) =>
                  setTimeout(resolve, 3000 + 2000 * Math.random()),
                )
                setPlanLoading(false)
                if (
                  typeof selectedFile === "string" &&
                  !filesWithTestPlan.includes(selectedFile)
                ) {
                  const nextFilesWithTestPlan = [
                    ...filesWithTestPlan,
                    selectedFile,
                  ]
                  nextFilesWithTestPlan.sort()
                  setFilesWithTestPlan(nextFilesWithTestPlan)
                }
                setRightHandDisplay("plan")
              }}
              disabled={planLoading}
            >
              {planLoading ? "Creating test plan..." : "Create test plan"}
            </PrimaryButton>
            <SourceCodeDisplay
              filepath={selectedFile}
              fileState={sourceCodeState[projectID]?.[selectedFile]}
            />
          </div>
        )
      }
    } else if (rightHandDisplay === "plan" || rightHandDisplay === "editPlan") {
      const testPlanDisplay =
        rightHandDisplay === "plan" ? (
          <div
            className={
              "m-4 px-8 pt-1 bg-backgroundLighter plan-markdown rounded"
            }
          >
            <Markdown>{testPlan}</Markdown>
          </div>
        ) : (
          <div className={"m-4 p-8 bg-backgroundLighter plan-markdown rounded"}>
            <TextArea onChange={() => {}} value={testPlan} rows={40} />
          </div>
        )
      rightHandContent = (
        <div className={"p-4 w-full"}>
          <div className="text-xl font-bold">
            Test plan for {selectedFile}
            <div className="inline ml-8">
              <PrimaryButton
                onClick={() => {
                  if (rightHandDisplay === "plan") {
                    setRightHandDisplay("editPlan")
                  } else if (rightHandDisplay === "editPlan") {
                    setRightHandDisplay("plan")
                  }
                }}
              >
                {rightHandDisplay === "plan" ? "Edit test plan" : "Save"}
              </PrimaryButton>
            </div>
          </div>
          {testPlanDisplay}
        </div>
      )
    }

    const newPlanTree = (
      <Collapsible
        header={<span>Choose more files...</span>}
        size={20}
        arrowDivClass={"p-1"}
        initOpen={false}
      >
        <div className={"text-sm"}>
          <UncontrolledTreeEnvironment
            viewState={{}}
            getItemTitle={(item) => item.index.toString()}
            dataProvider={new StaticTreeDataProvider(filesWithoutPlan)}
            {...renderers}
            onSelectItems={(items) => {
              setRightHandDisplay("sourceCode")
              const filepath = items.length > 0 ? items[0] : null
              if (filepath && typeof filepath === "string") {
                const fileState = sourceCodeState[projectID]?.[filepath]
                if (!fileState) {
                  dispatch(
                    fetchFile({
                      organizationID,
                      projectID,
                      filepath,
                      gitHash,
                    }),
                  )
                }
                setSelectedFile(filepath)
              }
            }}
          >
            <Tree rootItem={"/"} treeId={"tree2"} treeLabel={"tree example"} />
          </UncontrolledTreeEnvironment>
        </div>
      </Collapsible>
    )

    content = (
      <div className={"w-full"}>
        <div className={"text-lg text-textDefault font-mono"}>
          Test plans for {project.git_url}
        </div>
        <div className={"flex flex-row"}>
          <div className={"flex-initial w-1/3 p-4"}>
            {tree}
            {newPlanTree}
          </div>
          <div className={"flex-initial w-2/3"}>{rightHandContent}</div>
        </div>
      </div>
    )
  }

  return <PageWrapper title={title} content={content} />
}

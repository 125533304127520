import { PageWrapper } from "../../components/PageWrapper"
import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useCreateProjectsFromInstallationIDMutation } from "../api/apiSlice"

export function PostInstall() {
  const [searchParams] = useSearchParams()
  const installationID = searchParams.get("installation_id")
  useNavigate()
  const [createProjects, createProjectsInfo] =
    useCreateProjectsFromInstallationIDMutation()
  // TODO query all organizations, then query for projects in the organization.
  //   call createProjects only if there's one org and no projects.

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      if (installationID) {
        createProjects({
          installation_id: installationID,
        }).unwrap()
      }
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [installationID])

  let message
  switch (true) {
    case createProjectsInfo.isLoading:
      message = "Creating project..."
      break

    case createProjectsInfo.isError:
      message = `There was an error while creating your project: ${createProjectsInfo.error}`
      break

    case createProjectsInfo.isSuccess:
      message = `Successfully created project. You can now start running tests. Redirecting...`
      window.setTimeout(() => {
        // set location rather than using `navigate` to force re-fetch of data
        // from API
        // @ts-expect-error I wasn't here for this. -kay
        window.location = "/post-login"
      }, 3000)
  }

  const content = (
    <div className={"space-y-4 text-lg"}>
      <p>You have installed the Silogy app on your GitHub account.</p>
      <p>{message}</p>
    </div>
  )
  return <PageWrapper title="Welcome to Silogy!" content={content} />
}

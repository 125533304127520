import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
  useGetBasedefPresetsQuery,
  useGetBasedefQuery,
  useGetOrganizationQuery,
  useGetProjectQuery,
  useUpdateBasedefMutation,
} from "../api/apiSlice"
import {
  Basedef,
  Variable,
  VariableConfig,
  VariableConfigs,
  Variables,
} from "./basedefSlice"
import { PageWrapper } from "../../components/PageWrapper"
import {
  Input,
  Label,
  PrimaryButton,
  SecondaryButton,
  Select,
  TextArea,
} from "../../components/form"
import { lockedIcon, unlockedIcon } from "../../components/icon"

const variablesMatch = (a: Variables, b: Variables): boolean =>
  Object.entries(a).every(
    ([aKey, aValue]) => aValue.value === b[aKey]?.value,
  ) && Object.keys(a).length === Object.keys(b).length

const VariableDisplay = ({
  variable,
  variableConfig,
  onChange,
  onSetLocked,
}: {
  variable: Variable
  variableConfig: VariableConfig
  onChange: (name: string, value: string | null) => void
  onSetLocked: (name: string, value: boolean) => void
}): React.JSX.Element => {
  const canBeLocked = variable.secret && variable.is_set
  const name = variableConfig.name
  return (
    <>
      <div className={"col-span-2"}>
        <p>
          <b>{name}</b>
        </p>
        <p className={"text-gray-700 text-sm"}>{variableConfig.help}</p>
        {canBeLocked && (
          <p
            onClick={() => {
              onSetLocked(name, !variable.locked)
            }}
          >
            {variable.locked ? lockedIcon : unlockedIcon}
          </p>
        )}
      </div>
      <div className={"col-span-4 md:col-span-10"}>
        <TextArea
          value={
            variable.value === null || variable.locked
              ? ""
              : atob(variable.value)
          }
          onChange={(e) => {
            onChange(name, btoa(e.target.value))
          }}
          disabled={variable.locked}
          placeholder={
            variable.secret && variable.is_set
              ? variable.locked
                ? "Previously saved value cannot be displayed. To set a new value, first click the lock icon to unlock."
                : "Set a new value here. To keep the old value, click the lock icon to lock."
              : ""
          }
        />
      </div>
    </>
  )
}

const VariablesDisplay = ({
  variables,
  variableConfigs,
  onChange,
  onSetLocked,
}: {
  variables: Variables
  variableConfigs: VariableConfigs
  onChange: (name: string, value: string | null) => void
  onSetLocked: (name: string, value: boolean) => void
}): React.JSX.Element => {
  return (
    <div>
      <h2>Variables</h2>
      <div className={"my-4 grid grid-cols-6 md:grid-cols-12 space-x-4"}>
        {Object.entries(variableConfigs).map(([name, variableConfig]) => {
          const variable = variables[name] ?? {
            secret: variableConfig.secret,
            value: null,
            is_set: variableConfig.secret ? false : undefined,
          }
          return (
            <VariableDisplay
              key={name}
              variable={variable}
              variableConfig={variableConfig}
              onChange={onChange}
              onSetLocked={onSetLocked}
            />
          )
        })}
      </div>
    </div>
  )
}

export function BasedefPage(): React.JSX.Element {
  const [name, setName] = useState<string>("")
  const [presetValue, setPresetValue] = useState<string>("")
  const [preClone, setPreClone] = useState<string>("")
  const [timeLimit, setTimeLimit] = useState<number>(0)
  const [variables, setVariables] = useState<Variables>({})

  const [originalName, setOriginalName] = useState<string>("")
  const [originalPresetValue, setOriginalPresetValue] = useState<string>("")
  const [originalPreClone, setOriginalPreClone] = useState<string>("")
  const [originalTimeLimit, setOriginalTimeLimit] = useState<number>(0)
  const [originalVariables, setOriginalVariables] = useState<Variables>({})

  const { organizationID, projectID, basedefID } = useParams()
  if (
    organizationID === undefined ||
    projectID === undefined ||
    basedefID === undefined
  ) {
    throw Error("organization ID required")
  }
  const orgInfo = useGetOrganizationQuery(organizationID)
  const projectInfo = useGetProjectQuery({ organizationID, projectID })
  const basedefInfo = useGetBasedefQuery({
    organizationID,
    projectID,
    basedefID,
  })
  const basedefPresetsInfo = useGetBasedefPresetsQuery({
    organizationID,
    projectID,
    basedefID,
  })
  const [updateBasedef, updateBasedefInfo] = useUpdateBasedefMutation()

  const allSuccessful =
    orgInfo.isSuccess && projectInfo.isSuccess && basedefInfo.isSuccess
  useEffect(() => {
    if (orgInfo.isSuccess && projectInfo.isSuccess && basedefInfo.isSuccess) {
      const basedef: Basedef = basedefInfo.data
      setName(basedef.name)
      setOriginalName(basedef.name)
      setPresetValue(basedef.preset.label)
      setOriginalPresetValue(basedef.preset.label)
      setPreClone(basedef.pre_clone)
      setOriginalPreClone(basedef.pre_clone)
      setTimeLimit(basedef.time_limit)
      setOriginalTimeLimit(basedef.time_limit)
      const variablesWithLocking: Variables = {}
      const originalVariablesWithLocking: Variables = {}
      Object.entries(basedef.variables).forEach(([name, variable]) => {
        const locked = variable.secret ? variable.is_set : false
        variablesWithLocking[name] = {
          ...variable,
          locked,
        }
        originalVariablesWithLocking[name] = { ...variable, locked }
      })
      setVariables(variablesWithLocking)
      setOriginalVariables(variablesWithLocking)
    }
  }, [
    allSuccessful,
    basedefInfo.data,
    basedefInfo.isSuccess,
    orgInfo.isSuccess,
    projectInfo.isSuccess,
  ])

  let content
  let title = "Loading..."
  if (
    orgInfo.isLoading ||
    projectInfo.isLoading ||
    basedefInfo.isLoading ||
    basedefPresetsInfo.isLoading
  ) {
    content = <div>Loading...</div>
  } else if (orgInfo.isError) {
    content = (
      <div>Error loading organization: {JSON.stringify(orgInfo.error)}</div>
    )
  } else if (projectInfo.isError) {
    content = (
      <div>Error loading project: {JSON.stringify(projectInfo.error)}</div>
    )
  } else if (basedefInfo.isError) {
    content = (
      <div>
        Error loading base definitions: {JSON.stringify(basedefInfo.error)}
      </div>
    )
  } else if (basedefPresetsInfo.isError) {
    content = (
      <div>
        Error loading base definition presets:{" "}
        {JSON.stringify(basedefInfo.error)}
      </div>
    )
  } else {
    const dirty = !(
      name === originalName &&
      presetValue === originalPresetValue &&
      preClone === originalPreClone &&
      timeLimit === originalTimeLimit &&
      variablesMatch(variables, originalVariables)
    )
    const allPresets = basedefPresetsInfo.data
    if (allPresets === undefined) {
      throw Error("basedef presets are undefined")
    }
    const presets = basedefPresetsInfo.data ?? {}
    const gitURL =
      projectInfo.data?.git_url.replace(
        "github.com",
        "oauth2:$GIT_TOKEN@github.com",
      ) ?? ""
    title = `Base definition in project ${projectInfo.data?.name}`
    const currentPreset = presets[presetValue]
    const variableConfigs = currentPreset?.variables ?? {}
    const presetOptions = Object.values(presets).map(({ name, label }) => ({
      text: name,
      value: label,
    }))
    content = (
      <div>
        <form>
          <div className={"my-3"}>
            <Label htmlFor={"name"}>Name</Label>
            <Input
              type={"text"}
              value={name}
              onChange={(e) => setName(e.target.value)}
              id={"name"}
            />
          </div>
          <div className={"my-3"}>
            <Label htmlFor={"preset"}>Preset</Label>
            <Select
              onChange={(e) => {
                setPresetValue(e.target.value)
              }}
              value={presetValue}
              options={presetOptions}
              id={"preset"}
            />
          </div>
          {Object.keys(variableConfigs).length > 0 && (
            <VariablesDisplay
              variables={variables}
              variableConfigs={currentPreset.variables}
              onChange={(name: string, value: string | null) => {
                const currentVariable = variables[name] ?? {
                  secret: currentPreset.variables[name].secret,
                  value: null,
                  locked: false,
                  is_set: false,
                }
                setVariables({
                  ...variables,
                  [name]: {
                    ...currentVariable,
                    value,
                  },
                })
              }}
              onSetLocked={(name, value) => {
                const variable = variables[name]
                if (!variable.secret) {
                  return
                }
                setVariables({
                  ...variables,
                  [name]: {
                    ...variable,
                    locked: value,
                  },
                })
              }}
            />
          )}
          <div
            className={
              "border border-gray-600 p-6 font-mono whitespace-pre-wrap"
            }
          >
            <div className={"my-3 text-gray-700"}>
              <code>
                # Base definition: <b>{name}</b>
                <br />
                FROM <b>{presets[presetValue]?.base_image} AS base</b>
                <br />
                {atob(presets[presetValue]?.commands ?? "")}
              </code>
            </div>
            <Label htmlFor={"pre_clone"} className={"hidden"}>
              Pre-clone steps
            </Label>
            <div className={"my-3"}>
              <code>
                <TextArea
                  value={atob(preClone)}
                  onChange={(e) => {
                    setPreClone(btoa(e.target.value))
                  }}
                  id={"pre_clone"}
                  cols={88}
                  rows={10}
                  placeholder={
                    "Docker instructions to run before cloning and checking out " +
                    "the Git version under test. These will be cached across " +
                    "runs, so you can do things like installing dependencies here."
                  }
                />
              </code>
            </div>
            <div className={"my-3 text-gray-700"}>
              <code>
                # /run/secrets/git_token is supplied at runtime.
                <br />
                RUN
                --mount=type=secret,id=git_token,target=/run/secrets/git_token \
                <br />
                {"  "}GIT_TOKEN=$(cat /run/secrets/git_token) && \
                <br />
                {"  "}_CACHE_KEY=<b>$GIT_HASH</b> git clone {gitURL} /tmp/code
                <br />
                <br />
                WORKDIR /tmp/code
                <br /># Your Git hash goes here:
                <br />
                RUN git checkout <b>$GIT_HASH</b>
                <br />
                RUN pip install
                /tmp/packages/otl-0.1.0-cp312-cp312-manylinux_2_17_x86_64.manylinux2014_x86_64.whl
                <br />
                COPY ./job_runner.py /tmp
              </code>
            </div>
          </div>
          <div className={"py-4 flex flex-row space-x-4"}>
            <PrimaryButton
              onClick={async (e) => {
                e.preventDefault()
                const variablesToSend: Variables = {}
                Object.entries(variables).forEach(([name, variable]) => {
                  if (!variable.locked) {
                    // locked variables cannot be saved
                    variablesToSend[name] = variable
                  }
                })
                await updateBasedef({
                  organizationID,
                  projectID,
                  basedefID,
                  name: name === originalName ? undefined : name,
                  preset:
                    presetValue === originalPresetValue
                      ? undefined
                      : presetValue,
                  pre_clone:
                    preClone === originalPreClone ? undefined : preClone,
                  time_limit:
                    timeLimit === originalTimeLimit ? undefined : timeLimit,
                  variables: variablesToSend,
                }).unwrap()
              }}
              disabled={!dirty}
            >
              Save
            </PrimaryButton>
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault()
                setName(originalName)
                setPresetValue(originalPresetValue)
                setPreClone(originalPreClone)
                setTimeLimit(originalTimeLimit)
                setVariables(originalVariables)
              }}
              disabled={!dirty}
            >
              Reset
            </SecondaryButton>
          </div>
        </form>
        <div className={"mt-4"}>
          {updateBasedefInfo.isSuccess && "Changes saved successfully."}
          {updateBasedefInfo.isError &&
            `Changes were not saved: ${updateBasedefInfo.error}`}
        </div>
      </div>
    )
  }

  return <PageWrapper title={title} content={content} />
}

import { useEffect } from "react"
import { PageWrapper } from "../../components/PageWrapper.js"
import { getConfigValue } from "../../app/config"

import {
  InitOutput,
  inject_message,
  IntoUnderlyingByteSource,
  IntoUnderlyingSink,
  IntoUnderlyingSource,
  SyncInitInput,
} from "./surfer.js"

import { useParams } from "react-router-dom"
import {
  useGetJobRunQuery,
  useGetOrganizationQuery,
  useGetPipelineQuery,
  useGetPipelineRunQuery,
  useGetProjectQuery,
  useGetInitWaveQuery,
} from "../api/apiSlice.js"

interface WasmBindings {
  inject_message(message: string): void
  initSync(module: SyncInitInput): InitOutput
  IntoUnderlyingByteSource: typeof IntoUnderlyingByteSource
  IntoUnderlyingSink: typeof IntoUnderlyingSink
  IntoUnderlyingSource: typeof IntoUnderlyingSource

  // add more methods as needed
}

declare global {
  interface Window {
    wasmBindings: WasmBindings
  }
}

const loadSurferWasm = async () => {
  const { default: init, ...bindings } = await import("./surfer.js")
  const wasm = await init("/surfer.wasm")

  window.wasmBindings = bindings
  dispatchEvent(
    new CustomEvent("TrunkApplicationStarted", { detail: { wasm } }),
  )
}

async function loadWave(
  organizationID: string,
  projectID: string,
  pipelineID: string,
  pipelineRunID: string,
  jobRunID: string,
  waveName: string,
  waveuid: string,
): Promise<void> {
  try {
    const base_url = getConfigValue("API_BASE_URL")

    var url = `${base_url}/organizations/${organizationID}/projects/${projectID}/pipelines/${pipelineID}/runs/${pipelineRunID}/jobs/${jobRunID}/wave/${waveuid}`

    //FIXME? I don't have an elegant way to make this work, surfer wants an entire url
    try {
      new URL(url)
    } catch (_) {
      url = `${window.location.origin}/${url}`
    }

    const obj = {
      LoadWaveformFileFromUrl: [
        url,
        {
          keep_variables: false,
          keep_unavailable: false,
        },
      ],
    }

    const js = JSON.stringify(obj)

    inject_message(js)
  } catch (error) {
    console.error("Error occurred:", error)
  }
}

export function SurferPage() {
  const {
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
    jobRunID,
    waveName,
  } = useParams()
  if (
    organizationID === undefined ||
    projectID === undefined ||
    pipelineID === undefined ||
    pipelineRunID === undefined ||
    jobRunID === undefined ||
    waveName === undefined
  ) {
    throw Error("some stuff is missing")
  }

  const organizationInfo = useGetOrganizationQuery(organizationID)
  const projectInfo = useGetProjectQuery({ organizationID, projectID })
  const pipelineInfo = useGetPipelineQuery({
    organizationID,
    projectID,
    pipelineID,
  })
  const pipelineRunInfo = useGetPipelineRunQuery({
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
  })
  const jobRunInfo = useGetJobRunQuery({
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
    jobRunID,
  })

  const initWave = useGetInitWaveQuery({
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
    jobRunID,
  })
  //console.log(initWave)
  //console.log(initWave.isSuccess)

  useEffect(() => {
    if (
      organizationInfo.isSuccess &&
      projectInfo.isSuccess &&
      pipelineInfo.isSuccess &&
      pipelineRunInfo.isSuccess &&
      jobRunInfo.isSuccess &&
      initWave.isSuccess
    ) {
      loadSurferWasm().then(() => {
        loadWave(
          organizationID,
          projectID,
          pipelineID,
          pipelineRunID,
          jobRunInfo.data.id,
          waveName,
          initWave.data.wave_uid,
        )
      })
    }
  }, [
    organizationInfo,
    projectInfo,
    pipelineInfo,
    pipelineRunInfo,
    jobRunInfo,
    initWave,
  ])

  let title: string = ""

  // surfer's egui frame works by connecting to a named canvas and manipulating state via wasm
  // embarassingly, the name of the canvas is hard coded right now to be "the_canvas_id"
  const content = (
    <div className="w-full h-screen">
      <canvas id="the_canvas_id" className="w-full h-full"></canvas>
    </div>
  )

  if (
    organizationInfo.isError ||
    projectInfo.isError ||
    pipelineInfo.isError ||
    pipelineRunInfo.isError ||
    jobRunInfo.isError
  ) {
    title = "An error occurred"
  } else if (
    organizationInfo.isLoading ||
    projectInfo.isLoading ||
    pipelineInfo.isLoading ||
    pipelineRunInfo.isLoading ||
    jobRunInfo.isLoading
  ) {
    title = "Loading..."
  } else {
    if (
      !(
        organizationInfo.isSuccess &&
        projectInfo.isSuccess &&
        pipelineInfo.isSuccess &&
        pipelineRunInfo.isSuccess &&
        jobRunInfo.isSuccess
      )
    ) {
      throw new Error("unhandled case")
    }

    title = `${jobRunInfo.data.job_name} waveforms`
  }

  return <PageWrapper title={title} content={content} />
}

import { useCreateOrganizationMutation } from "../api/apiSlice"
import { useNavigate } from "react-router-dom"
import { Input, Label, PrimaryButton } from "../../components/form"
import { PageWrapper } from "../../components/PageWrapper"
import { useState } from "react"

export function CreateOrganizationPage() {
  const [createOrganization, createOrganizationInfo] =
    useCreateOrganizationMutation()
  const navigate = useNavigate()

  const [name, setName] = useState<string>("")

  const content = (
    <div>
      <form>
        <Label htmlFor={"name"}>Name</Label>
        <Input
          type={"text"}
          key={"name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          id={"name"}
        />
        <PrimaryButton
          onClick={async (e) => {
            e.preventDefault()
            await createOrganization({ name }).unwrap()
            navigate("/organizations")
          }}
          disabled={createOrganizationInfo.isLoading}
        >
          {createOrganizationInfo.isLoading
            ? "Creating Organization"
            : "Create organization"}
        </PrimaryButton>
        {createOrganizationInfo.isError &&
          "Error: " + JSON.stringify(createOrganizationInfo.error)}
      </form>
    </div>
  )
  return <PageWrapper title={"Home"} content={content} />
}

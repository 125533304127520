import React, { PropsWithChildren } from "react"

export const Label = ({
  htmlFor,
  children,
  className = "",
}: PropsWithChildren<{
  htmlFor?: string
  className?: string
}>): React.JSX.Element => (
  <label
    className={className || "block text-gray-700 text-sm font-bold mb-2"}
    htmlFor={htmlFor}
  >
    {children}
  </label>
)

type onChange = (
  e: React.ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >,
) => void | Promise<void>

export const Input = ({
  type,
  onChange,
  value,
  id,
  pattern,
}: {
  type: string
  onChange: onChange
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any
  id?: string
  pattern?: string
}): React.JSX.Element => (
  <input
    type={type}
    value={value}
    onChange={onChange}
    className={
      "shadow appearance-none border rounded w-full p-3 my-3 text-gray-700 leading-tight focus:shadow-outline "
    }
    id={id}
    pattern={pattern}
  />
)

export const TextArea = ({
  onChange,
  value,
  id,
  cols,
  rows,
  placeholder,
  disabled,
}: {
  onChange: onChange
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any
  id?: string
  cols?: number
  rows?: number
  placeholder?: string
  disabled?: boolean
}): React.JSX.Element => (
  <textarea
    value={value}
    onChange={onChange}
    className={
      "shadow appearance-none border rounded w-full p-3 text-black leading-tight focus:shadow-outline font-mono"
    }
    id={id}
    cols={cols}
    rows={rows}
    placeholder={placeholder}
    disabled={disabled}
  />
)

export const Select = ({
  onChange,
  value,
  options,
  id,
}: {
  onChange: onChange
  value: string
  options: { value: string; text: string }[]
  id: string
}): React.JSX.Element => {
  return (
    <select
      value={value}
      onChange={onChange}
      id={id}
      className={
        "shadow border rounded w-full p-3 my-3 text-gray-700 leading-tight"
      }
    >
      {options.map(({ value, text }) => (
        <option key={value} value={value}>
          {text}
        </option>
      ))}
    </select>
  )
}

type ButtonType = "submit" | "reset" | "button"

type onClick = (e: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>
export const PrimaryButton = ({
  onClick,
  disabled,
  children,
  type = "button",
}: PropsWithChildren<{
  onClick?: onClick
  disabled?: boolean
  type?: ButtonType
}>): React.JSX.Element => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={`
      bg-white disabled:bg-gray-300
      hover:bg-gray-200
      focus:ring
      border-2 border-black disabled:border-0
       text-black disabled:text-gray-700 py-2 px-4 rounded-lg`}
    >
      {children}
    </button>
  )
}

export const SecondaryButton = ({
  onClick,
  disabled,
  children,
  type = "button",
}: PropsWithChildren<{
  onClick: onClick
  disabled?: boolean
  type?: ButtonType
}>): React.JSX.Element => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={`
      bg-gray-300 disabled:bg-gray-300
      hover:bg-gray-300
      focus:ring
      text-black disabled:text-gray-700 py-2 px-4 rounded-lg`}
    >
      {children}
    </button>
  )
}

import React from "react"
import { CombinedLogo } from "./CombinedLogo"
import {
  Activity,
  BoxArrowRight,
  Files,
  Icon,
  Stack,
} from "react-bootstrap-icons"
import { useLocation } from "react-router-dom"

interface SidebarItemProps {
  MenuIcon: Icon
  text: string
  link?: string
  onClick?: () => void
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  MenuIcon,
  text,
  link,
  onClick,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      e.preventDefault()
      onClick()
    }
  }

  const isActive = link ? useLocation().pathname.startsWith(link) : false
  const activeColor = isActive ? "text-textLight" : "text-textLightGray"

  const Content = (
    <div className="flex items-center space-x-2">
      <MenuIcon className={`w-4 h-4 ${activeColor}`} />
      <span className={`pl-1 font-bold ${activeColor} text-xl`}>{text}</span>
    </div>
  )

  return (
    <div className="pl-2 py-1 flex items-center space-x-2">
      {link ? (
        <a href={link} onClick={handleClick}>
          {Content}
        </a>
      ) : (
        <button onClick={onClick}>{Content}</button>
      )}
      <div className={"pb-8"}>{/* Spacer */}</div>
    </div>
  )
}

export function Sidebar({ onLogout }: { onLogout: () => void }): JSX.Element {
  return (
    <nav
      className={
        "fixed left-0 top-0 h-screen w-80 flex flex-col p-6 " +
        "bg-backgroundDark text-textLight overflow-y-auto"
      }
    >
      <a href="/" className="mb-4">
        <CombinedLogo />
      </a>
      <SidebarItem MenuIcon={Stack} text="Projects" link="/organizations" />
      <SidebarItem MenuIcon={Activity} text="Analytics" link="/analytics" />
      <SidebarItem MenuIcon={Files} text="Docs" link="/welcome" />
      <div className={"w-0 flex-1"}>{/* Spacer */}</div>
      <SidebarItem MenuIcon={BoxArrowRight} text="Log out" onClick={onLogout} />
    </nav>
  )
}

import { useGetOrganizationQuery, useGetProjectsQuery } from "../api/apiSlice"
import { useParams } from "react-router-dom"
import { Project } from "../projects/projectSlice"
import { PageWrapper } from "../../components/PageWrapper"
import { PrimaryButton } from "../../components/form"
import { useDispatch, useSelector } from "react-redux"
import { RootState, store } from "../../app/store"
import { useEffect, useState } from "react"
import {
  fetchPipelinesForProject,
  PipelineCollectionState,
  PipelinesState,
} from "../pipelines/pipelineSlice"
import { DisplayPipelinesWindow } from "../../components/DisplayPipelinesWindow"
import {
  fetchPipelineRunsForPipeline,
  PipelineRunState,
} from "../pipelineRuns/pipelineRunSlice"
import { getConfigValue } from "../../app/config"

const ProjectLink = ({
  organizationID,
  projectID,
  projectName,
  projectGitURL,
  projectGitDefaultBranch,
  pipelinesData,
  pipelineRunData,
}: {
  organizationID: string
  projectID: string
  projectName: string
  projectGitURL: string
  projectGitDefaultBranch: string | null
  pipelinesData: PipelineCollectionState | undefined
  pipelineRunData: PipelineRunState | undefined
}): React.JSX.Element => {
  const pipelinesContent =
    pipelinesData?.status === "succeeded" ? (
      <DisplayPipelinesWindow
        organizationID={organizationID}
        projectGitURL={projectGitURL}
        projectGitDefaultBranch={projectGitDefaultBranch}
        projectID={projectID}
        pipelines={pipelinesData.pipelines}
        pipelineRunData={pipelineRunData}
      />
    ) : null
  return (
    <div className={"mb-12"}>
      <div className={"mb-4 text-3xl"}>
        <a
          href={`/organizations/${organizationID}/projects/${projectID}`}
          className={"header"}
        >
          {projectName}
        </a>
      </div>
      {pipelinesContent}
    </div>
  )
}

async function fetchPipelinesAndRuns(
  organizationID: string,
  project: Project,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any,
): Promise<void> {
  const pipelines = await dispatch(
    fetchPipelinesForProject({ organizationID, projectID: project.id }),
  )
  pipelines.payload.forEach(({ id }: { id: string }) => {
    dispatch(
      fetchPipelineRunsForPipeline({
        organizationID,
        projectID: project.id,
        pipelineID: id,
      }),
    )
  })
}

export function OrganizationInfo({
  organizationID,
}: {
  organizationID: string
}) {
  const {
    isLoading: orgIsLoading,
    isSuccess: orgIsSuccess,
    isError: orgIsError,
    error: orgError,
  } = useGetOrganizationQuery(organizationID)

  const {
    data: projectsData,
    isLoading: projectsIsLoading,
    isSuccess: projectsIsSuccess,
    isError: projectsIsError,
    error: projectsError,
  } = useGetProjectsQuery(organizationID)

  const dispatch = useDispatch<typeof store.dispatch>()
  const prelimPipelinesData = useSelector((state: RootState) => state.pipeline)
  const [pipelinesData, setPipelinesData] = useState<PipelinesState>({})
  const prelimPipelineRunData = useSelector(
    (state: RootState) => state.pipelineRun,
  )
  const [pipelineRunData, setPipelineRunData] = useState<PipelineRunState>({})
  useEffect(() => {
    if (
      Object.values(prelimPipelinesData).every(
        ({ status }) => status === "succeeded",
      )
    ) {
      setPipelinesData(prelimPipelinesData)
    }
  }, [prelimPipelinesData])
  useEffect(() => {
    if (
      Object.values(prelimPipelineRunData).every(
        ({ status }) => status === "succeeded",
      )
    ) {
      setPipelineRunData(prelimPipelineRunData)
    }
  }, [prelimPipelineRunData])
  useEffect(() => {
    if (orgIsSuccess && projectsIsSuccess) {
      const projects: Project[] = projectsData.results
      projects.forEach(async (project) => {
        await fetchPipelinesAndRuns(organizationID, project, dispatch)
      })
      const interval = setInterval(() => {
        const projects: Project[] = projectsData.results
        projects.forEach(async (project) => {
          await fetchPipelinesAndRuns(organizationID, project, dispatch)
        })
      }, 5000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [orgIsSuccess, projectsIsSuccess, organizationID, projectsData, dispatch])

  let content = <></>
  if (orgIsLoading || projectsIsLoading) {
    content = <div>Loading...</div>
  } else if (orgIsError) {
    content = (
      <div>
        There was an error fetching the data: {JSON.stringify(orgError)}
      </div>
    )
  } else if (projectsIsError) {
    content = (
      <div>
        There was an error fetching the data: {JSON.stringify(projectsError)}
      </div>
    )
  } else if (orgIsSuccess && projectsIsSuccess) {
    const projects: Project[] = projectsData.results
    content = (
      <>
        <div className={"py-2"}>
          {projects.length ? (
            projects.map(({ id, name, git_url, git_default_branch }) => (
              <ProjectLink
                organizationID={organizationID}
                projectID={id}
                projectName={name}
                projectGitURL={git_url}
                projectGitDefaultBranch={git_default_branch}
                pipelinesData={pipelinesData[id]}
                pipelineRunData={pipelineRunData}
                key={id}
              />
            ))
          ) : (
            <div>No projects.</div>
          )}
        </div>
        {getConfigValue("environment") === "development" ? (
          <a href={`/organizations/${organizationID}/create-project`}>
            <PrimaryButton onClick={(_) => {}}>
              Create new project
            </PrimaryButton>
          </a>
        ) : (
          <a href={`/organizations/${organizationID}/create-project`}>
            <PrimaryButton>Create new project</PrimaryButton>
          </a>
        )}
      </>
    )
  }
  return content
}

export function OrganizationPage() {
  const { organizationID } = useParams()
  if (organizationID === undefined) {
    throw Error("organization ID required")
  }
  const content = <OrganizationInfo organizationID={organizationID} />
  return <PageWrapper title={""} content={content} />
}

import { useParams } from "react-router-dom"
import {
  useCreatePipelineRunMutation,
  useGetOrganizationQuery,
  useGetPipelineQuery,
  useGetPipelineRunsQuery,
  useGetProjectQuery,
} from "../api/apiSlice"
import React, { useState } from "react"
import { Pipeline } from "./pipelineSlice"
import { PipelineRun } from "../pipelineRuns/pipelineRunSlice"
import { PageWrapper } from "../../components/PageWrapper"
import { PrimaryButton } from "../../components/form"

const PipelineRunLink = (
  organizationID: string,
  projectID: string,
  pipelineID: string,
  pipelineRunID: string,
  runStatus: string,
  dateCreated: string,
) => {
  const url = `/organizations/${organizationID}/projects/${projectID}/pipelines/${pipelineID}/runs/${pipelineRunID}`
  const date = new Date(dateCreated)

  const dateTimeFormat = new Intl.DateTimeFormat("en-us", {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  })
  const dateString = dateTimeFormat.format(date)
  return (
    <div className={"text-xl"}>
      <a href={url}>{dateString}</a> ({runStatus})
    </div>
  )
}

export function PipelinePage() {
  const { organizationID, projectID, pipelineID } = useParams()
  if (
    organizationID === undefined ||
    projectID === undefined ||
    pipelineID === undefined
  ) {
    throw Error("organization ID and project ID required")
  }

  const {
    isLoading: orgIsLoading,
    isSuccess: orgIsSuccess,
    isError: orgIsError,
    error: orgError,
  } = useGetOrganizationQuery(organizationID)
  const {
    isLoading: projectIsLoading,
    isSuccess: projectIsSuccess,
    isError: projectIsError,
    error: projectError,
  } = useGetProjectQuery({ organizationID, projectID })
  const {
    data: pipelineData,
    isLoading: pipelineIsLoading,
    isSuccess: pipelineIsSuccess,
    isError: pipelineIsError,
    error: pipelineError,
  } = useGetPipelineQuery({ organizationID, projectID, pipelineID })
  const {
    data: runsData,
    isLoading: runsIsLoading,
    isSuccess: runsIsSuccess,
    isError: runsIsError,
    error: runsError,
  } = useGetPipelineRunsQuery({ organizationID, projectID, pipelineID })
  useCreatePipelineRunMutation()
  const [{}, setShowModal] = useState<boolean>(false)

  let title = ""
  let content = <></>
  if (orgIsLoading || projectIsLoading || pipelineIsLoading || runsIsLoading) {
    title = "Loading..."
    content = <div>Loading...</div>
  } else if (orgIsError || projectIsError || pipelineIsError || runsIsError) {
    title = "An error occurred"
    let errorType, error
    switch (true) {
      case orgIsError:
        errorType = "organization"
        error = orgError
        break
      case projectIsError:
        errorType = "project"
        error = projectError
        break
      case pipelineIsError:
        errorType = "pipeline"
        error = pipelineError
        break
      case runsIsError:
        errorType = "pipeline runs"
        error = runsError
        break
      default:
        throw new Error("unhandled error case")
    }
    content = (
      <div>
        Error loading {errorType}: {JSON.stringify(error)}
      </div>
    )
  } else {
    if (
      !(orgIsSuccess && projectIsSuccess && pipelineIsSuccess && runsIsSuccess)
    ) {
      throw new Error("unhandled case")
    }
    const pipeline: Pipeline = pipelineData
    const runs: PipelineRun[] = runsData.results
    title = pipeline.name
    content = (
      <div>
        <h2>Runs</h2>
        {runs.map(({ id, status, date_created: dateCreated }) =>
          PipelineRunLink(
            organizationID,
            projectID,
            pipelineID,
            id,
            status,
            dateCreated,
          ),
        )}
        {runs.length === 0 && "This pipeline has never been run."}
        <div>
          <PrimaryButton
            onClick={(_) => {
              setShowModal(true)
            }}
          >
            Run pipeline
          </PrimaryButton>
        </div>
        {/*{showModal && (*/}
        {/*  <Modal*/}
        {/*    contents={*/}
        {/*      <StartPipelineRunForm gitHash={gitHash} setGitHash={setGitHash} />*/}
        {/*    }*/}
        {/*    title={"Start pipeline"}*/}
        {/*    confirmMessage={"Start"}*/}
        {/*    confirmDisabled={gitHash === ""}*/}
        {/*    onCancel={() => {*/}
        {/*      setShowModal(false)*/}
        {/*    }}*/}
        {/*    onConfirm={async () => {*/}
        {/*      setShowModal(false)*/}
        {/*      await createPipelineRun({*/}
        {/*        organizationID,*/}
        {/*        projectID,*/}
        {/*        pipelineID,*/}
        {/*        git_identifier: gitHash,*/}
        {/*      })*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
      </div>
    )
  }

  return <PageWrapper title={title} content={content} />
}

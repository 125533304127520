import { useSignupMutation } from "../api/apiSlice"
import { useState } from "react"
import {
  Input,
  Label,
  PrimaryButton,
  SecondaryButton,
} from "../../components/form"
import { useNavigate } from "react-router-dom"
import { ErrorDisplay } from "../../components/ErrorDisplay"
import { isFetchBaseQueryError } from "../../utils/errors"
import { CombinedLogo } from "../../components/CombinedLogo"

const makeExampleEmail = () => {
  let result = ""
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result + "@demo.silogy.io"
}

export function Signup() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [
    signup,
    {
      isError,
      isLoading,
      isSuccess: {},
      error,
    },
  ] = useSignupMutation()
  const navigate = useNavigate()

  return (
    <div
      className={
        "px-6 py-2 h-screen w-screen bg-backgroundDark text-textLight flex "
      }
    >
      <div className={"flex flex-col flex-1 items-center justify-center"}>
        <CombinedLogo />
        <h1 className={"hidden"}>Sign up</h1>
        <form className={"min-w-72"}>
          <div className={"mb-4"}>
            <Label
              className={"block text-gray-300 text-sm font-bold mb-2"}
              htmlFor={"email"}
            >
              Email
            </Label>
            <Input
              type={"email"}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />

            <br />
            <Label
              className={"block text-gray-300 text-sm font-bold mb-2"}
              htmlFor={"email"}
            >
              Password
            </Label>
            <Input
              type={"password"}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
          </div>

          <div className={"space-x-2"}>
            <SecondaryButton
              disabled={isLoading}
              onClick={() => {
                setEmail(makeExampleEmail())
              }}
            >
              Use guest email address
            </SecondaryButton>
            <PrimaryButton
              type={"submit"}
              disabled={isLoading}
              onClick={async (e) => {
                e.preventDefault()
                await signup({ email, password }).unwrap()
                window.localStorage.setItem("onboarding", "true")
                navigate("/welcome")
              }}
            >
              Sign up
            </PrimaryButton>
          </div>

          <div className={"my-4"}>
            Already have an account?{" "}
            <a className={"obvious"} href={"/login"}>
              Log in
            </a>
          </div>

          {isLoading && <div className={"my-4"}>Creating your account...</div>}

          {isError && isFetchBaseQueryError(error) && (
            <ErrorDisplay shortMessage={"Error"} longerMessage={error.data} />
          )}
        </form>
      </div>
    </div>
  )
}

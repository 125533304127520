import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

export type ChangeDir = "next" | "prev"

export type WaveKind = "bit" | "vec"
// Time, then value
export type WaveChange = [number, number]

export type WaveColor = "green" | "red" | "orange"

export type WaveFormat = "decimal" | "hexadecimal" | "binary" | "octal"

//TODO -- technically, there are nine value simulators with weak zs and xs -- not handling that
//
export enum SingleBitValue {
  Zero = 0,
  One = 1,
  X = 2,
  Z = 3,
}

export interface EntireSignal {
  name: string
  kind: WaveKind
  wave: WaveChange[]
  color: WaveColor
  format: WaveFormat
  live_value: number | null
}

export interface WavesState {
  live_signals: EntireSignal[]
  start_time: number
  end_time: number
  window_state: WindowState
  cursor_state: CursorState
  live_signals_state: LiveSignalState
}

export interface CursorState {
  hovered_time: number
  selected_time: number
}

export interface LiveSignalState {
  focused_indices: number[]
}

/**
 * Interface that tracks the physical window state
 */
export interface WindowState {
  timescale_font_size: number
  value_font_size: number
  // Currently will be physical width of the window
  width: number
  height: number
  /**
   * pixels per time
   * given an x_coord in our waveform view window, time is x / x_scale
   **/
  x_scale: number

  // ratio of wave height to text size
  y_duty: number
  // flat number of pixels between each wave
  y_buffer_px: number
}

const initialWavesState: WavesState = {
  live_signals: [],
  start_time: 0,
  end_time: 1000,
  window_state: {
    value_font_size: 16,
    timescale_font_size: 16,
    width: 1000,
    height: 1000,
    x_scale: 50,
    y_duty: 1.2,
    y_buffer_px: 5,
  },
  cursor_state: {
    hovered_time: 0,
    selected_time: 0,
  },
  live_signals_state: {
    focused_indices: [],
  },
}

export const setFocusedTime = createAsyncThunk(
  "waveState/someTimeChange",
  async ({
    signal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    signal_index,
    focused_time,
  }: {
    signal: EntireSignal
    signal_index: number
    focused_time: number
  }) => {
    const value = signal.wave.findLast((val) => {
      return val[0] <= focused_time
    })
    if (value) {
      return value[1]
    } else {
      // we found no value at that time or greater, so we take the signal change at the largest time
      return signal.wave.at(-1)![1]
    }
  },
)

export const moveCursorTime = createAsyncThunk(
  "waveState/moveCursor",
  async ({
    signal,
    current_time,
    change_dir,
  }: {
    signal: EntireSignal
    current_time: number
    change_dir: ChangeDir
  }) => {
    const find_next_time = (pred: WaveChange) => {
      if (pred[0] > current_time) {
        return pred
      }
    }
    const find_prev_time = (pred: WaveChange) => {
      if (pred[0] < current_time) {
        return pred
      }
    }

    const time =
      change_dir == "next"
        ? signal.wave.find(find_next_time)
        : signal.wave.findLast(find_prev_time)
    if (time) {
      return time[0]
    } else {
      return current_time
    }
  },
)

export const fetchOneWave = createAsyncThunk(
  "waveState/fetchWave",
  async ({
    wave_id,
    wave_name,
    cursor_time,
  }: {
    wave_id: number
    wave_name: string
    cursor_time: number
  }) => {
    const base_wave = [
      [0, 0],
      [1, 1],
      [2, 0],
      [3, 1],
      [4, 0],
      [5, 1],
      [6, 0],
      [7, 1],
      [8, 0],
      [9, 1],
      [10, 0],
      [11, 1],
      [12, 0],
      [13, 1],
      [14, 0],
    ]

    const kind = wave_id % 2 == 1 ? "bit" : "vec"

    const actual_wave = base_wave.map((val) => {
      val[0] = val[0]
      if (kind == "vec") {
        val[1] = val[1] * (wave_id + 1) + wave_id
      }
      return val as WaveChange
    })

    const current_value = actual_wave.findLast((val) => {
      return val[0] <= cursor_time
    })![1]

    return {
      name: wave_name,
      kind: kind,
      wave: base_wave,
      format: "hexadecimal",
      live_value: current_value,
    } as EntireSignal
  },
)

export const wavesSlice = createSlice({
  name: "waveState",
  initialState: initialWavesState,
  reducers: {
    zoomIn: (state) => {
      state.window_state.x_scale *= 3 / 2
    },
    zoomOut: (state) => {
      state.window_state.x_scale *= 2 / 3
    },

    setCursor: (state, action: PayloadAction<number>) => {
      state.cursor_state.selected_time = Math.floor(
        action.payload / state.window_state.x_scale,
      )
      console.log(state.cursor_state.selected_time)
    },

    setHovered: (state, action: PayloadAction<number>) => {
      state.cursor_state.hovered_time = Math.floor(
        action.payload / state.window_state.x_scale,
      )
    },

    setFocusedIndices: (state, action: PayloadAction<number[]>) => {
      const indices = action.payload
      state.live_signals_state.focused_indices = indices
    },

    setDimensions: (
      state,
      action: PayloadAction<{ width: number; height: number }>,
    ) => {
      state.window_state.width = action.payload.width
      state.window_state.height = action.payload.height
    },
    deleteWaves: (state, action: PayloadAction<number[]>) => {
      const indices = action.payload
      state.live_signals = state.live_signals.filter((val, idx) => {
        return !indices.includes(idx)
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOneWave.fulfilled, (state, action) => {
      const wave = action.payload
      state.live_signals = [...state.live_signals, wave] // Using spread operator
    })
    builder.addCase(moveCursorTime.fulfilled, (state, action) => {
      console.log("moveCursor")
      const new_time = action.payload
      state.cursor_state.selected_time = new_time
      console.log(new_time)
    })
    builder.addCase(setFocusedTime.fulfilled, (state, action) => {
      const new_value = action.payload
      const idx = action.meta.arg.signal_index
      state.live_signals[idx].live_value = new_value
    })
  },
})
export const {
  zoomIn,
  zoomOut,
  setDimensions,
  setCursor,
  setHovered,
  deleteWaves,
  setFocusedIndices,
} = wavesSlice.actions
export default wavesSlice.reducer

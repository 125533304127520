import React from "react"
import TerminalComponent from "./terminal/terminal"
type CardStatus = "success" | "neutral" | "failure"

type ErrorPosition = {
  basename: string
  lineno: number
  startOffset: number
  endOffset: number
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const parseErrorLine = (line: string): ErrorPosition | null => {
  // "foo.v:29" should be preceded by "%Error: " but we don't want to match it
  const pattern = /(?<=%(?:Error|Warning|Fatal): )(.*\.s?v):(\d+):/
  const match = pattern.exec(line)
  if (match !== null) {
    return {
      basename: match[1],
      lineno: Number.parseInt(match[2]),
      startOffset: match.index,
      endOffset: match.index + match[1].length + match[2].length + 1,
    }
  }
  return null
}

export const LogsDisplay = ({
  logLines,
  loading,
  title,
  card_status,
}: {
  logLines: string[]
  loading: boolean
  title: JSX.Element
  card_status: CardStatus
}): React.JSX.Element => {
  //TODO: we should refactor so we don't have to do this
  const log = logLines.join("\n")

  return (
    <div className={`card dark card-${card_status}`}>
      <div className="content">
        <div className="test-log">
          <TerminalComponent value={log} title={title} loading={loading} />
        </div>
      </div>
    </div>
  )
}

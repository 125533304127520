import { Pipeline } from "../features/pipelines/pipelineSlice"
import {
  PipelineRun,
  PipelineRunCollectionState,
  PipelineRunState,
  statusDescription,
} from "../features/pipelineRuns/pipelineRunSlice"
import { Collapsible } from "./Collapsible"
import React, { useState } from "react"
import { PrimaryButton } from "./form"
import { Modal } from "./Modal"
import { StartPipelineRunForm } from "./StartPipelineRunForm"
import { formatDate } from "../utils/date"
import { failureIcon, gitHubIcon, inProgressIcon, successIcon } from "./icon"
import { ProgressBar } from "./ProgressBar"
import { useCreatePipelineRunMutation } from "../features/api/apiSlice"
import { isFetchBaseQueryError } from "../utils/errors"
import { useDispatch, useSelector } from "react-redux"
import { RootState, store } from "../app/store"
import { fetchJobConfig } from "../features/jobConfig/jobConfigSlice"
import { ExternalLinkButton } from "./ExternalLinkButton"
import {
  getGitHubCommitURL,
  getGitHubRefURL,
  getGitRefSuffix,
} from "../utils/git"

const PipelineRunDisplay = ({
  organizationID,
  projectID,
  projectGitURL,
  pipeline,
  pipelineRun,
}: {
  organizationID: string
  projectID: string
  projectGitURL: string
  pipeline: Pipeline
  pipelineRun: PipelineRun
}): React.JSX.Element => {
  const isInProgress = ["INIT", "PENDING", "BUILDING", "RUNNING"].includes(
    pipelineRun.status,
  )
  const isSucceeded = pipelineRun.status === "SUCCEEDED"
  const isFailed = [
    "BUILD_FAILED",
    "FAILED",
    "BUILD_TIMED_OUT",
    "TIMED_OUT",
  ].includes(pipelineRun.status)
  const timedOut = ["BUILD_TIMED_OUT", "TIMED_OUT"].includes(pipelineRun.status)
  if (
    [isInProgress, isSucceeded, isFailed].filter((status) => status).length !==
    1
  ) {
    throw Error(
      "exactly one of isInProgress, isSucceeded, or isFailed should be true, but got: " +
        `${isInProgress}, ${isSucceeded}, ${isFailed}`,
    )
  }
  const icon = isInProgress
    ? inProgressIcon
    : isSucceeded
      ? successIcon
      : failureIcon
  const errorCount =
    typeof pipelineRun.error_count === "number" ? pipelineRun.error_count : 0
  const errorMessage = statusDescription(pipelineRun.status, errorCount)
  const errorTextColor = isInProgress
    ? "text-black"
    : isFailed
      ? "text-red-600"
      : "text-green-600"
  const progressBackgroundColor = isFailed ? "bg-red-600" : "bg-green-600"
  const errorsDisplay = (
    <div
      className={`flex-initial w-32 ${errorTextColor} flex flex-col justify-center`}
    >
      {errorMessage}
    </div>
  )

  const progressBar = (
    <ProgressBar
      startTime={new Date(pipelineRun.date_created)}
      endTime={
        pipelineRun.date_completed ? new Date(pipelineRun.date_completed) : null
      }
      expectedDuration={pipeline.average_duration ?? 120}
      textColor={errorTextColor}
      backgroundColor={progressBackgroundColor}
      timedOut={timedOut}
    />
  )

  const url =
    `/organizations/${organizationID}/projects/${projectID}` +
    `/pipelines/${pipeline.id}/runs/${pipelineRun.id}`
  const gitHubCommitURL = getGitHubCommitURL(
    projectGitURL,
    pipelineRun.git_hash,
  )
  const gitHubRefURL = getGitHubRefURL(projectGitURL, pipelineRun.git_ref)
  const gitRefSuffix = getGitRefSuffix(pipelineRun.git_ref)
  return (
    <div>
      <a href={url}>
        <div className={"flex flex-row w-full p-1 shadow-sm hover:shadow"}>
          <div className="flex flex-row w-full space-x-4">
            <div className={"flex-initial w-8 flex flex-col justify-center"}>
              <div className={"flex-none"}>{icon}</div>
            </div>
            <div className={"flex-initial w-16 flex flex-col justify-center"}>
              <div>#{pipelineRun.display_number}</div>
            </div>
            <div
              className={
                "flex-initial w-48 text-gray-500 flex flex-col justify-center"
              }
            >
              {formatDate(pipelineRun.date_created)}
            </div>
            {errorsDisplay}
            <div
              className={
                "flex-initial w-32 bg-logoColor text-white rounded text-center text-sm truncate p-1 " +
                "flex flex-col justify-center"
              }
            >
              {pipelineRun.created_by}
            </div>
            <div className={"flex-initial w-44 flex flex-col justify-center"}>
              {progressBar}
            </div>
            <div className={"w-36"}>
              <ExternalLinkButton href={gitHubCommitURL}>
                <div className={"flex flex-row space-x-2"}>
                  <div>{gitHubIcon}</div>
                  <div>{pipelineRun.git_hash.substring(0, 8)}</div>
                </div>
              </ExternalLinkButton>
            </div>
            {gitHubRefURL && gitRefSuffix && (
              <div>
                <ExternalLinkButton href={gitHubRefURL}>
                  <div className={"flex flex-row space-x-2"}>
                    <div>{gitHubIcon}</div>
                    <div className="truncate w-max-40">{gitRefSuffix}</div>
                  </div>
                </ExternalLinkButton>
              </div>
            )}
          </div>
        </div>
      </a>
    </div>
  )
}

const SinglePipelineDisplay = ({
  organizationID,
  projectID,
  projectGitURL,
  projectGitDefaultBranch,
  pipeline,
  pipelineRuns,
}: {
  organizationID: string
  projectID: string
  projectGitURL: string
  projectGitDefaultBranch: string | null
  pipeline: Pipeline
  pipelineRuns: PipelineRunCollectionState | undefined
}): React.JSX.Element => {
  let pipelineRunContent

  const [createPipelineRun, createPipelineRunInfo] =
    useCreatePipelineRunMutation()

  const [testTarget, setTestTarget] = useState<string>("")

  const dispatch = useDispatch<typeof store.dispatch>()
  const jobConfigsForProject =
    useSelector((state: RootState) => state.jobConfig[projectID]) ?? {}

  if (createPipelineRunInfo.isSuccess) {
    window.location.reload()
  }
  const error = createPipelineRunInfo.error
  const createPipelineRunErrorMessage =
    createPipelineRunInfo.isError && isFetchBaseQueryError(error)
      ? error.data
        ? error.data.toString()
        : "An error occurred"
      : ""

  const [showModal, setShowModal] = useState(false)
  const [gitIdentifier, setGitIdentifier] = useState(
    projectGitDefaultBranch ?? "main",
  )

  switch (pipelineRuns?.status) {
    case undefined:
    case "idle":
    case "loading":
      pipelineRunContent = <>Loading runs...</>
      break
    case "succeeded":
      pipelineRunContent =
        pipelineRuns.pipelineRuns.length > 0 ? (
          <>
            {pipelineRuns.pipelineRuns.map((pipelineRun) => (
              <PipelineRunDisplay
                organizationID={organizationID}
                projectID={projectID}
                projectGitURL={projectGitURL}
                pipeline={pipeline}
                pipelineRun={pipelineRun}
                key={pipelineRun.id}
              />
            ))}
          </>
        ) : (
          "This pipeline has not been run."
        )
      break
  }

  return (
    <div className={"my-3"}>
      <Collapsible
        header={
          <div className={"flex flex-row w-full"}>
            <div className={"text-lg font-bold p-4"}>{pipeline.name}</div>
            <div className={"flex-none text-sm p-2"}>
              <PrimaryButton
                onClick={() => {
                  setShowModal(true)
                }}
              >
                Run
              </PrimaryButton>
            </div>
          </div>
        }
        arrowDivClass={"mx-1 p-4"}
        size={30}
      >
        <div
          className={
            "text-base p-2 mx-1 space-y-1 rounded border border-solid border-gray-300 "
          }
        >
          {pipelineRunContent}
        </div>
      </Collapsible>
      {showModal && (
        <Modal
          contents={
            <StartPipelineRunForm
              gitHash={gitIdentifier}
              setGitHash={setGitIdentifier}
              errorMessage={createPipelineRunErrorMessage}
              onStopTyping={() => {
                dispatch(
                  fetchJobConfig({ organizationID, projectID, gitIdentifier }),
                )
              }}
              jobConfigInfo={jobConfigsForProject[gitIdentifier]}
              testTarget={testTarget}
              setTestTarget={setTestTarget}
            />
          }
          title={"Run pipeline"}
          subtitle={pipeline.name}
          confirmMessage={
            createPipelineRunInfo.isLoading ? "Starting build..." : "Run"
          }
          confirmDisabled={createPipelineRunInfo.isLoading}
          onCancel={(e) => {
            e.preventDefault()
            setShowModal(false)
          }}
          onConfirm={async (e) => {
            e.preventDefault()
            await createPipelineRun({
              organizationID,
              projectID,
              pipelineID: pipeline.id,
              git_identifier: gitIdentifier,
              jobs: testTarget === "" ? null : [testTarget],
            })
          }}
        />
      )}
    </div>
  )
}

export const DisplayPipelinesWindow = ({
  organizationID,
  projectID,
  projectGitURL,
  projectGitDefaultBranch,
  pipelines,
  pipelineRunData,
}: {
  organizationID: string
  projectID: string
  projectGitURL: string
  projectGitDefaultBranch: string | null
  pipelines: Pipeline[]
  pipelineRunData: PipelineRunState | undefined
}): React.JSX.Element => {
  return (
    <div className={"overflow-y-auto max-h-128 my-2"}>
      {pipelines.map((pipeline) => (
        <SinglePipelineDisplay
          organizationID={organizationID}
          projectID={projectID}
          projectGitURL={projectGitURL}
          projectGitDefaultBranch={projectGitDefaultBranch}
          pipeline={pipeline}
          pipelineRuns={
            pipelineRunData ? pipelineRunData[pipeline.id] : undefined
          }
          key={pipeline.id}
        />
      ))}
    </div>
  )
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { client } from "../../api/axios"
import { formatBytes } from "../../utils/formatting"

export type JobRunStatus = "IN_PROGRESS" | "FAILED" | "SUCCEEDED" | "TIMED_OUT"

export type JobArtifact = {
  key: string
  size: number
}

export function getArtifactInfo(artifact: JobArtifact): {
  name: string
  size: string
} {
  const size = formatBytes(artifact.size)
  return { name: artifact.key, size }
}

export type JobRun = {
  id: string
  job_name: string
  status: JobRunStatus
  date_created: string
  date_started: string | null
  date_finished: string | null
  time_limit: number
  duration: number | null
  smelt_file_path: string | null
  run_summary: {
    warnings: number
    errors: number
    fatals: number
  } | null
}

export type RascalWave = {
  wave_uid: string
}

export const jobRunErrorCount = (jobRun: JobRun): number =>
  jobRun.run_summary === null
    ? 0
    : jobRun.run_summary.warnings +
      jobRun.run_summary.errors +
      jobRun.run_summary.fatals

export type JobRunLogFetchStatus = "idle" | "loading" | "succeeded" | "failed"

export interface JobRunState {
  logStatus: JobRunLogFetchStatus
  logs: string[]

  code: string[]
}

const initialState: JobRunState = {
  logStatus: "idle",
  logs: [],

  code: [],
}
export const fetchLogs = createAsyncThunk(
  "jobRun/fetchLogs",
  async ({
    organizationID,
    projectID,
    pipelineID,
    pipelineRunID,
    jobRunID,
  }: {
    organizationID: string
    projectID: string
    pipelineID: string
    pipelineRunID: string
    jobRunID: string
  }) => {
    const response = await client(
      `organizations/${organizationID}/` +
        `projects/${projectID}/` +
        `pipelines/${pipelineID}/` +
        `runs/${pipelineRunID}/` +
        `jobs/${jobRunID}/logs`,
    )
    return response.data
  },
)
const jobRunSlice = createSlice({
  name: "jobRun",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogs.pending, (state) => {
        state.logStatus = "loading"
      })
      .addCase(fetchLogs.rejected, (state) => {
        state.logStatus = "failed"
      })
      .addCase(fetchLogs.fulfilled, (state, action) => {
        state.logStatus = "succeeded"
        state.logs = action.payload.split("\n")
      })
  },
})

export default jobRunSlice.reducer

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { client } from "../../api/axios"
import { assertUnreachable } from "../../utils/assertUnreachable"

export type PipelineRunStatus =
  | "INIT"
  | "PENDING"
  | "BUILDING"
  | "BUILD_FAILED"
  | "RUNNING"
  | "FAILED"
  | "SUCCEEDED"
  | "BUILD_TIMED_OUT"
  | "TIMED_OUT"

export const logsComplete = (pipelineRunStatus: PipelineRunStatus): boolean => {
  return (
    pipelineRunStatus === "RUNNING" ||
    pipelineRunStatus === "FAILED" ||
    pipelineRunStatus === "SUCCEEDED" ||
    pipelineRunStatus === "TIMED_OUT"
  )
}

export const statusDescription = (
  status: PipelineRunStatus,
  errorCount: number,
): string => {
  switch (status) {
    case "INIT":
    case "PENDING":
    case "BUILDING":
    case "RUNNING":
      return "In progress"

    case "BUILD_FAILED":
      return "Build failed"

    case "FAILED":
      return errorCount > 1
        ? `${errorCount} errors`
        : errorCount === 1
          ? "1 error"
          : "Failed"

    case "BUILD_TIMED_OUT":
      return "Build timed out"

    case "TIMED_OUT":
      return "Tests timed out"

    case "SUCCEEDED":
      return "Tests succeeded"

    default:
      return assertUnreachable(status)
  }
}

export type PipelineRun = {
  id: string
  pipeline_id: string
  status: PipelineRunStatus
  date_created: string
  date_completed: string | undefined | null
  git_hash: string
  git_ref: string | null
  error_count: number | undefined | null
  created_by: string | null
  display_number: number
  failure_reason?: string
}

export interface PipelineRunCreateQuery {
  organizationID: string
  projectID: string
  pipelineID: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [otherKey: string]: any
}

export type fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export interface PipelineRunCollectionState {
  status: fetchStatus
  pipelineRuns: PipelineRun[]
}

export interface PipelineRunState {
  [pipelineID: string]: PipelineRunCollectionState
}

const initialState: PipelineRunState = {}

export const fetchPipelineRunsForPipeline = createAsyncThunk(
  "pipelineRun/fetchPipelineRuns",
  async ({
    organizationID,
    projectID,
    pipelineID,
  }: {
    organizationID: string
    projectID: string
    pipelineID: string
  }) => {
    const response = await client(
      `organizations/${organizationID}/projects/${projectID}/pipelines/${pipelineID}/runs`,
    )
    return response.data.results
  },
)
const pipelineRunSlice = createSlice({
  name: "pipelineRun",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPipelineRunsForPipeline.pending, (state, action) => {
        state[action.meta.arg.pipelineID] = {
          status: "loading",
          pipelineRuns: [],
        }
      })
      .addCase(fetchPipelineRunsForPipeline.rejected, (state, action) => {
        state[action.meta.arg.pipelineID] = {
          status: "failed",
          pipelineRuns: [],
        }
      })
      .addCase(fetchPipelineRunsForPipeline.fulfilled, (state, action) => {
        state[action.meta.arg.pipelineID] = {
          status: "succeeded",
          pipelineRuns: action.payload,
        }
      })
  },
})

export default pipelineRunSlice.reducer

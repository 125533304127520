export const getGitHubCommitURL = (
  projectGitURL: string,
  gitHash: string,
): string => {
  const repoPart = projectGitURL.endsWith(".git")
    ? projectGitURL.substring(0, projectGitURL.length - 4)
    : projectGitURL

  return `${repoPart}/commit/${gitHash}`
}

export const getGitRefSuffix = (ref: string | null): string | null => {
  if (ref === null) {
    return null
  }
  const branchPrefix = "refs/heads/"
  const tagPrefix = "refs/tags/"
  return ref.startsWith(branchPrefix)
    ? ref.substring(branchPrefix.length)
    : ref.startsWith(tagPrefix)
      ? ref.substring(tagPrefix.length)
      : null
}

export const getGitHubRefURL = (
  projectGitURL: string,
  ref: string | null,
): string | null => {
  if (ref === null) {
    return null
  }

  const repoPart = projectGitURL.endsWith(".git")
    ? projectGitURL.substring(0, projectGitURL.length - 4)
    : projectGitURL

  const suffix = getGitRefSuffix(ref)

  if (suffix === null) {
    return null
  }
  return `${repoPart}/tree/${suffix}`
}

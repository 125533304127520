import { PipelineRunStatus } from "./pipelineRunSlice"
import React from "react"
import {
  failureIcon,
  inProgressIcon,
  notStartedIcon,
  successIcon,
} from "../../components/icon"
import { assertUnreachable } from "../../utils/assertUnreachable"

type StepStatus = "IN_PROGRESS" | "SUCCEEDED" | "FAILED" | "NOT_STARTED"
type Step = { status: StepStatus; label: string; highlighted?: boolean }

const pipelineRunStepIcon = (status: StepStatus): React.JSX.Element => {
  if (status === "IN_PROGRESS") {
    return inProgressIcon
  } else if (status === "SUCCEEDED") {
    return successIcon
  } else if (status === "FAILED") {
    return failureIcon
  } else if (status === "NOT_STARTED") {
    return notStartedIcon
  } else {
    return assertUnreachable(status)
  }
}

const initPendingSteps: Step[] = [
  {
    label: "Waiting in queue",
    status: "IN_PROGRESS",
    highlighted: true,
  },
  {
    label: "Building",
    status: "NOT_STARTED",
  },
  {
    label: "Running tests",
    status: "NOT_STARTED",
  },
  {
    label: "Complete",
    status: "NOT_STARTED",
  },
]
const stepConfigs: { [key in PipelineRunStatus]: Step[] } = {
  INIT: initPendingSteps,
  PENDING: initPendingSteps,
  BUILDING: [
    {
      label: "Build started",
      status: "SUCCEEDED",
    },
    {
      label: "Building",
      status: "IN_PROGRESS",
      highlighted: true,
    },
    {
      label: "Running tests",
      status: "NOT_STARTED",
    },
    {
      label: "Complete",
      status: "NOT_STARTED",
    },
  ],
  BUILD_FAILED: [
    {
      label: "Build started",
      status: "SUCCEEDED",
    },
    {
      label: "Build failed",
      status: "FAILED",
      highlighted: true,
    },
    {
      label: "Running tests",
      status: "NOT_STARTED",
    },
    {
      label: "Complete",
      status: "NOT_STARTED",
    },
  ],
  RUNNING: [
    {
      label: "Build started",
      status: "SUCCEEDED",
    },
    {
      label: "Building",
      status: "SUCCEEDED",
    },
    {
      label: "Running tests",
      status: "IN_PROGRESS",
      highlighted: true,
    },
    {
      label: "Complete",
      status: "NOT_STARTED",
    },
  ],
  FAILED: [
    {
      label: "Build started",
      status: "SUCCEEDED",
    },
    {
      label: "Building",
      status: "SUCCEEDED",
    },
    {
      label: "Tests failed",
      status: "FAILED",
      highlighted: true,
    },
    // {
    //   label: "Complete",
    //   status: "NOT_STARTED",
    // },
  ],
  SUCCEEDED: [
    {
      label: "Build started",
      status: "SUCCEEDED",
    },
    {
      label: "Building",
      status: "SUCCEEDED",
    },
    {
      label: "Running tests",
      status: "SUCCEEDED",
    },
    {
      label: "Complete",
      status: "SUCCEEDED",
      highlighted: true,
    },
  ],
  BUILD_TIMED_OUT: [
    {
      label: "Build started",
      status: "SUCCEEDED",
    },
    {
      label: "Build timed out",
      status: "FAILED",
      highlighted: true,
    },
    {
      label: "Running tests",
      status: "NOT_STARTED",
    },
    {
      label: "Complete",
      status: "NOT_STARTED",
    },
  ],
  TIMED_OUT: [
    {
      label: "Build started",
      status: "SUCCEEDED",
    },
    {
      label: "Building",
      status: "SUCCEEDED",
    },
    {
      label: "Tests timed out",
      status: "FAILED",
      highlighted: true,
    },
    {
      label: "Complete",
      status: "NOT_STARTED",
    },
  ],
}

export const PipelineRunStatusWidget = ({
  status,
}: {
  status: PipelineRunStatus
}): React.JSX.Element => {
  const steps = stepConfigs[status]
  const greenSegments = ["RUNNING", "FAILED", "TIMED_OUT"].includes(status)
    ? 2
    : status === "SUCCEEDED"
      ? 3
      : 1
  const greenSegmentDiv = (
    <div className={"flex-1 flex flex-col"}>
      <div className={"flex-1"}></div>
      <div className={"flex-none bg-green-500 h-1"}></div>
      <div className={"flex-1"}></div>
    </div>
  )
  const graySegmentDiv = (
    <div className={"flex-1 flex flex-col"}>
      <div className={"flex-1"}></div>
      <div className={"flex-none bg-gray-300 h-1"}></div>
      <div className={"flex-1"}></div>
    </div>
  )
  const emptySegmentDiv = <div className={"flex-1"}></div>

  const colClassName =
    steps.length === 4
      ? "col-span-3"
      : steps.length === 3
        ? "col-span-4"
        : steps.length === 2
          ? "col-span-6"
          : ""
  const topRow = steps.map(({ status }, i) => {
    const left =
      i === 0
        ? emptySegmentDiv
        : i <= greenSegments
          ? greenSegmentDiv
          : graySegmentDiv
    const right =
      i === steps.length - 1
        ? emptySegmentDiv
        : i < greenSegments
          ? greenSegmentDiv
          : graySegmentDiv
    return (
      <div className={colClassName + " flex flex-row"}>
        {left}
        <div className={"-m-0.5"}>{pipelineRunStepIcon(status)}</div>
        {right}
      </div>
    )
  })
  const bottomRow = steps.map(({ label, highlighted }) => {
    const fontClass = highlighted
      ? "font-bold text-textDefault"
      : "text-gray-500"
    return (
      <div className={`${fontClass} ${colClassName} text-center my-2`}>
        {label}
      </div>
    )
  })

  return (
    <div className={"grid grid-cols-12 my-4"}>
      {topRow}
      {bottomRow}
    </div>
  )
}

import React, { useState } from "react"

import { PageWrapper } from "../../components/PageWrapper"
import { useParams } from "react-router-dom"
import {
  useGetJobPassAnalyticsQuery,
  useGetProjectQuery,
} from "../api/apiSlice"
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
} from "recharts"
import { Project } from "../projects/projectSlice"
import { SecondaryButton } from "../../components/form"

type TimeSelectorCB = (num_days: number) => void

const TimeRangeSelector = ({ onSelect }: { onSelect: TimeSelectorCB }) => {
  const [selectedRange, setSelectedRange] = useState("7d")

  const handleSelect = (range: string) => {
    setSelectedRange(range)

    function convertRangeToDays(range: string): number {
      const unit = range.slice(-1)
      const value = parseInt(range.slice(0, -1))

      switch (unit) {
        case "d":
          return value
        case "M":
          return value * 30
        default:
          console.log(`malformed range: ${range}, returning 7 days`)
          return 7
      }
    }

    const asdays = convertRangeToDays(range)
    onSelect(asdays)
  }
  return (
    <div className="flex space-x-4 pl-12">
      {["7d", "14d", "1M", "3M"].map((range) => (
        <SecondaryButton
          key={range}
          onClick={() => handleSelect(range)}
          children={
            <p
              className={`${selectedRange === range ? "underline underline-offset-4" : "no-underline"}`}
            >
              {range}
            </p>
          }
        />
      ))}
    </div>
  )
}

export function TestsSummaryGraph({
  organizationID,
  projectID,
}: {
  organizationID: string
  projectID: string
}): JSX.Element {
  const [numDaysShown, setNumDaysShow] = React.useState(7)
  const job_summary = useGetJobPassAnalyticsQuery({
    organizationID,
    projectID,
    history: numDaysShown,
  })

  const data = job_summary.isSuccess ? job_summary.data : []

  return (
    <div className="flex flex-col items-start">
      <div className="flex flex-row w-full justify-between items-start">
        <TimeRangeSelector onSelect={(num_days) => setNumDaysShow(num_days)} />
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={data}
          margin={{
            top: 5,

            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date_executed"
            tickFormatter={(utcDate: string) => {
              const date = new Date(utcDate)
              const month = date.getUTCMonth() + 1 // Months are zero indexed, so +1 is necessary
              const day = date.getUTCDate()
              return `${month}-${day}`
            }}
          />
          <YAxis />

          <Tooltip />

          <Legend />
          <Area
            dataKey="tests_failed"
            type="monotone"
            stackId="1"
            fill="#8B0000"
          />
          <Area
            dataKey="tests_passed"
            type="monotone"
            stackId="1"
            fill="#2E8B57"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export function AnalyticsPage(): React.JSX.Element {
  const { organizationID, projectID } = useParams()

  if (organizationID === undefined || projectID === undefined) {
    throw Error("org id or project id is missing")
  }
  let title: string = ""
  const content = (
    <TestsSummaryGraph organizationID={organizationID} projectID={projectID} />
  )

  const projectInfo = useGetProjectQuery({ organizationID, projectID })

  if (projectInfo.isLoading) {
    title = "Loading..."
  } else if (projectInfo.isSuccess) {
    const project: Project = projectInfo.data
    title = `${project.name} project health`
  }
  return <PageWrapper title={title} content={content} />
}

import combined_logo from "./assets/combined_logo.svg"
import React from "react"

export const CombinedLogo = () => (
  <div className={"h-16 flex items-center justify-center my-4"}>
    <img
      src={combined_logo}
      alt={"Silogy logotype"}
      className={"w-64 h-16 object-contain"}
    />
  </div>
)

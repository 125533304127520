import { useGetOrganizationsQuery } from "../api/apiSlice"
import { PageWrapper } from "../../components/PageWrapper"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchProjectsForOrganization } from "../projects/projectSlice"
import { Organization } from "../organizations/organizationSlice"
import { OrganizationInfo } from "../organizations/OrganizationPage"
import { RootState, store } from "../../app/store"

export function OrganizationsPage() {
  const {
    data = { results: [] },
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetOrganizationsQuery()
  const navigate = useNavigate()
  const dispatch = useDispatch<typeof store.dispatch>()
  const projectsData = useSelector((state: RootState) => state.project)

  useEffect(() => {
    if (isSuccess) {
      const organizations: Organization[] = data.results
      organizations.forEach(({ id }) => {
        dispatch(fetchProjectsForOrganization({ organizationID: id }))
      })
    }
  }, [isSuccess, data, dispatch])

  let content = <></>
  if (isLoading) {
    content = <div>Loading...</div>
  } else if (isSuccess) {
    const results = data.results
    const allProjects = Object.values(projectsData)
    if (
      allProjects.length === 1 &&
      allProjects[0].status === "succeeded" &&
      allProjects[0].projects.length === 0
    ) {
      // The user has one account and no projects, so it's a new user
      navigate("/welcome")
    }

    content = (
      <div>
        {results
          .filter((result) => result.id !== undefined)
          .map(({ id }) => (
            <OrganizationInfo key={id} organizationID={id} />
          ))}
      </div>
    )
  } else if (isError) {
    content = (
      <div>
        There was an error fetching your organizations: {JSON.stringify(error)}
      </div>
    )
  }

  return <PageWrapper title={"Home"} content={content} />
}

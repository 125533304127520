import { getMaterialChartColor, getLightMaterialChartColor } from "./color"
import { Profile, buildTimeSeries } from "./trace_events"
import * as constants from "./constants"

/**
 * A trace event profile structured for easier rendering in the trace viewer.
 */
export type TraceViewerModel = {
  panels: PanelModel[]
  xMax: number
}

export type PanelModel = {
  height: number
  sections: SectionModel[]
}

export type SectionModel = {
  name: string
  y: number
  height: number
  linePlot: LinePlotModel
}

export type LinePlotModel = {
  xs: number[]
  ys: number[]
  yMax: number
  darkColor: string
  lightColor: string
}

export function buildTraceViewerModel(trace: Profile): TraceViewerModel {
  const panels = [buildLinePlotsPanel(trace)]
  return {
    panels,
    xMax: computeXMax(panels),
  }
}

function buildLinePlotsPanel(profile: Profile): PanelModel {
  const timeSeries = buildTimeSeries(profile)
  let sectionY = 0
  let index = 0
  const sectionHeight =
    constants.SECTION_LABEL_HEIGHT +
    constants.SECTION_LABEL_PADDING_BOTTOM +
    constants.TIME_SERIES_HEIGHT +
    constants.SECTION_PADDING_BOTTOM
  const sections: SectionModel[] = []
  for (const { name, events } of timeSeries) {
    const xs: number[] = []
    const ys: number[] = []
    let yMax = 0
    for (const [value, offset_ms] of events) {
      const x = offset_ms * 1000
      const y = value
      if (isNaN(y)) continue
      xs.push(x)
      ys.push(y)
      if (y > yMax) yMax = y
    }
    sections.push({
      name: name,
      y: sectionY,
      height: sectionHeight,
      linePlot: {
        xs,
        ys,
        yMax,
        darkColor: getMaterialChartColor(index),
        lightColor: getLightMaterialChartColor(index),
      },
    })
    sectionY += sectionHeight
    index++
  }

  return {
    height: constants.LINE_PLOTS_PANEL_HEIGHT,
    sections,
  }
}

/**
 * Returns the max x-coordinate needed to render all of the data in the model.
 */
function computeXMax(panels: PanelModel[]): number {
  let width = 0
  for (const panel of panels) {
    for (const section of panel.sections) {
      if (section.linePlot) {
        if (!section.linePlot.xs.length) continue
        const lastX = section.linePlot.xs[section.linePlot.xs.length - 1]
        if (lastX > width) width = lastX
      }
    }
  }
  return width
}

/**
 * Returns the total scrollable height of all panel contents, including the
 * header and footer.
 */
export function panelScrollHeight(panel: PanelModel): number {
  let height =
    constants.TIMESTAMP_HEADER_SIZE + constants.BOTTOM_CONTROLS_HEIGHT
  if (!panel.sections.length) height

  const lastSection = panel.sections[panel.sections.length - 1]
  height += lastSection.y + lastSection.height

  return height
}

import React from "react"
import { onLoggedOut } from "../features/user/userSlice"
import { useLogoutMutation } from "../features/api/apiSlice"
import { Sidebar } from "./Sidebar"

export const PageWrapper = ({
  title,
  content,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loggedIn = true,
  supertitle = "",
}: {
  title: string
  content: React.JSX.Element
  loggedIn?: boolean
  supertitle?: string
}): React.JSX.Element => {
  const [logout] = useLogoutMutation()
  const handleLogout = async () => {
    await logout({}).unwrap()
    onLoggedOut()
  }

  return (
    <div className={"flex min-h-screen"}>
      {loggedIn && <Sidebar onLogout={handleLogout} />}
      <main className={"flex-1 ml-72 p-8"}>
        <div className={"m-2 px-6"}>
          {supertitle !== "" && <div className={"text-lg"}>{supertitle}</div>}
          <h1 className={"text-black text-3xl mb-4 font-bold"}>{title}</h1>
        </div>
        <div className={"m-2 px-6 py-2 w-full"}>{content}</div>
      </main>
    </div>
  )
}

import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Input, Label } from "./form"
import { ErrorDisplay } from "./ErrorDisplay"
import { JobConfigInfo } from "../features/jobConfig/jobConfigSlice"
const emptyLabel = "(All test targets)"

export const StartPipelineRunForm = ({
  gitHash,
  setGitHash,
  errorMessage,
  onStopTyping,
  jobConfigInfo,
  testTarget,
  setTestTarget,
}: {
  gitHash: string
  setGitHash: (gitHash: string) => void
  errorMessage?: string
  onStopTyping: () => void
  jobConfigInfo?: JobConfigInfo
  testTarget: string
  setTestTarget: (testTarget: string) => void
}): React.JSX.Element => {
  // This is so that we can delay searching but it shows as loading immediately
  // upon typing rather than after 500 ms
  const [fakeIsLoading, setFakeIsLoading] = useState(false)
  useEffect(() => {
    setFakeIsLoading(true)
    if (gitHash.length > 0) {
      setTestTarget("")
      const timeout = setTimeout(() => {
        onStopTyping()
        setFakeIsLoading(false) // real isLoading should have kicked in
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [gitHash])

  const options = [{ value: "", label: emptyLabel }].concat(
    (jobConfigInfo?.jobConfig?.test_lists ?? [])
      .map((val) => ({
        value: val.name,
        label: val.name,
      }))
      .sort((a, b) => (a.label < b.label ? -1 : a.label === b.label ? 0 : 1)),
  )

  const selectIsLoading = jobConfigInfo?.status === "loading" || fakeIsLoading
  return (
    <div className={"space-y-6"}>
      <div className={"-space-y-2"}>
        <Input
          type={"text"}
          onChange={(e) => setGitHash(e.target.value)}
          id={"gitHash"}
          value={gitHash}
        />
        <Label htmlFor={"gitHash"}>Git commit hash or branch name</Label>
      </div>
      <div className={"space-y-1"}>
        <Select
          isSearchable
          isDisabled={selectIsLoading}
          id={"target"}
          className={"my-1"}
          options={options}
          defaultValue={{ value: "", label: emptyLabel }}
          value={{
            value: testTarget,
            label: testTarget === "" ? emptyLabel : testTarget,
          }}
          isLoading={selectIsLoading}
          loadingMessage={() =>
            jobConfigInfo?.status === "loading" ? (
              <span>"Loading..."</span>
            ) : (
              <></>
            )
          }
          onChange={(value) => {
            setTestTarget(value === null ? "" : value.value)
          }}
        />
        <Label htmlFor={"target"}>Test suite</Label>
      </div>
      {jobConfigInfo?.status === "failed" && (
        <ErrorDisplay
          shortMessage="Failed to fetch job config"
          longerMessage={
            jobConfigInfo?.failureMessage ? (
              <span className={"whitespace-pre-wrap"}>
                {jobConfigInfo?.failureMessage}
              </span>
            ) : (
              "Unknown error, please contact contact@silogy.yml."
            )
          }
        />
      )}

      {/*
      This used to enable override arguments to pass to the job. Smelt doesn't
      support it, so I'm commenting it out for now. I'm not sure if we'll want
      to add back this kind of functionality in the future.
      <div className={"space-y-1"}>
        <TextArea
          id={"args"}
          value={testArgs}
          onChange={(e) => {
            setTestArgs(e.target.value)
          }}
          cols={40}
          rows={5}
          placeholder={"verbose=true\nlog_dump=false\nfoo=bar"}
          // className={"my-3"}
        />
        <Label htmlFor={"args"}>
          Optional: Override arguments to pass into the test rule in{" "}
          <a
            href={"https://hexdocs.pm/dotenvy/dotenv-file-format.html"}
            className={"obvious"}
          >
            dotenv
          </a>{" "}
          format
        </Label>
      </div>
      */}
      {errorMessage !== "" && (
        <ErrorDisplay
          shortMessage={"Failed to run pipeline"}
          longerMessage={errorMessage}
        />
      )}
    </div>
  )
}

import React from "react"
import ReactDOM from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "./app/store"
import "./index.css"
import { User } from "./features/auth/User"
import { Login } from "./features/auth/Login"
import { OrganizationsPage } from "./features/home/HomePage"
import { OrganizationPage } from "./features/organizations/OrganizationPage"
import { ProjectPage } from "./features/projects/ProjectPage"
import { Signup } from "./features/auth/Signup"
import { PipelineRunPage } from "./features/pipelineRuns/PipelineRunPage"
import { BasedefsPage } from "./features/basedef/BasedefsPage"
import { BasedefPage } from "./features/basedef/BasedefPage"
import { JobRunPage } from "./features/jobRun/JobRunPage"
import { CreateOrganizationPage } from "./features/organizations/CreateOrganizationPage"
import { CreateProjectPage } from "./features/projects/CreateProjectPage"
import { PostLoginPage } from "./features/home/PostLoginPage"
import { AnalyticsPage } from "./features/analytics/AnalyticsPage"
import { MultiAnalyticsPage } from "./features/analytics/MultiAnalyticsPage"

import { Welcome } from "./features/welcome/Welcome"
import { PostInstall } from "./features/postInstall/PostInstall"

import "bootstrap-icons/font/bootstrap-icons.css"
import { PipelinePage } from "./features/pipelines/PipelinePage"
import { getConfigValue } from "./app/config"
import { PlanGenPage } from "./features/planGen/PlanGenPage"
import { SurferPage } from "./features/surferWrapper/SurferWrapperPage"

type HocProps = { element: React.JSX.Element }
function RequireAuth({ element }: HocProps) {
  const cookies = Object.fromEntries(
    document.cookie.split("; ").map((c) => c.split("=")),
  )
  if (cookies[getConfigValue("SESSION_COOKIE_NAME")]) {
    return element
  } else {
    document.location = "/login"
    return <div />
  }
}

function RedirectToHome() {
  const cookies = Object.fromEntries(
    document.cookie.split("; ").map((c) => c.split("=")),
  )
  if (cookies[getConfigValue("SESSION_COOKIE_NAME")]) {
    if (window.localStorage.getItem("onboarding") === "true") {
      document.location.assign("/welcome")
    } else {
      document.location.assign("/post-login")
    }
  } else {
    document.location.assign("/login")
  }
  return <div />
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <RedirectToHome />,
  },

  // auth stuff
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/post-login",
    element: <PostLoginPage />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/user",
    element: <RequireAuth element={<User />} />,
  },
  // organizations
  {
    path: "/organizations",
    element: <RequireAuth element={<OrganizationsPage />} />,
  },
  {
    path: "/organizations/:organizationID",
    element: <RequireAuth element={<OrganizationPage />} />,
  },
  {
    path: "/create-organization",
    element: <RequireAuth element={<CreateOrganizationPage />} />,
  },
  // project
  {
    path: "/organizations/:organizationID/projects/:projectID",
    element: <RequireAuth element={<ProjectPage />} />,
  },
  {
    path: "/organizations/:organizationID/create-project",
    element: <RequireAuth element={<CreateProjectPage />} />,
  },
  // pipeline - disabled for now
  {
    path: "/organizations/:organizationID/projects/:projectID/pipelines/:pipelineID",
    element: <RequireAuth element={<PipelinePage />} />,
  },
  // pipeline run
  {
    path: "/organizations/:organizationID/projects/:projectID/pipelines/:pipelineID/runs/:pipelineRunID",
    element: <RequireAuth element={<PipelineRunPage />} />,
  },
  // job run
  {
    path: "/organizations/:organizationID/projects/:projectID/pipelines/:pipelineID/runs/:pipelineRunID/jobs/:jobRunID",
    element: <RequireAuth element={<JobRunPage />} />,
  },
  // basedef
  {
    path: "/organizations/:organizationID/projects/:projectID/basedefs",
    element: <RequireAuth element={<BasedefsPage />} />,
  },
  // plangen
  {
    path: "/organizations/:organizationID/projects/:projectID/plangen",
    element: <RequireAuth element={<PlanGenPage />} />,
  },
  // analytics
  {
    path: "/organizations/:organizationID/projects/:projectID/analytics",
    element: <RequireAuth element={<AnalyticsPage />} />,
  },
  // multiproject analytics
  {
    path: "/analytics",
    element: <RequireAuth element={<MultiAnalyticsPage />} />,
  },

  {
    path: "/organizations/:organizationID/projects/:projectID/basedefs/:basedefID",
    element: <RequireAuth element={<BasedefPage />} />,
  },
  // Onboarding stuff
  {
    path: "/welcome",
    element: <RequireAuth element={<Welcome />} />,
  },
  {
    path: "/organizations/:organizationID/projects/:projectID/pipelines/:pipelineID/runs/:pipelineRunID/jobs/:jobRunID/wave/:waveName",
    element: <RequireAuth element={<SurferPage />} />,
  },

  {
    path: "/post-install",
    element: <RequireAuth element={<PostInstall />} />,
  },
])
async function main() {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>,
  )
}

main()

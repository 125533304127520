import { getConfigValue } from "../app/config"

export const deleteCookie = (name: string): void => {
  let newCookieVal = `${name}=whatever;path=/;SameSite=None;expires=Thu, 01 Jan 1970 00:00:01 GMT`
  const cookieDomain = getConfigValue("COOKIE_DOMAIN")
  if (cookieDomain) {
    newCookieVal = newCookieVal + `; domain=${cookieDomain}`
  }
  document.cookie = newCookieVal
}
